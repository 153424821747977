import React from 'react'
import PropTypes from 'prop-types'

import prettyBytes from 'pretty-bytes'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import ItemIconCopyButton from './ItemIconCopyButton'

function ItemMetadataAssetTable(props) {
  const {asset} = props

  const CellValue = ({value}) => {
    if (value === null || value === undefined || value === '') {
      return <span sx={{color: ' #888', fontStyle: 'oblique'}}>(None provided)</span>
    }
    return <>{value}</>
  }

  return (
    <TableContainer>
      <Table sx={{minWidth: '450px'}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell align="left">Value</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell align="left" size="medium" className="cell-fixed-width">
              <CellValue value={asset.id} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Href
            </TableCell>
            <TableCell align="left" size="medium" className="cell-fixed-width">
              <CellValue value={asset.href} />
            </TableCell>
            <TableCell>
              <ItemIconCopyButton value={asset.href} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Etag
            </TableCell>
            <TableCell align="left" size="medium" className="cell-fixed-width">
              <CellValue value={asset.entityTag} />
            </TableCell>
            <TableCell>
              <ItemIconCopyButton value={asset.entityTag} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Title
            </TableCell>
            <TableCell align="left" size="medium" className="cell-fixed-width">
              <CellValue value={asset.title} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Roles
            </TableCell>
            <TableCell align="left" size="medium" className="cell-fixed-width">
              <CellValue value={asset.roles} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Size
            </TableCell>
            <TableCell align="left" size="medium" className="cell-fixed-width">
              <CellValue value={asset.size ? prettyBytes(asset.size) : null} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ItemMetadataAssetTable.propTypes = {
  /** A set of attributes that describe an item's asset */
  asset: PropTypes.shape({
    href: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number,
    roles: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}

export default ItemMetadataAssetTable
