import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Button, TextField, Typography} from '@mui/material'

import useAdvancedSearch from '../hooks/useAdvancedSearch'
import {isDateLike} from '../utilities/dateTimeUtils'
import moment from 'moment'

const DateSearchElement = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 100px;
  grid-template-rows: 30px 30px;
  grid-column-gap: 10px;
  grid-row-gap: 5px;

  .clear-button {
    grid-column: 3 / 4;
    grid-row: 1 / 3;

    height: 70px;
  }

  .label {
    grid-column: 1 / 2;
    align-self: center;
    color: rgba(0, 0, 0, 0.38);
  }
`

function AdvancedSearchSelectorDateRange() {
  const [date, setDate] = useState({from: null, to: null})
  const {from, to} = date

  const {
    setDateRange,
    searchAttributes: {fromDate, toDate},
  } = useAdvancedSearch()

  // Sets initial values of from and to dates
  useEffect(() => {
    if (fromDate && toDate) setDate({from: fromDate, to: toDate})
  }, [])

  useEffect(() => {
    // Prevents infinite loops
    if (from === fromDate && to === toDate) return

    // Sets date at the reducer if either both or none are set locally.
    const fromValid = isDateLike(from)
    const toValid = isDateLike(to)
    if (fromValid && toValid) {
      setDateRange({fromDate: from, toDate: to})
    } else if (fromValid && !toValid) {
      const dr = {fromDate: from, toDate: moment().format('YYYY-MM-DDThh:mm')}
      setDateRange(dr)
      setDate({from: dr.fromDate, to: dr.toDate})
    } else if (!fromValid && toValid) {
      const dr = {fromDate: moment(to).add(-1, 'month').format('YYYY-MM-DDThh:mm'), toDate: to}
      setDateRange(dr)
      setDate({from: dr.fromDate, to: dr.toDate})
    }

    if (!fromValid && !toValid) {
      setDateRange({fromDate: '', toDate: ''})

      document.getElementById('setFromDateInput').value = null
      document.getElementById('setToDateInput').value = null
    }
  }, [from, to])

  return (
    <DateSearchElement>
      <div className="label">
        <Typography variant="body2">From:</Typography>
      </div>
      <TextField
        id="setFromDateInput"
        type="datetime-local"
        style={{
          marginLeft: '8px',
          marginRight: '8px',
          marginBottom: 20,
          width: 250,
        }}
        shrink="true"
        InputProps={{
          inputProps: {
            min: '1000-01-01T00:00',
            max: '9999-12-31T23:59',
          },
        }}
        onChange={event => {
          setDate({...date, from: event.target.value})
        }}
        value={from ?? ''}
        variant="standard"
      />
      <div className="label">
        <Typography variant="body2">To:</Typography>
      </div>
      <TextField
        id="setToDateInput"
        type="datetime-local"
        style={{
          marginLeft: '8px',
          marginRight: '8px',
          marginBottom: 20,
          width: 250,
        }}
        shrink="true"
        InputProps={{
          inputProps: {
            min: '1000-01-01T00:00',
            max: '9999-12-31T23:59',
          },
        }}
        onChange={event => {
          setDate({...date, to: event.target.value})
        }}
        value={to ?? ''}
        variant="standard"
      />
      <Button
        className="clear-button"
        variant="outlined"
        color="inherit"
        onClick={() => setDate({from: null, to: null})}
      >
        Clear
      </Button>
    </DateSearchElement>
  )
}

export default AdvancedSearchSelectorDateRange
