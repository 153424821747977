import React, {useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import useToastNotification from '../hooks/useToastNotification'

import {Button, Tooltip, IconButton, Dialog, DialogActions, TextField} from '@mui/material'
import {Edit as EditIcon} from '@mui/icons-material'

const Container = styled('div')`
  overflow: hidden;
  background: white;
`

const Title = styled('h3')`
  padding: 0.3em 1em;
  font-weight: 300;
  font-size: 1.3em;
`

const StyledTextField = styled(TextField)`
  padding: 0.3em 1em;
`

/*
 * A button that when triggered engages the edit collection title modal.
 *
 */
export default function GallerySummaryCollectionEditTitleButton(props) {
  const {name, collection} = props
  const [editTitleModal, setEditTitleModal] = useState(false)
  const [collectionTitle, setCollectionTitle] = useState(name)
  const {errorNotification, successNotification} = useToastNotification()

  const handleEditCollectionTitle = () => {
    if (!collectionTitle || collectionTitle === name) {
      errorNotification('Please enter a new title.')
      return
    }
  }

  const openDialogfn = () => {
    setEditTitleModal(true)
  }

  const closeDialogFn = () => {
    setEditTitleModal(false)
    setCollectionTitle(name)
  }

  const handleTextOnChange = e => {
    setCollectionTitle(e.target.value)
  }

  return (
    <>
      <Container>
        <Tooltip title="Edit collection title" aria-label="edit-collection-title">
          <IconButton
            aria-label="edit-collection-title"
            size="small"
            color="default"
            onClick={openDialogfn}
            className="editIcon"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Container>
      {/* Edit Title Modal */}
      {editTitleModal && (
        <Dialog open onClose={closeDialogFn}>
          <Title>Edit the collection title for {name}</Title>
          <StyledTextField
            variant="outlined"
            value={collectionTitle}
            onChange={handleTextOnChange}
          />

          <DialogActions>
            <Button style={{fontSize: '0.8em'}} onClick={closeDialogFn}>
              Cancel
            </Button>
            <Button
              style={{fontSize: '0.8em'}}
              onClick={handleEditCollectionTitle}
              variant="contained"
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

GallerySummaryCollectionEditTitleButton.propTypes = {
  /** The name of the collection */
  name: PropTypes.string.isRequired,
  /** The collection instance */
  collection: PropTypes.object.isRequired,
}
