import React, {CSSProperties, useState} from 'react'
import {Box, Button} from '@mui/material'

export function BorderedSection({
  title,
  children,
  style,
}: {
  title: string
  children?: React.ReactNode
  style?: CSSProperties | undefined
}) {
  const [hovering, setHovering] = useState(false) // used for consistency /w tricky border implementation
  const [expand, setExpand] = useState(true)
  return (
    <Box
      title={title}
      className="bordered-section"
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderLeft: '1px solid rgba(0,0,0,.23)',
        borderBottom: '1px solid rgba(0,0,0,.23)',
        borderRight: '1px solid rgba(0,0,0,.23)',
        ...(hovering
          ? {
              borderLeft: '1px solid rgba(0,0,0,.6)',
              borderBottom: '1px solid rgba(0,0,0,.6)',
              borderRight: '1px solid rgba(0,0,0,.6)',
            }
          : {}),
        borderRadius: '5px',
        ...style,
      }}
    >
      <div
        className="border-section-subcontainer"
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100% !important',
        }}
      >
        <Box
          className="border-section-border-left"
          sx={{
            borderTop: hovering ? '1px solid rgba(0,0,0,.6)' : '1px solid rgba(0,0,0,.23)',
            width: '1em',
            borderTopLeftRadius: '5px',
          }}
        ></Box>
        {title && (
          <div
            data-testid={title}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
              gap: '0.25em',
              width: 'fit-content',
              height: '2em',
              margin: '-1em 0.5em 0em 0.5em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '.75rem',
              fontWeight: '400',
              color: 'rgba(0,0,0,.6)',
              cursor: 'pointer',
            }}
            onClick={() => setExpand(!expand)}
          >
            {title}
          </div>
        )}
        <Box
          className="border-section-border-top"
          sx={{
            borderTop: hovering ? '1px solid rgba(0,0,0,.6)' : '1px solid rgba(0,0,0,.23)',
            width: '1em',
            flexGrow: '2',
            borderTopRightRadius: '5px',
          }}
        ></Box>
      </div>
      {expand ? (
        children
      ) : (
        <Button sx={{margin: '12px', marginTop: 0}} onClick={() => setExpand(!expand)}>
          Expand
        </Button>
      )}
    </Box>
  )
}
