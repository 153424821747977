import {TextField, Dialog, FormControl} from '@mui/material'
import styled from 'styled-components'
import type {Provider, StacPropertyValue} from '@bayer-int/imagine-sdk-browser'

export const StyledFormControl = styled(FormControl)`
  margin-top: 0;
`

export const TitleSection = styled('section')`
  display: flex;
  justify-content: center;
`

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 1080px;
    min-height: 500px;
  }
` as typeof Dialog

export const Container = styled.section`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const StyledTextField = styled(TextField)`
  margin-top: 20px;
  margin-bottom: 0px;
  line-height: 32px;
` as typeof TextField

export const CollectionAttributesTable = styled('ul')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 0;
  padding: 0;
`

export const CollectionAttributesItem = styled('div')`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: stretch;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
`

export const StyledTableTextField = styled(TextField)`
  margin-bottom: 0px;
`

export const DialogFooter = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  padding: 20px 20px 20px 0;
`

export const initialCollectionAttribute = {
  fieldName: '',
  fieldType: 'string' as StacPropertyValue,
  fieldRequired: true,
}

export type CollectionAttribute = {
  fieldName: string
  fieldType: StacPropertyValue
  fieldRequired: boolean
  fieldProperties: Record<string, any>
}

export type CreateExtensionFields = {
  id?: string
  title: string
  description: string
  major: string
  minor: string
  patch: string
  collectionProperties: CollectionAttribute[]
  prefix: string
}

export type CreateCollectionFields = {
  id?: string
  name: string
  description: string
  appEntitlements: {
    admin: GroupOption[]
    delete: GroupOption[]
    publish: GroupOption[]
    download: GroupOption[]
  }
  admin: GroupOption[]
  delete: GroupOption[]
  publish: GroupOption[]
  download: GroupOption[]
  extensions?: ExtOption[] | undefined
  keywords?: string[]
  license?: string
  providers?: Provider[]
}

export type CreateProjectFields = {
  id?: string
  title: string
  appEntitlements: {
    admin: GroupOption[]
    delete: GroupOption[]
    publish: GroupOption[]
    download: GroupOption[]
  }
  admin: GroupOption[]
  delete: GroupOption[]
  publish: GroupOption[]
  download: GroupOption[]
  region: string
}

export type GroupOption = {
  id: string
  name: string
  description: string
  hasGroup?: boolean
}

export type ExtOption = {
  label?: string | undefined
  value: string
  description?: string | undefined
  version: string
  disabled: boolean
}

export const requiredExtensions = ['exif', 'file', 'gh', 'project', 'storage', 'tags']
