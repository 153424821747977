import React, {useContext, useEffect} from 'react'
import {Snackbar} from '@mui/material'
import MuiAlert from '@mui/material/Alert'

import useToastNotification from '../hooks/useToastNotification'
import {toastNotificationStateContext} from '../context/toastNotificationContext'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function ToastNotification() {
  const {resetNotification} = useToastNotification()
  const {
    toastState: {open, severity, timeout, message},
  } = useContext(toastNotificationStateContext)

  useEffect(() => {
    if (open && timeout) {
      setTimeout(resetNotification, timeout)
    }
  }, [open])

  return (
    <Snackbar
      open={open}
      autoHideDuration={timeout}
      onClose={resetNotification}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Alert onClose={resetNotification} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default ToastNotification
