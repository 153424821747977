import {queryProfile} from '@monsantoit/profile-client'
import {useEffect, useState} from 'react'
import {useDebounce} from 'use-debounce'

async function internalQueryProfile(query: string, variables: any = {}, init: RequestInit) {
  const res = await fetch(`${(window as any).phoenix.serviceBindins['profile-url']}/v3/graphql`, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      ...init.headers,
    },
  })
  const body = await res.json()
  if (!body) {
    throw new Error(
      'profileClient.queryProfile - Unknown response from the Profile API. Is auth token support enabled? https://github.platforms.engineering/Velocity/profile-client#auth-token-support'
    )
  } else if (body.errors) {
    throw body.errors
  }
  return body.data
}

export async function introspectProfile() {
  const query = `fragment FullType on __Type {
    kind
    name
    fields(includeDeprecated: true) {
      name
      args {
        ...InputValue
      }
      type {
        ...TypeRef
      }
      isDeprecated
      deprecationReason
    }
    inputFields {
      ...InputValue
    }
    interfaces {
      ...TypeRef
    }
    enumValues(includeDeprecated: true) {
      name
      isDeprecated
      deprecationReason
    }
    possibleTypes {
      ...TypeRef
    }
  }
  fragment InputValue on __InputValue {
    name
    type {
      ...TypeRef
    }
    defaultValue
  }
  fragment TypeRef on __Type {
    kind
    name
    ofType {
      kind
      name
      ofType {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  query IntrospectionQuery {
    __schema {
      queryType {
        name
      }
      mutationType {
        name
      }
      types {
        ...FullType
      }
      directives {
        name
        locations
        args {
          ...InputValue
        }
      }
    }
  }
  `

  return await queryProfile(query)
}

export async function findApp(id: string): Promise<ProfileGroup | null> {
  const graphqlQuery = `query GetApplicationById($id: String!) {
    getApplicationById(id: $id) {
      id
      name
      description
    }
  }`

  const response = await queryProfile(graphqlQuery, {
    id,
  })

  return response?.getApplicationById
}

export async function findGroup(id: string): Promise<ProfileGroup | null> {
  const graphqlQuery = `query GetGroupById($id: String!) {
    getGroupById(id: $id) {
      id
      name
      description
    }
  }`

  const response = await queryProfile(graphqlQuery, {
    id,
  })

  return response?.getGroupById
}

export type ProfileGroup = {
  id: string
  name: string
  description: string
}

export async function searchGroups({
  query,
  offset,
  limit,
  searchField,
  fuzzy,
}: ProfileSearchOptions): Promise<ProfileGroup[]> {
  const graphqlQuery = `query SearchGroups($query: String, $offset: Int, $limit: Int, $searchField: SearchField, $fuzzy: Boolean) {
    searchGroups(query: $query, offset: $offset, limit: $limit, searchField: $searchField, fuzzy: $fuzzy) {
      groups {
        id
        name
        description
      }
    }
  }`

  const response = await queryProfile(graphqlQuery, {
    query,
    offset,
    limit,
    searchField,
    fuzzy,
  })

  return response?.searchGroups?.groups?.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
}

export async function getCurrenUserGroups(): Promise<ProfileGroup[]> {
  const query = `query GetCurrentUser {
      getCurrentUser {
        groups {
          id
          name
          description
        }
      }
    }
  `

  const response = await queryProfile(query)
  return response?.getCurrentUser?.groups?.sort((a, b) =>
    (a.name ?? '').localeCompare(b.name ?? '')
  )
}

export type ProfileApplication = {
  id: string
  name: string
  description: string
}

export type HookProfileSearchOptions = {
  onError: (err: Error) => void
  searchOptions: ProfileSearchOptions
}

export function useProfileSearch<T>({
  searchOptions,
  onError,
  searchFn,
  initialOptions,
}: {
  searchOptions?: ProfileSearchOptions
  onError: (err: Error) => void
  searchFn: (options: ProfileSearchOptions, init: RequestInit) => Promise<T[]>
  initialOptions?: T[]
}) {
  const [initialLoad, setInitialLoad] = useState(true)
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [options, setOptions] = useState<T[]>(initialOptions ?? [])
  const [debouncedSearchText] = useDebounce(searchText, 400)

  useEffect(() => {
    const abortController = new AbortController()
    if (debouncedSearchText) {
      searchFn({...searchOptions, query: debouncedSearchText}, {signal: abortController.signal})
        .then(res => {
          setOptions(res)
        })
        .catch(err => {
          setOptions([])
          onError(err)
        })
        .finally(() => {
          setLoading(false)
          setInitialLoad(false)
        })
    }

    return () => {
      abortController.abort()
    }
  }, [debouncedSearchText])

  return {initialLoad, loading, text: searchText, setText: setSearchText, options}
}

export function useApplicationSearch({
  searchOptions,
  onError,
}: {
  searchOptions?: ProfileSearchOptions
  onError: (err: Error) => void
}) {
  return useProfileSearch({
    searchOptions,
    onError,
    searchFn: searchApplications,
  })
}

export function useGroupSearch({
  searchOptions,
  onError,
}: {
  searchOptions?: ProfileSearchOptions
  onError: (err: Error) => void
}) {
  return useProfileSearch({
    searchOptions,
    onError,
    searchFn: searchGroups,
  })
}

type ProfileSearchOptions = {
  query?: string
  offset?: number
  limit?: number
  searchField?: any
  fuzzy?: boolean
}

export async function searchApplications({
  query,
  offset,
  limit,
  searchField,
  fuzzy,
}: ProfileSearchOptions): Promise<ProfileApplication[]> {
  const graphqlQuery = `query SearchApplications($query: String, $offset: Int, $limit: Int, $searchField: SearchField, $fuzzy: Boolean) {
    searchApplications(query: $query, offset: $offset, limit: $limit, searchField: $searchField, fuzzy: $fuzzy) {
      applications {
        id
        name
        description
      }
    }
  }`

  const response = await queryProfile(graphqlQuery, {
    query,
    offset,
    limit,
    searchField,
    fuzzy,
  })

  return response?.searchApplications?.applications?.sort((a, b) =>
    (a.name ?? '').localeCompare(b.name ?? '')
  )
}
