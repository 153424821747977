import React, {useContext, useEffect, useState} from 'react'
import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import {Add as AddIcon} from '@mui/icons-material'
import {albumListContext} from '../context/albumListContext'
import {imagineServiceContext} from '../context/imagineServiceContext'
import DialogCreateNewAlbum from './DialogCreateNewAlbum'
import DialogAddToAlbumListItem from './DialogAddToAlbumListItem'
import {telemetry} from '../utilities/telemetry'
import useToastNotification from '../hooks/useToastNotification'

function DialogAddToAlbum(props) {
  const {handleClose, items} = props
  const {retrieveSession} = useContext(imagineServiceContext)
  const {errorNotification} = useToastNotification()
  const [createAlbumDialogOpen, setCreateAlbumDialogOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [userInfo, setUserInfo] = useState(null)
  const [albumElements, setAlbumElements] = useState(null)
  const {albums, error: albumCtxError} = useContext(albumListContext)

  useEffect(() => {
    async function retrieveUserData() {
      try {
        const data = await retrieveSession()
        setUserInfo({userId: data.id})
      } catch (e) {
        const additionalErrorDetails = "There was an issue with retrieving the user's session."
        telemetry.error(e, additionalErrorDetails)
        errorNotification('Error loading user information')
      }
    }

    retrieveUserData()
  }, [])

  useEffect(() => {
    function populateValidAlbums() {
      const populatedElements = []

      albums.forEach((album, index) => {
        if (userInfo.userId === album.createdBy) {
          populatedElements.push(
            <DialogAddToAlbumListItem
              // @ts-ignore
              title={album.title}
              plural={items.length > 1}
              index={index}
              addItems={async () => album.addItems(items)}
              closeDialog={handleClose}
              key={`item-${album.title}-${album.id}`}
            />
          )
        }
      })
      setAlbumElements(populatedElements)
      setLoading(false)
    }

    if (userInfo && albums) populateValidAlbums()
  }, [userInfo, albums])

  if (albumCtxError) {
    return (
      <Dialog onClose={handleClose} open>
        <DialogTitle id="simple-dialog-title">Error Loading Albums</DialogTitle>
      </Dialog>
    )
  }

  if (loading)
    return (
      <Dialog onClose={handleClose} open>
        <DialogTitle id="simple-dialog-title">
          Add Item{items.length > 0 && 's'} to Album
        </DialogTitle>
        <ListItem>Loading...</ListItem>
      </Dialog>
    )

  return (
    <>
      {createAlbumDialogOpen && (
        <DialogCreateNewAlbum
          items={items}
          handleClose={() => {
            setCreateAlbumDialogOpen(false)
            handleClose()
          }}
        />
      )}
      <Dialog onClose={handleClose} open>
        <DialogTitle id="simple-dialog-title">
          Add Item{items.length > 0 && 's'} to Album
        </DialogTitle>
        <List>
          {albumElements}

          <ListItem
            sx={{cursor: 'pointer', '&:hover': {background: 'lightgrey'}}}
            onClick={() => setCreateAlbumDialogOpen(true)}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Create new" />
          </ListItem>
        </List>
      </Dialog>
    </>
  )
}

export default DialogAddToAlbum
