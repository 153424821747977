import React from 'react'
import App from './App'
import {navbarInstalled} from './utilities/initializeProfile'
import {createRoot} from 'react-dom/client'

import './style.css'
import {setupInterceptor} from './utilities/fetchInterceptor'
import {setupCache} from './utilities/cache'
import {setupTelemetry, telemetry} from './utilities/telemetry'

setupInterceptor()
setupCache()
setupTelemetry()

const container = document.querySelector('.contents')
const root = createRoot(container)

if (!process.env.TESTING) {
  navbarInstalled
    .then(() => {
      root.render(<App />)
    })
    .catch(e => {
      telemetry.error(e, 'Initial render failed')
    })
}
