import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Box, LinearProgress, Typography} from '@mui/material'
import styled from 'styled-components'
import {duration} from 'moment'
import formatBytes from 'pretty-bytes'
import {CopyToClipboard} from 'react-copy-to-clipboard'

import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'

const UploaderContainer = styled.section`
  display: grid;

  height: 550px;

  grid-template-rows: 20px 50px 110px 1fr;
  grid-row-gap: 15px;

  .row-status-bar,
  .row-message-area {
    margin: 0 auto;
    min-width: 0;
    width: 90%;
  }

  .row-status-bar {
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    grid-template-rows: 40px 20px 40px;

    .indicator-zero {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      justify-self: start;
      align-self: end;
      align-self: end;
      border-left: 1px solid #222;
      position: relative;
      padding: 0 0 4px 4px;
      top: 9px;

      & > .MuiTypography-caption {
        display: block;
        line-height: unset;
      }
    }

    .indicator-total {
      justify-self: end;
      align-self: end;
      grid-row: 1 / 2;
      grid-column: 3 / 4;
      border-right: 1px solid #222;
      position: relative;
      top: 9px;
      left: -8px;
      padding: 0 4px 4px 0;

      & > .MuiTypography-caption {
        display: block;
        line-height: unset;
        text-align: right;
      }
    }

    .progress-bar {
      align-self: start;
      padding-top: 7px;
      grid-row: 2 / 3;
      grid-column: 1 / 4;
      align-self: start;
      padding-top: 7px;
    }

    .progress-indicator {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      text-align: center;
    }
  }
`

const Path = styled('pre')`
  background-color: #f0f0f0;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  max-width: 100%;
  overflow-x: auto;
  padding: 0.5em;
  cursor: pointer;

  transition: background-color 0.35s ease-in-out;

  &:hover {
    background-color: #e8e8e8;
  }
`

let upload

/** State of upload are defined at
 *
 * https://github.platforms.engineering/location-360/experimental-image-uploader/blob/main/src/Upload.ts#L367-L385
 *
 * 'complete' |
 'error' |
 'evaluating' |
 'ongoing' |
 'suspended'
 *
 */

function UploaderExperimentalUploadingPane(props) {
  const {infoNotification} = useToastNotification()

  const {setCriticalError, uploader} = props
  const [progress, setProgress] = useState({
    bytesDone: -1,
    bytesRemaining: -1,
    rate: 0,
    status: 'initializing',
    timeRemaining: 0,
    message: '',
  })
  const [targetPath, setTargetPath] = useState('')

  function updateProgress({bytesDone, bytesRemaining, status, message, rate, timeRemaining}) {
    setProgress({
      bytesDone: bytesDone > progress.bytesDone ? bytesDone : progress.bytesDone,
      bytesRemaining,
      rate,
      status,
      timeRemaining,
      message,
    })
  }

  let percentage = 0
  let progressMessage
  let bytesDoneFormatted = ''
  let totalBytesFormatted = ''

  if (progress.bytesDone >= 0 && progress.bytesRemaining >= 0) {
    const totalBytes = progress.bytesDone + progress.bytesRemaining
    percentage = Math.floor((progress.bytesDone / totalBytes) * 100)
    bytesDoneFormatted = formatBytes(progress.bytesDone)
    totalBytesFormatted = formatBytes(totalBytes)
    progressMessage = `${percentage}% – `

    if (progress.timeRemaining && progress.rate) {
      const timeFormatted = duration(Math.max(60e3, progress.timeRemaining)).humanize()
      const rateFormatted = formatBytes(progress.rate * 8, {bits: true})
      progressMessage += `about ${timeFormatted} remaining (${rateFormatted}/s)`
    } else {
      progressMessage += 'Calculating time remaining...'
    }
  } else {
    progressMessage = 'Reading file...'
  }

  const uploaderComplete = progress.status === 'complete'

  function onUploadProgress(update) {
    const {bytesDone, bytesRemaining, status, message, rate, timeRemaining} = update

    switch (status) {
      case 'evaluating':
        updateProgress({
          bytesDone,
          bytesRemaining,
          status,
          message,
        })
        break
      case 'ongoing':
        updateProgress({
          bytesDone,
          bytesRemaining,
          rate,
          status,
          timeRemaining,
          message,
        })
        break
      case 'error':
        setCriticalError(message)

        break
      case 'complete':
        updateProgress({
          bytesDone,
          bytesRemaining,
          status,
          message,
        })
    }
  }

  useEffect(() => {
    if (!uploader) return

    uploader
      .upload(onUploadProgress)
      .then(uploadResult => {
        upload = uploadResult
      })
      .catch(e => {
        const additionalErrorDetails = 'There was an error uploading to experimental.'
        telemetry.error(e, additionalErrorDetails)
        setCriticalError(e.message)
      })

    return () => {
      if (upload?.status === 'ongoing') {
        upload.suspend()
      }
    }
  }, [uploader])

  useEffect(() => {
    if (upload) setTargetPath(upload?.targetPath)
  }, [upload])

  return (
    <UploaderContainer>
      <div className="spacer" />
      <Box display="flex" justifyContent="space-evenly" justifySelf="center">
        {uploaderComplete ? (
          <Typography variant="h6">Upload Complete</Typography>
        ) : (
          <Typography variant="h6">Uploading Files...</Typography>
        )}
      </Box>

      <div className="row-status-bar">
        <div className="indicator-zero">
          <Typography variant="caption"> {bytesDoneFormatted}</Typography>
        </div>
        <div className="indicator-total">
          <Typography variant="caption">{totalBytesFormatted}</Typography>
        </div>

        <Box display="flex" alignItems="center" className="progress-bar">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={percentage} color="primary" />
          </Box>
        </Box>
        {!uploaderComplete && (
          <Typography className="progress-indicator" variant="body2">
            {progressMessage}
          </Typography>
        )}
      </div>
      <Box display="flex" flexDirection="column" alignItems="center" className="row-message-area">
        {uploaderComplete && (
          <>
            <Typography variant="body1" style={{flex: '1', textAlign: 'center'}}>
              {progress.message}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center" maxWidth="100%">
              {targetPath && (
                <>
                  <Typography variant="overline">File Location</Typography>
                  <CopyToClipboard
                    text={targetPath}
                    onCopy={() => infoNotification('Upload S3 location copied to clipboard')}
                  >
                    <Path>{targetPath}</Path>
                  </CopyToClipboard>
                </>
              )}
            </Box>
          </>
        )}
        {!uploaderComplete && (
          <Typography variant="body2">
            {percentage !== 100
              ? 'Your files are now uploading. Please do not refresh the page or close the browser.'
              : 'Your files are being processed. The process will be finished momentarily.'}
          </Typography>
        )}
      </Box>
    </UploaderContainer>
  )
}

UploaderExperimentalUploadingPane.propTypes = {
  /** Function that triggers critical error on failure of upload */
  setCriticalError: PropTypes.func.isRequired,
  /** Uploader object generated from the experimental uploader sdk */
  uploader: PropTypes.shape({}).isRequired,
}

export default UploaderExperimentalUploadingPane
