import React, {useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import useToastNotification from '../hooks/useToastNotification'

import {Button, Tooltip, IconButton, Dialog, DialogActions, TextField} from '@mui/material'
import {Delete as DeleteIcon} from '@mui/icons-material'

const Container = styled('div')`
  overflow: hidden;
  background: white;
`

const Title = styled('h3')`
  padding: 0.3em 1em;
  font-weight: 300;
  font-size: 1.3em;
`

const StyledTextField = styled(TextField)`
  padding: 0.3em 1em;
`

const StyledDeleteButton = styled(Button)`
  color: var(--mdc-theme-error);
  border: 1px solid var(--mdc-theme-error);
`

/*
 * A button that when triggered engages the delete collection modal.
 *
 */

export default function GallerySummaryCollectionDeleteButton(props) {
  const {name, collection} = props
  const [deleteModal, setDeleteModal] = useState(false)
  const [collectionName, setCollectionName] = useState('')
  const {errorNotification, successNotification} = useToastNotification()

  const handleDeleteCollectionDescription = () => {
    if (collectionName !== name) {
      errorNotification('The collection name was entered incorrectly.')
      return
    }
  }

  const openDialogfn = () => {
    setDeleteModal(true)
  }

  const closeDialogFn = () => {
    setDeleteModal(false)
    setCollectionName('')
  }

  const handleTextOnChange = e => {
    setCollectionName(e.target.value)
  }

  return (
    <>
      <Container>
        <Tooltip title="Delete collection" aria-label="Delete-collection">
          <IconButton
            aria-label="delete-collection"
            size="small"
            color="default"
            onClick={openDialogfn}
            className="editIcon"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Container>
      {/* Delete Collection Modal */}
      {deleteModal && (
        <Dialog open onClose={closeDialogFn}>
          <Title>{`Type "${name}" below to confirm the deletion of the collection.`}</Title>
          <StyledTextField
            variant="outlined"
            value={collectionName}
            onChange={handleTextOnChange}
            error={collectionName !== name}
          />

          <DialogActions>
            <Button style={{fontSize: '0.8em'}} onClick={closeDialogFn}>
              Cancel
            </Button>
            <StyledDeleteButton
              style={{fontSize: '0.8em'}}
              onClick={handleDeleteCollectionDescription}
              variant="outlined"
              color="primary"
              autoFocus
              className="deleteButton"
            >
              Delete
            </StyledDeleteButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

GallerySummaryCollectionDeleteButton.propTypes = {
  /** The name of the collection */
  name: PropTypes.string.isRequired,
  /** The collection instance */
  collection: PropTypes.object.isRequired,
}
