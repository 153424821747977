import React, {useContext, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'

import {
  setUploadMetadataSchema,
  updateMetadataForm,
  setAvailableWorkflowStages,
  indicateWorkflowFailure,
} from '../actions/imageUploaderAction'
import useToastNotification from '../hooks/useToastNotification'
import {imagineServiceContext} from '../context/imagineServiceContext'

import UploaderImagineMetadataInput from './UploaderImagineMetadataInput'
import LoadingSpinnerInline from './LoadingSpinnerInline'
import {telemetry} from '../utilities/telemetry'
import {StoreState} from '../reducers/appStore'
import {UploaderMetadataContent} from '../reducers/imageUploaderReducer'
import type {PropertyValidationFailure} from '@bayer-int/imagine-sdk-browser'

const MetadataFormSection = styled.section`
  max-height: 583px;

  overflow-y: scroll;
  overflow-x: hidden;

  form {
    display: grid;
    grid-auto-rows: 80px;
    grid-row-gap: 2px;

    div {
      justify-self: center;
    }

    .two-rows {
      grid-row: span 2;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      width: 100%;
    }
  }
`

const Container = styled.div`
  width: 80%;
  margin: 80px auto 0 auto;
`

function UploaderImagineStageMetadataForm() {
  const {imagineSdk} = useContext(imagineServiceContext)
  const [formContentLoading, setFormContentLoading] = useState(true)
  const [formWarnings, setformWarnings] = useState({})
  const dispatch = useDispatch()
  const {infoNotification, errorNotification} = useToastNotification()

  const selectedCollection = useSelector<StoreState, string>(
    state => state.imageUploader.selectedCollection
  )
  const selectedProject = useSelector<StoreState, string>(
    state => state.imageUploader.selectedProject
  )
  const formContent = useSelector<StoreState, unknown[]>(
    state => state.imageUploader.metadataSchema
  )
  const formValues = useSelector<StoreState, UploaderMetadataContent>(
    state => state.imageUploader.metadataContent
  )
  const formErrors = useSelector<StoreState, PropertyValidationFailure[]>(
    state => state.imageUploader.metadataValidationResults
  )
  const setWorkflowStages = (stages: number[]) => dispatch(setAvailableWorkflowStages(stages))
  const setDialogError = (message: string) => {
    dispatch(indicateWorkflowFailure(message))
    setWorkflowStages([4])
  }
  const updateFormSchema = (schema: unknown[]) => dispatch(setUploadMetadataSchema(schema))
  const updateFormValues = (data: UploaderMetadataContent) => {
    dispatch(
      updateMetadataForm({
        ...formValues,
        properties: {
          ...formValues.properties,
          ...data,
          'project:name': selectedProject,
        },
      })
    )
  }

  // Restrict workflow stages if user edits the form
  // This also disables the upload button if the form was already successfully validated
  useEffect(() => {
    setWorkflowStages([1, 2])
  }, [formValues])

  // Populates the type of form elements that will appear on the form
  useEffect(() => {
    if (!selectedCollection || !imagineSdk) {
      return
    }

    const populateCollectionSchema = async () => {
      setFormContentLoading(true)
      const collection = await imagineSdk.fetchCollection({id: selectedCollection})
      const schema = await collection.itemSchema()

      updateFormSchema(
        Object.entries(schema.jsonSchema.properties.properties.properties).map(
          ([key, attributes]) => ({
            attribute: key,
            value: '',
            ...attributes,
          })
        )
      )
      setFormContentLoading(false)
    }

    populateCollectionSchema().catch(err => {
      const details = 'Error populating collection schema form.'
      telemetry.error(err, details)
      setDialogError(details)
      setFormContentLoading(false)
    })
  }, [selectedCollection])

  useEffect(() => {
    try {
      if (formErrors) {
        const isMissingGeometry =
          formErrors.filter(
            e =>
              e.message ===
              'A geometry was not provided. By default, a Point with coordinates [0, 0] will be used.'
          ).length > 0

        if (isMissingGeometry) {
          dispatch(
            updateMetadataForm({
              ...formValues,
              geometry: {
                type: 'Point',
                coordinates: [0, 0],
              },
            })
          )
        }
      }
    } catch (err) {
      const details =
        'Internal error occurred while checking for missing geometry. Please contact Location360 support.'
      telemetry.error(err, details)
      setDialogError(details)
    }
  }, [formErrors])

  useEffect(() => {
    const errorsPresent = formErrors?.length > 0

    // Prevent toast notification on form initial load
    if (!formContentLoading) {
      if (errorsPresent) {
        errorNotification('Errors found. Please fix errors before proceeding.', {timeout: 1000})
      } else {
        infoNotification('No errors found. Uploading is enabled.')
      }
    }
  }, [formErrors])

  // Map the form values for warnings
  useEffect(() => {
    const formErrorObject = {}
    if (formErrors?.length > 0) {
      formErrors.forEach(({message, violation}) => {
        const propertyName = violation.split('"')[1]
        formErrorObject[propertyName] = {message}
      })
    }
    setformWarnings(formErrorObject)
  }, [formErrors])

  // Base contents:
  // 1. A image (click and drag or plus sign)
  // 2. A preview of said image if possible without requiring upload

  // Seperate out the fields so the form can be organised effectively
  // Render objects and arrays later as they are less compact
  const restrictedFieldTypes = ['object', 'array', 'string']

  // Prevents use of the field geohash in any form.
  const restrictedTextFieldRe = /geohash/

  const acceptedField = f => !restrictedFieldTypes.includes(f.type)
  const acceptedArrayStructure = f => f.type === 'array' && f.items.type === 'string'
  const acceptedTextField = f => f.type === 'string' && !restrictedTextFieldRe.test(f.attribute)

  const acceptedFieldFilter = f =>
    acceptedField(f) || acceptedTextField(f) || acceptedArrayStructure(f)

  const acceptedFields = formContent.filter(acceptedFieldFilter)

  if (formContentLoading)
    return (
      <MetadataFormSection>
        <Container>
          <LoadingSpinnerInline message="Loading metadata form..." />
        </Container>
      </MetadataFormSection>
    )

  return (
    <MetadataFormSection>
      <form noValidate autoComplete="off">
        {acceptedFields.map((field, index) => {
          // Ignores attribute completely.
          if (field.attribute === 'project:name') return <></>

          return (
            <UploaderImagineMetadataInput
              key={`metadata-prop-input-${field.attribute}-${index}`}
              field={field}
              value={formValues.properties[field.attribute]}
              onChange={updateFormValues}
              warning={formWarnings[field.attribute]}
            />
          )
        })}
      </form>
    </MetadataFormSection>
  )
}

export default UploaderImagineStageMetadataForm
