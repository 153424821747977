import React, {useEffect, useState} from 'react'

import styled from 'styled-components'

import {TextField} from '@mui/material'
import useDirectoryPageState from '../hooks/useDirectoryPageState'

const FilterSection = styled('section')`
  .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
    margin-top: 9px;
  }
`

function DirectoryGridFilter() {
  const {
    setDirectoryPageFilter,
    state: {contentType},
  } = useDirectoryPageState()

  const [filter, setFilter] = useState('')

  useEffect(() => {
    setDirectoryPageFilter(filter)

    return () => {
      setDirectoryPageFilter('')
    }
  }, [filter])

  const label = `Filter by ${contentType}`

  return (
    <FilterSection style={{flexGrow: 1}}>
      <TextField
        value={filter}
        onChange={e => {
          setFilter(e.target.value)
        }}
        label={label}
        placeholder={label}
        margin="normal"
        variant="outlined"
        fullWidth
      />
    </FilterSection>
  )
}

export default DirectoryGridFilter
