import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {galleryItemContext} from '../context/galleryItemContext'
import {telemetry} from '../utilities/telemetry'

const DictionaryTitle = styled.dt`
  font-weight: 400;
  font-size: 90%;
`
const DictionaryItem = styled.dd`
  font-weight: 300;
  font-size: 80%;
`

function CardGalleryViewItemCustomList({schemaKeys}) {
  const {properties} = useContext(galleryItemContext)

  if (!Array.isArray(schemaKeys)) {
    telemetry.error(new Error(`improper data type for properties ${JSON.stringify(schemaKeys)}`))
    return <></>
  }

  const elements = schemaKeys.map((key, index) => {
    let element = properties[key]
    if (typeof element === 'object') element = JSON.stringify(element)

    return (
      <React.Fragment key={`item-${key}-${index}`}>
        <DictionaryTitle>{key}</DictionaryTitle>
        <DictionaryItem>{element ?? '(none provided)'}</DictionaryItem>
      </React.Fragment>
    )
  })

  return <dl>{elements}</dl>
}

CardGalleryViewItemCustomList.propTypes = {
  schemaKeys: PropTypes.arrayOf(PropTypes.string),
}

CardGalleryViewItemCustomList.defaultProps = {
  schemaKeys: [],
}

export default CardGalleryViewItemCustomList
