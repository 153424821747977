import React, {createContext, useReducer} from 'react'
import PropTypes from 'prop-types'

const initialState = {
  error: false,
  loading: true,
  data: [],
  maxCount: null,
  itemsPopulated: 0,
  filter: '',
}

function reducer(state, action) {
  switch (action.type) {
    case 'set-error':
      return {...state, loading: false, error: action.error}

    case 'set-filter':
      return {...state, filter: action.filter}

    case 'set-data':
      return {
        ...state,
        data: action.content,
        loading: false,
      }

    case 'increment-data-population-count':
      return {
        ...state,
        itemsPopulated: state.itemsPopulated + 1,
      }
    case 'item-populating-complete':
      return {
        ...state,
        itemsPopulated: state.maxCount,
      }
    case 'set-max-count':
      return {...state, maxCount: action.maxCount}

    default:
      throw new Error('Unknown reducer case ', action.type)
  }
}

const directoryPageContext = createContext(initialState)
const {Provider} = directoryPageContext

/**
 * Provides directory-page relevant data storage
 * */
function DirectoryPageProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const {contentType, children} = props

  if (['project', 'collection', 'album', 'extension'].indexOf(contentType) === -1) {
    throw new Error('One of "project", "collection" or "album" must be set to true')
  }

  return <Provider value={{state: {...state, contentType}, dispatch}}>{children}</Provider>
}

DirectoryPageProvider.propTypes = {
  /** Indicator of the type of content used in a given directory page */
  contentType: PropTypes.string.isRequired,
  /** Child react elements.  ...It's a context provider. */
  children: PropTypes.node.isRequired,
}

export {directoryPageContext, DirectoryPageProvider}
