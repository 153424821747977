import React, {useContext} from 'react'
import {Button, DialogTitle, Autocomplete, Chip} from '@mui/material'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {useFormikContext} from 'formik'
import {
  CreateCollectionFields,
  TitleSection,
  Container,
  StyledTextField,
  DialogFooter,
} from './SelfService'
import {FormikErrorMessage} from './FormikErrorMessage'
import {AppEntitlementFields} from './AppEntitlementFields'
import {GroupEntitlementFields} from './GroupEntitlementFields'
import {ExtensionSelectField} from './ExtensionSelectField'

export function CollectionForm({onClose, recentExtensions}) {
  const {loading, error} = useContext(imagineServiceContext)
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
    isValid,
    touched,
    handleSubmit,
  } = useFormikContext<CreateCollectionFields>()

  return (
    <form onSubmit={handleSubmit} role="form">
      <TitleSection>
        <DialogTitle>Collection Form</DialogTitle>
      </TitleSection>
      <Container>
        <StyledTextField
          style={{marginTop: '20px'}}
          label="Collection Name"
          name="name"
          value={values.name}
          required
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
        />
        <FormikErrorMessage name="name" />
        <StyledTextField
          style={{marginTop: '20px'}}
          label="Collection Description"
          value={values.description}
          name="description"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
        />
        <FormikErrorMessage name="description" />
        <StyledTextField
          style={{marginTop: '20px'}}
          label="License"
          name="license"
          value={values.license}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
        />
        <FormikErrorMessage name="license" />
        <GroupEntitlementFields onClose={onClose} />
        <AppEntitlementFields />
        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={values.keywords}
          renderInput={params => (
            <StyledTextField
              {...params}
              name="keywords"
              label="Keywords"
              style={{marginTop: '20px'}}
            />
          )}
          onBlur={handleBlur}
          onChange={(_, value) => {
            setFieldValue('keywords', value)
          }}
          renderTags={(tagValue, getTagProps) => (
            <>
              {tagValue.map((option, index) => {
                return <Chip label={option} {...getTagProps({index})} key={option} />
              })}
            </>
          )}
        />
        <FormikErrorMessage name="keywords" />
        <ExtensionSelectField onClose={onClose} recentExtensions={recentExtensions} />
        <DialogFooter>
          <Button size="large" onClick={onClose}>
            Close
          </Button>
          <Button
            type="submit"
            disabled={
              isSubmitting || loading || !!error || !isValid || !Object.keys(touched).length
            }
            sx={{marginLeft: '10px'}}
            variant="contained"
            size="large"
          >
            Submit
          </Button>
        </DialogFooter>
      </Container>
    </form>
  )
}

// <BorderedSection title="Providers" style={{marginTop: '20px'}}>
//   <div
//     style={{
//       padding: '5px',
//       margin: 0,
//       width: '100%',
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//     }}
//   >
//     <FieldArray
//       name="providers"
//       render={({push, remove}) => (
//         <>
//           <CollectionAttributesTable>
//             {values.providers.map(({name, description, roles, url}, i) => (
//               <li key={i} className="CollectionAttributeTableRow">
//                 <CollectionAttributesItem>
//                   <StyledFormControl style={{width: '250px'}}>
//                     <StyledTableTextField
//                       label="Name"
//                       variant="outlined"
//                       name={`providers.${i}.name`}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       value={name}
//                       required
//                     />
//                     <FormikErrorMessage name={`providers.${i}.name`} />
//                   </StyledFormControl>
//                   <StyledFormControl style={{width: '250px'}}>
//                     <StyledTableTextField
//                       label="Url"
//                       variant="outlined"
//                       name={`providers.${i}.url`}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                       value={url}
//                     />
//                     <FormikErrorMessage name={`providers.${i}.url`} />
//                   </StyledFormControl>
//                   <Autocomplete
//                     multiple
//                     disableClearable
//                     options={['host', 'licensor', 'processor', 'producer']}
//                     value={roles}
//                     renderInput={params => (
//                       <StyledTextField
//                         {...params}
//                         name={`providers.${i}.roles`}
//                         label="Roles"
//                         style={{marginTop: 0}}
//                       />
//                     )}
//                     onBlur={handleBlur}
//                     onChange={(_, value) => {
//                       setFieldValue(`providers.${i}.roles`, value)
//                     }}
//                     renderTags={(tagValue, getTagProps) => (
//                       <>
//                         {tagValue.map((option, index) => {
//                           return (
//                             <Chip label={option} {...getTagProps({index})} key={option} />
//                           )
//                         })}
//                       </>
//                     )}
//                   />
//                   <FormikErrorMessage name={`providers.${i}.roles`} />
//                   <div
//                     style={{
//                       display: 'flex',
//                       justifyContent: 'end',
//                       alignItems: 'center',
//                       height: '53.13px',
//                       flexGrow: 1,
//                     }}
//                   >
//                     <IconButton
//                       style={{height: '34px'}}
//                       onClick={() => remove(i)}
//                       size="small"
//                     >
//                       <DeleteForeverIcon />
//                     </IconButton>
//                   </div>
//                 </CollectionAttributesItem>
//                 <StyledFormControl
//                   style={{
//                     display: 'flex',
//                     margin: '8px',
//                     marginTop: '10px', // needed a little extra so the label wouldn't overlap with above field
//                     flexGrow: 1,
//                   }}
//                 >
//                   <StyledTableTextField
//                     style={{flexGrow: 1}}
//                     label="Description"
//                     variant="outlined"
//                     name={`providers.${i}.description`}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     value={description}
//                     multiline
//                   />
//                   <FormikErrorMessage name={`providers.${i}.description`} />
//                 </StyledFormControl>
//               </li>
//             ))}
//           </CollectionAttributesTable>
//           <Button
//             style={values.providers?.length ? {} : {marginTop: '-12px'}}
//             onClick={() => push({name: '', description: '', roles: [], url: ''})}
//           >
//             Add Provider
//           </Button>
//         </>
//       )}
//     />
//   </div>
// </BorderedSection>
