import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import FallbackImage from './FallbackImage'
import DirectoryCardOwnershipAlbum from './DirectoryCardOwnershipAlbum'
import DirectoryCardContentProject from './DirectoryCardContentProject'

import DirectoryCardScrollingDescription from './DirectoryCardScrollingDescription'
import useCollectionPin from '../hooks/useCollectionPin'

import {IconButton, Tooltip} from '@mui/material'
import {Edit as EditIcon} from '@mui/icons-material'
import {PushPinOutlined as PushPinOutlinedIcon, PushPin as PushPinIcon} from '@mui/icons-material/'

import DirectoryCardItemsCount from './DirectoryCardItemsCount'

const CardLayout = styled('div')`
  /* Colouring */
  background-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  transition: background-color, box-shadow 0.35s ease-in-out;
  &:hover {
    background: #f8f8ff;
    box-shadow: 0 2px 1px -1px rgba(70, 70, 70, 0.2), 0 1px 1px 0 rgba(70, 70, 70, 0.14),
      0 1px 3px 0 rgba(70, 70, 70, 0.12);
  }

  /* Layout */
  display: grid;
  grid-template-columns: 1fr 175px;
  grid-template-rows: 175px;

  width: 530px;
  height: 175px;
  overflow: hidden;

  .thumbnail {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    height: 175px;
  }

  /* Subelements */
  #content {
    padding-bottom: 1px;
    overflow: hidden;
    height: 175px;

    display: grid;
    grid-template-rows: fit-content(81px) 1fr;
    grid-row-gap: 10px;

    .align-top {
      align-self: flex-start;
    }
    .align-bottom {
      align-self: flex-end;
    }
  }
`

const Title = styled.div`
  grid-column: 1 / 2;
  text-transform: uppercase;

  padding: 3px 0 0 5px;
  font-size: 1.2rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  letter-spacing: 3px;
  font-weight: 300;
  line-height: 23px;
  margin-top: 3px;
  max-width: 395px;
  overflow-x: hidden;

  z-index: 10;
  background: white;
`

const LastUpdatedContainer = styled.div`
  padding: 0 5px;
  color: var(--mdc-theme-text-disabled-on-background);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

function DirectoryEditButton({onClick}) {
  return (
    <div style={{top: '135px', left: '135px', position: 'relative', height: 0, width: 0}}>
      <IconButton
        style={{
          backgroundColor: 'var(--mdc-theme-primary)',
          color: 'white',
        }}
        size="small"
        onClick={onClick}
      >
        <EditIcon />
      </IconButton>
    </div>
  )
}

/**
 * Displays a single card on the homepage. Represents a project, collection or album.
 */
const DirectoryCard = function (props) {
  const {
    version,
    count,
    title,
    name,
    thumbnail,
    notAdmin,
    notPublish,
    notDelete,
    notDownload,
    contentType,
    description,
    parent,
    lastUpdated,
    userPinnedCollections,
    onClickEdit,
  } = props
  const {addToPinnedCollections, removeFromPinnedCollections} = useCollectionPin()

  if (['projects', 'collections', 'albums', 'extensions'].indexOf(contentType) === -1) {
    throw new Error(
      'DirectoryCard: contentType must be one of "project", "collection", "album", or "extension". ContentTyoe: ',
      contentType
    )
  }

  const titleCopy = (title || name).split('_').join(' ')
  const [isPinned, setIsPinned] = useState(userPinnedCollections.includes(name))

  const pinnedCollectionHandler = event => {
    event.preventDefault()
    if (isPinned) {
      removeFromPinnedCollections(userPinnedCollections, name)
      setIsPinned(false)
    } else {
      addToPinnedCollections(userPinnedCollections, name)
      setIsPinned(true)
    }
  }

  return (
    <Link
      to={
        contentType === 'extensions'
          ? '/directory/extensions'
          : `/${contentType.split('s')[0]}/${name}`
      }
    >
      <CardLayout>
        <div id="content">
          <Title className="title">{titleCopy}</Title>

          <>
            {contentType === 'albums' ||
              (contentType === 'extensions' && (
                <div className="align-top">
                  <DirectoryCardScrollingDescription description={parent.description} />
                </div>
              ))}
            {contentType === 'collections' && (
              <>
                <div className="align-top">
                  <DirectoryCardScrollingDescription description={description} />
                </div>
                <LastUpdatedContainer className="align-bottom">
                  <span>{lastUpdated && `Last updated ${lastUpdated}`}</span>
                  <span>
                    <Tooltip
                      title={isPinned ? 'Unpin Collection' : 'Pin Collection'}
                      aria-label="pin-collection"
                    >
                      <span onClick={pinnedCollectionHandler}>
                        <IconButton aria-label="pin-collection" size="small" color="primary">
                          {isPinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </span>
                </LastUpdatedContainer>
              </>
            )}
            {contentType === 'projects' && (
              <div className="align-bottom">
                <DirectoryCardContentProject
                  publishPermission={!notPublish}
                  deletePermission={!notDelete}
                  downloadPermission={!notDownload}
                />
              </div>
            )}
          </>
        </div>

        <div className="thumbnail">
          <>
            {(!notAdmin || contentType === 'extensions') && contentType !== 'albums' && (
              <DirectoryEditButton
                onClick={evt => {
                  evt.preventDefault()
                  onClickEdit({id: name, version})
                }}
              />
            )}
            {!isNaN(Number(count)) && (
              <DirectoryCardItemsCount contentType={contentType} count={count} />
            )}
            {contentType !== 'extensions' && (
              <>
                {contentType === 'albums' && (
                  <DirectoryCardOwnershipAlbum createdBy={parent.createdBy} />
                )}
                <FallbackImage src={thumbnail} small />
              </>
            )}
          </>
        </div>
      </CardLayout>
    </Link>
  )
}

export default DirectoryCard
