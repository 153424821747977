import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {useDispatch} from 'react-redux'

import {Button, IconButton, Link as MuiLink, Tooltip, Typography} from '@mui/material'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CollectionsIcon from '@mui/icons-material/Collections'

import {initializeUploader} from '../actions/imageUploaderAction'
import {imagineServiceContext} from '../context/imagineServiceContext'
import BasicSearchBar from './BasicSearchBar'
import AdvancedSearchContainer from './AdvancedSearchContainer'
import ControlBarIconShareUrl from './ControlBarIconShareUrl'

const ControlBarSection = styled('section')`
  background: white;
  display: grid;
  grid-template-columns: 1fr 95px 125px 45px 45px 45px;
  padding: 8px 10px;
  grid-column-gap: 5px;
  align-items: center;
  justify-items: center;

  .help-page-link {
    align-self: center;

    button {
      height: 53.625px;
    }
  }

  .controlbar-icon {
    height: 30px;
  }
`

function ControlBar() {
  const dispatch = useDispatch()
  const {loading, error} = useContext(imagineServiceContext)

  const urlToBeCopied = window.location.href

  return (
    <ControlBarSection>
      <BasicSearchBar />
      <AdvancedSearchContainer />
      <MuiLink
        className="help-page-link"
        href="https://location360.assets.engineering/docs/imagery/imagine_loc360/visualization/"
        target="_blank"
      >
        <Button size="small" color="primary">
          <Typography variant="button">Imagine Docs</Typography>
        </Button>
      </MuiLink>
      <div className="controlbar-icon">
        <Tooltip title="Upload images" aria-label="upload">
          <span>
            <IconButton
              aria-label="Upload"
              size="small"
              color="primary"
              onClick={() => dispatch(initializeUploader())}
              disabled={loading || error}
            >
              <CloudUploadIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <div className="controlbar-icon">
        <ControlBarIconShareUrl urlToBeCopied={urlToBeCopied} />
      </div>
    </ControlBarSection>
  )
}

export default ControlBar
