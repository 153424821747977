import {telemetry} from './telemetry'

const BING_MAPS_API_KEY = 'Aqyf0AFZg980DAAnDpwgzmKmSjyhalLz85vualgJJJxi2Mh4ofU66CfwGo-d6o9B'

const exports = {resource: null}

export default exports

async function getBingMapResource() {
  const response = await fetch(
    `https://dev.virtualearth.net/REST/V1/Imagery/Metadata/CanvasGray?output=json&include=ImageryProviders&key=${BING_MAPS_API_KEY}&uriScheme=https`
  )
  if (response.status === 401) {
    telemetry.error(new Error('Invalid Bing Maps API key'))
  }
  const responseText = await response.text()
  try {
    const {resourceSets} = JSON.parse(responseText)
    return resourceSets[0].resources[0]
  } catch (error) {
    telemetry.error(error, 'Bing Maps API response was not valid.')
  }
  throw Error('The Bing Maps session could not be started.')
}

async function loadBingMaps() {
  exports.resource = await getBingMapResource()
}

export const bingLoaded = loadBingMaps()
