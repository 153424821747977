import React, {useContext} from 'react'
import {imagineServiceContext} from '../context/imagineServiceContext'
import LoadingSpinner from './LoadingSpinner'
import CardGenericError from './CardGenericError'
import DialogContentContainer from './styled/DialogContentContainer'
import {FormikHelpers} from 'formik'
import type {CreateStacExtensionOptions} from '@bayer-int/imagine-sdk-browser'
import {CreateCollectionFields, StyledDialog} from './SelfService'
import {CollectionFormFormik} from './CollectionFormFormik'

export function CollectionFormDialog({
  open,
  onClose,
  recentExtensions,
  initialValues,
  onSubmit,
}: {
  open: boolean
  onClose: () => void
  recentExtensions: CreateStacExtensionOptions[]
  initialValues?: CreateCollectionFields
  onSubmit: (
    values: CreateCollectionFields,
    formikHelpers: FormikHelpers<CreateCollectionFields>
  ) => void | Promise<any>
}) {
  const {loading, error} = useContext(imagineServiceContext)

  if (!open) {
    return <></>
  }

  if (loading) {
    return <LoadingSpinner message="Please wait. Connecting to Imagine service" />
  }

  if (error) {
    return (
      <CardGenericError
        title="Unable to upload data"
        description="The Imagine UI is unable to upload data without a connection to the Imagine service"
      />
    )
  }

  return (
    <DialogContentContainer>
      <StyledDialog
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        className="upload-dialog"
        maxWidth="md"
      >
        <CollectionFormFormik
          onClose={onClose}
          recentExtensions={recentExtensions}
          initialValues={initialValues}
          onSubmit={onSubmit}
        />
      </StyledDialog>
    </DialogContentContainer>
  )
}
