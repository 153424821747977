import React, {useEffect} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import {DirectoryPageProvider} from '../context/directoryPageContext'
import {ItemProvider} from '../context/itemContext'

import ItemPage from './ItemPage'

import DirectoryPageAlbums from './DirectoryPageAlbums'
import DirectoryPageCollections from './DirectoryPageCollections'
import DirectoryPageProjects from './DirectoryPageProjects'

import GalleryPageAlbum from './GalleryPageAlbum'
import GalleryPageAdvancedSearch from './GalleryPageAdvancedSearch'
import GalleryPageBasicSearch from './GalleryPageBasicSearch'
import GalleryPageCollection from './GalleryPageCollection'
import GalleryPageProject from './GalleryPageProject'

import Page404 from './404Page'

import {isLocal} from '../utilities/serviceBindings'
import DirectoryPageExtensions from './DirectoryPageExtensions'
import {JSONSchemaFormikForm} from '../components/JSONSchemaForm'
import useToastNotification from '../hooks/useToastNotification'

const GLOBAL_WARNING_NOTIFICATION = ''

export default function Router() {
  const {warningNotification} = useToastNotification()

  useEffect(() => {
    if (GLOBAL_WARNING_NOTIFICATION) {
      warningNotification(GLOBAL_WARNING_NOTIFICATION, {timeout: 100000})
    }
  }, [])

  const Item = () => (
    <ItemProvider>
      <ItemPage />
    </ItemProvider>
  )

  const AlbumDirectory = () => (
    <DirectoryPageProvider contentType="album">
      <DirectoryPageAlbums />
    </DirectoryPageProvider>
  )

  const CollectionDirectory = () => (
    <DirectoryPageProvider contentType="collection">
      <DirectoryPageCollections />
    </DirectoryPageProvider>
  )

  const ProjectDirectory = () => (
    <DirectoryPageProvider contentType="project">
      <DirectoryPageProjects />
    </DirectoryPageProvider>
  )

  const ExtensionDirectory = () => (
    <DirectoryPageProvider contentType="extension">
      <DirectoryPageExtensions />
    </DirectoryPageProvider>
  )

  return (
    <BrowserRouter basename={isLocal ? '/imagine' : '/'}>
      <Routes>
        <Route path="/tmp" element={<JSONSchemaFormikForm />} />
        <Route path="/item" element={<Item />} />
        <Route path="/project/:project" element={<GalleryPageProject />} />
        <Route path="/collection/:collection" element={<GalleryPageCollection />} />
        <Route path="/album/:album" element={<GalleryPageAlbum />} />
        <Route path="/search/:searchTerm" element={<GalleryPageBasicSearch />} />
        <Route path="/advanced-search" element={<GalleryPageAdvancedSearch />} />
        <Route path="/directory/albums" element={<AlbumDirectory />} />
        <Route path="/directory/collections" element={<CollectionDirectory />} />
        <Route path="/directory/projects" element={<ProjectDirectory />} />
        <Route path="/directory/extensions" element={<ExtensionDirectory />} />
        <Route path="/" element={<ProjectDirectory />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  )
}
