import type {
  UploadProgress as S3UploadProgress,
  Uploader as S3UploaderInternal,
  SizeMetrics,
  RateMetrics,
} from '@monsantoit/s3-multipart-uploader'
import {getBearerToken} from '../utilities/initializeProfile'
import {isProd} from '../utilities/serviceBindings'
import {telemetry} from '../utilities/telemetry'

const serviceUrls = {
  geoserver: {
    nonprod: 'https://geoserver-core-api.np.location360.ag/geoserver',
    prod: 'https://geoserver-core-api.location360.ag/geoserver',
  },
  upload: {
    nonprod:
      'https://uav-mission-upload-api.imagery-team-development.us-east-1-01.aws.k8s.loc360-np.monsanto.net',
    prod: 'https://uav-mission-upload-api.imagery-team-production.us-east-1-01.aws.k8s.loc360.monsanto.net',
  },
}

export function getIn(object: any, path: (string | number)[]): any {
  if (!path?.length) {
    return object
  }
  let result = object
  path.forEach(k => {
    result = result[k]
  })
  return result
}

export class HyperspectralPipelineMessageFactory implements IPipelineMessageFactory {
  _options: HyperspectralPipelineMessageFactoryOptions

  constructor(options: HyperspectralPipelineMessageFactoryOptions) {
    this._options = options
  }

  create(event: PipelineEvent): IPipelineMessage {
    const timestamp = new Date().toISOString()
    const data = {
      service_id: 'imagery_uploader',
      timestamp,
      message: {
        text: 'Start uploading hyperspec package',
        properties: {
          bucket: this._options.bucket,
          mission_key: this._options.missionKey,
          start_time: this._options.collectionDate,
          user_id: this._options.userId,
          collection_date: this._options.collectionDate,
          package_size: this._options.packageSize,
          workflow: 'hyperspectral',
        },
      },
      level: event,
      workflow_properties: {
        pipeline_ids: ['hyperspectral'],
        sub_pipeline_ids: ['hyperspectral'],
        workflow_ids: ['hyperspectral'],
        workflow_seqs: {
          ['hyperspectral']: [],
        },
      },
      job: {
        job_ids: {
          mission_id: this._options.missionKey,
        },
        properties: {
          platform_type: 'hyperspectral',
        },
      },
    }
    switch (event) {
      case PipelineEventValues.START:
      case PipelineEventValues.RESTART:
        return data
      case PipelineEventValues.DONE:
        data.message.text = 'Done uploading hyperspec package'
        return data
      default:
        throw new Error('Invalid pipeline event')
    }
  }
}

export const VALID_AT_LEAST_ONE_FILE = 'Must have at least one file.'
export const VALID_AT_LEAST_ONE_VNIR = 'Must have at least one VNIR file.'

export function createHyperspectralData(options: HyperspectralMetadataOptions) {
  const {env, files, userId, geohash} = options
  if (!files?.length) {
    throw new Error(VALID_AT_LEAST_ONE_FILE)
  }
  const names = files.map(f => f.name)
  const vnirRe = new RegExp('.*VNIR.*', 'i')
  if (!names.some(n => vnirRe.test(n))) {
    throw new Error(VALID_AT_LEAST_ONE_VNIR)
  }
  const bucket =
    env === 'prod' ? 'bayer.loc360-prod.use1.imagery-core' : 'bayer.loc360-np.use1.imagery-core'
  const packageSize = files.reduce((total, f) => total + f.size, 0)
  const collectionDate = files[0].lastModified
  const collectionDateString = new Date(collectionDate).toISOString()
  const missionKeyDateString = collectionDateString.split(/:|-|T/).slice(0, -1).join('')
  const missionKey = `M_${missionKeyDateString}_${geohash}`
  const missionPath = `hyperspec/${geohash}/${missionKeyDateString}`
  const targetPath = `s3://${bucket}/${missionPath}`
  const s3Files = files.map(file => {
    return {
      file,
      s3Path: `${targetPath}/${file.name}`,
    }
  })
  const missionJson: MissionJson = {
    collection_date: collectionDateString,
    geohash,
    mission_key: missionKey,
    upload_user: userId,
    workflow: env,
    images: s3Files.map(f => ({original_name: f.file.name, path: f.s3Path})),
  }
  const missionJsonFile = new File([JSON.stringify(missionJson)], 'mission.json')
  const missionS3File = {file: missionJsonFile, s3Path: `${targetPath}/${missionKey}.json`}
  const allFiles = s3Files.concat(missionS3File)

  return {
    files: allFiles,
    bucket,
    missionKey,
    packageSize,
    userId,
    collectionDate,
    targetPath,
  }
}

export class S3Uploader implements IUploader {
  _fetchAuthorization: AuthorizationFetcher
  _s3UploaderModule: any
  _s3Uploader?: S3UploaderInternal
  _serviceUrl: string
  _onProgress: ProgressHandler

  constructor(options: S3UploaderOptions) {
    this._fetchAuthorization = options.fetchAuthorization
    this._serviceUrl = serviceUrls.upload[options.env]
    this._onProgress = options.onProgress
  }

  async init() {
    if (this._s3UploaderModule) {
      return
    }

    const source = await fetch(
      'https://unpkg.loc360.monsanto.net/@monsantoit/s3-multipart-uploader@1',
      {
        headers: {
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      }
    ).then(r => r.blob())

    this._s3UploaderModule = await import(/* webpackIgnore: true */ URL.createObjectURL(source))

    this._s3Uploader = new this._s3UploaderModule.Uploader({
      authorization: this._fetchAuthorization,
      onProgress: this._onProgress,
      serviceUrl: this._serviceUrl,
    })
  }

  async exists(files: S3File[]): Promise<boolean> {
    if (!this._s3Uploader) {
      throw new Error('S3 Uploader was not initialized')
    }
    if (!files?.length) {
      return false
    }
    const s3Prefix = 's3://'
    const root = files[0]?.s3Path
    if (!root?.startsWith(s3Prefix)) {
      throw new Error('Invalid S3 path prefix.')
    }
    if (!root?.substring(s3Prefix.length)?.includes('/')) {
      throw new Error('Invalid S3 path.')
    }
    const folder = root.split('/').slice(0, -1).join('/') + '/'
    const existingFiles = await this._s3Uploader.existingObjects(folder)
    return files.some(f => existingFiles.includes(f.s3Path))
  }

  async upload(file: S3File): Promise<void> {
    if (!this._s3Uploader) {
      throw new Error('S3 Uploader was not initialized')
    }
    const result = this._s3Uploader.upload({
      attempts: 3,
      blob: file.file,
      s3Uri: file.s3Path,
    })
    await result.completed
  }
}

export class PipelineMessenger implements IPipelineMessenger {
  _serviceUrl: string
  _fetchAuthorization: AuthorizationFetcher
  _messageFactory: IPipelineMessageFactory

  constructor(options: PipelineMessengerOptions) {
    this._fetchAuthorization = options.fetchAuthorization
    this._serviceUrl = serviceUrls.upload[options.env]
    this._messageFactory = options.messageFactory
  }

  async send(event: PipelineEvent): Promise<void> {
    const token = await this._fetchAuthorization()
    const message = this._messageFactory.create(event)
    const res = await this._sendMessage(message, this._serviceUrl, token)
    if (!res.ok) {
      throw new Error(`Message send error: ${await res.text()}`)
    }
  }

  _sendMessage(data: any, serviceUrl: string, token: string): Promise<Response> {
    return fetch(`${serviceUrl}/message`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }
}

export type IPipelineMessenger = {
  send(msg: any): Promise<void>
}

export type IPipelineMessage = any

export type IPipelineMessageFactory = {
  create(event: PipelineEvent): IPipelineMessage
}

export type HyperspectralPipelineMessageFactoryOptions = {
  bucket: string
  missionKey: string
  packageSize: number
  userId: string
  collectionDate: string
}

enum PipelineEventValues {
  START = 'START',
  RESTART = 'RESTART',
  DONE = 'DONE',
}

export type PipelineEvent = 'START' | 'RESTART' | 'DONE'

type Env = 'prod' | 'nonprod'

export type PipelineMessengerOptions = {
  env: Env
  fetchAuthorization: AuthorizationFetcher
  messageFactory: IPipelineMessageFactory
}

export type S3File = {
  file: File
  s3Path: string
}

type HyperspectralMetadataOptions = {
  env: Env
  files: File[]
  userId: string
  geohash: string
}

export type ProgressHandler = (progress: S3UploadProgress) => void

export type HyperspectralUploaderOptions = {
  onProgress?: ProgressHandler
  uploader: IUploader
  messenger: IPipelineMessenger
}

export type IUploader = {
  exists(data: any): Promise<boolean>
  upload(data: any): Promise<void>
}

export type AuthorizationFetcher = () => Promise<string>

export type Envs = 'prod' | 'nonprod'

export type S3UploaderOptions = {
  env: Envs
  fetchAuthorization: AuthorizationFetcher
  onProgress: ProgressHandler
}

type ImageSource = {
  original_name: string
  path: string
}

export const initialProgress: UploadProgress = {
  bytesDone: 0,
  bytesRemaining: 0,
  bytesTotal: 0,
  filesDone: 0,
  filesRemaining: 0,
  filesTotal: 0,
  message: '',
  rate: 0,
  timeRemaining: 0,
  error: undefined,
  status: undefined,
}

type MissionJson = {
  collection_date: string
  geohash: string
  mission_key: string
  upload_user: string
  workflow: Envs
  images: ImageSource[]
}

export type Upload = {
  targetPath: string
  status: string
  suspend: () => void
}

export type Uploader = {
  missionKey: string
  upload(cb: (progress: UploadProgress) => void): Upload
}

export class HyperspecUploader implements Uploader {
  _env: string
  _fetchAuthorization: AuthorizationFetcher
  _messenger: PipelineMessenger
  _data: any
  missionKey: string

  constructor(options: {
    files: File[]
    isProd: boolean
    fetchAuthorization: AuthorizationFetcher
    userId: string
    geohash: string
  }) {
    this._env = options.isProd ? 'prod' : 'nonprod'
    this._fetchAuthorization = options.fetchAuthorization

    this._data = createHyperspectralData({
      env: this._env as any,
      files: options.files,
      userId: options.userId,
      geohash: options.geohash,
    })

    this.missionKey = this._data.missionKey

    const messageFactory = new HyperspectralPipelineMessageFactory({
      bucket: this._data.bucket,
      missionKey: this.missionKey,
      packageSize: this._data.packageSize,
      userId: this._data.userId,
      collectionDate: new Date(this._data.collectionDate).toISOString(),
    })

    this._messenger = new PipelineMessenger({
      env: this._env as any,
      fetchAuthorization: options.fetchAuthorization,
      messageFactory,
    })
  }

  async _upload(onProgress: (progress: UploadProgress) => void) {
    let lastProgressUpdate = initialProgress
    const _onProgress = (progress: UploadProgress) => {
      lastProgressUpdate = progress
      onProgress(progress)
    }
    try {
      const uploader = new S3Uploader({
        env: this._env as any,
        fetchAuthorization: this._fetchAuthorization,
        onProgress: _onProgress,
      })
      await uploader.init()
      if (await uploader.exists(this._data.files)) {
        await this._messenger.send(PipelineEventValues.RESTART)
      } else {
        await this._messenger.send(PipelineEventValues.START)
      }
      await Promise.all(this._data.files.map((f: any) => uploader.upload(f)))
      await this._messenger.send(PipelineEventValues.DONE)
      onProgress({...lastProgressUpdate, status: 'complete'})
    } catch (err) {
      telemetry.error(err, 'Error uploading hyperspectral data.')
      onProgress({...lastProgressUpdate, status: 'error-fatal', message: err.message})
    }
  }

  upload(onProgress: (progress: UploadProgress) => void) {
    this._upload(onProgress)
    return this._data
  }
}

export type InitializeHyperspectralUploaderOptions = {
  files: File[]
  geohash: string
  userId: string
}

export type UploadStatus =
  | 'complete'
  | 'complete-partial'
  | 'error-fatal'
  | 'error-file'
  | 'evaluating'
  | 'ongoing'
  | 'suspended'

export type UploadProgress = RateMetrics &
  SizeMetrics & {
    error?: Error
    fileName?: string
    filesDone: number
    filesRemaining: number
    filesTotal: number
    message?: string
    status?: UploadStatus
  }

export async function initializeHyperspectralUploader(
  options: InitializeHyperspectralUploaderOptions
) {
  const {files, geohash, userId} = options

  return new HyperspecUploader({
    files,
    isProd,
    fetchAuthorization: async () => `Bearer ${await getBearerToken()}`,
    userId,
    geohash: geohash,
  })
}
