import React from 'react'
import PropTypes from 'prop-types'
import {Typography} from '@mui/material'

/**
 * Text used to display the current load state on a directory page
 */
function TextIndicatorDirectoryLoadState(props) {
  const {
    loading,
    contentType,
    count: {current, total},
  } = props

  if (['projects', 'collections', 'albums', 'extensions'].indexOf(contentType) === -1)
    throw new Error(
      'TextIndicatorDirectoryLoadState: prop contentType must be either "project", "collection", "album", or "extension". Current value: ',
      contentType
    )

  return (
    <Typography>
      {loading ? 'Loading' : 'Viewing'} {current} out of {total} {contentType}
    </Typography>
  )
}

TextIndicatorDirectoryLoadState.propTypes = {
  loading: PropTypes.bool,
  contentType: PropTypes.string.isRequired,
  count: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
}

TextIndicatorDirectoryLoadState.defaultProps = {
  loading: false,
}

export default TextIndicatorDirectoryLoadState
