import React from 'react'
import {Provider as ReduxProvider} from 'react-redux'

import {FeatureFlagProvider} from './context/featureFlagContext'
import {ImagineServiceProvider} from './context/imagineServiceContext'

import {MaintenanceBanner} from './components/Banner'
import DialogUnsupportedBrowser from './components/DialogUnsupportedBrowser'

import {isProd} from './utilities/serviceBindings'
import Router from './pages/___Routes'
import store from './reducers/appStore'

// ! Library css files.  DO NOT REMOVE
import 'leaflet/dist/leaflet.css'

const App = () => {
  document.title = isProd ? 'Imagine UI v4' : '[Nonprod] Imagine UI v4'

  return (
    <>
      <DialogUnsupportedBrowser />
      <ReduxProvider store={store}>
        <FeatureFlagProvider>
          <ImagineServiceProvider>
            <MaintenanceBanner title="" message="" />
            <Router />
          </ImagineServiceProvider>
        </FeatureFlagProvider>
      </ReduxProvider>
    </>
  )
}

export default App
