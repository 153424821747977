import React, {useContext, useEffect, useState} from 'react'
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'

import {useNavigate} from 'react-router-dom'

import useToastNotification from '../hooks/useToastNotification'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {telemetry} from '../utilities/telemetry'
import useCurrentUserGroups from '../hooks/useCurrentUserGroups'
import {albumListContext} from '../context/albumListContext'

function DialogCreateNewAlbum(props) {
  const {handleClose, items} = props
  const {refreshAlbums} = useContext(albumListContext)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const {
    loading: loadingGroups,
    error: groupLoadingError,
    currentUserGroups,
  } = useCurrentUserGroups()
  const [groups, setGroups] = useState([])
  const navigate = useNavigate()
  const {successNotification, errorNotification} = useToastNotification()
  const {imagineSdk} = useContext(imagineServiceContext)
  const [groupOptions, setGroupOptions] = useState([])

  useEffect(() => {
    if (groupLoadingError) {
      errorNotification('Error loading your groups.')
    }
  }, [groupLoadingError])

  useEffect(() => {
    setGroupOptions(currentUserGroups?.map(g => ({label: g.name, value: g.id})) ?? [])
  }, [loadingGroups, groupLoadingError])

  async function handleSubmit() {
    const albumOptions = {
      title,
      description,
      entitlements: {
        admin: {
          papiGroups: groups.map(g => g.value),
        },
      },
    }
    try {
      const album = await imagineSdk.createAlbum(albumOptions)
      successNotification(`Album "${title}" created`)
      try {
        await refreshAlbums?.()
      } catch (err) {
        const additionalErrorDetails = `There was an error refreshing albums.`
        telemetry.error(err, additionalErrorDetails)
        errorNotification(additionalErrorDetails)
      }

      if (items?.length) {
        try {
          await album.addItems(items)
          successNotification(
            `Item${items.length === 1 ? ' has' : 's have'} been added to "${title}"`
          )
        } catch (err) {
          const additionalErrorDetails = `There was an error adding items to the album.`
          telemetry.error(err, additionalErrorDetails)
          errorNotification(additionalErrorDetails)
        }
      } else {
        navigate(`/album/${album.id}`, {replace: true})
      }
    } catch (e) {
      const additionalErrorDetails = `There was an error creating the album.`
      telemetry.error(e, additionalErrorDetails)
      errorNotification(additionalErrorDetails)
    } finally {
      handleClose()
    }
  }

  return (
    <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create album</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Albums are special groups of items used to help Imagine users organize items as they see
          fit.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          required
          variant="standard"
          onBlur={e => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          fullWidth
          required
          variant="standard"
          onBlur={e => setDescription(e.target.value)}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              setDescription(e.target.value)
              handleSubmit()
            }
          }}
        />
        <Autocomplete
          loading={loadingGroups}
          options={groupOptions}
          value={groups}
          onChange={(_, options) => setGroups(options)}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => <Chip label={option.label} {...getTagProps({index})} />)
          }
          renderInput={params => <TextField {...params} label="Groups" variant="standard" />}
          multiple
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create Album
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogCreateNewAlbum
