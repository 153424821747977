import React, {useEffect, useState} from 'react'
import {DialogContent} from '@mui/material'
import styled from 'styled-components'
import {queries, queryProfile} from '@monsantoit/profile-client'
import {useSelector} from 'react-redux'

import {getBearerToken} from '../utilities/initializeProfile'
import {isProd} from '../utilities/serviceBindings'

import UploaderMissionPackageFileSelector from './UploaderMissionPackageFileSelector'
import UploaderMissionPackageValidator from './UploaderMissionPackageValidator'
import UploaderMissionPackageUploadingPane from './UploaderMissionPackageUploadingPane'
import CardGenericError from './CardGenericError'
import UploaderDialogFooterMissionPackage from './UploaderDialogFooterMissionPackage'
import DialogContentContainer from './styled/DialogContentContainer'
import {telemetry} from '../utilities/telemetry'

const FormContainer = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
`

let uploader

async function initializeUavUploader(event) {
  const {currentUser} = queries

  const {
    getCurrentUser: {id: username},
  } = await queryProfile(currentUser())

  const source = await fetch(
    'https://unpkg.loc360.monsanto.net/@bayer-int/uav-mission-uploader@4',
    {
      headers: {
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    }
  ).then(r => r.blob())

  const {MissionUploader} = await import(/* webpackIgnore: true */ URL.createObjectURL(source))

  uploader = new MissionUploader({
    files: event.target.files,
    environment: isProd ? 'prod' : 'nonprod',
    token: getBearerToken,
    username,
  })
}

/*
 * Uploader used for uav mission package uploader
 *
 * Selects the stage of the uploader workflow dialog based on the imagineUploaderReduer
 */
function UploaderMissionPackage() {
  const workflowStage = useSelector(state => state.imageUploader.uploadWorkflowStage)

  const [criticalError, setCriticalError] = useState(null)

  useEffect(() => {
    if (criticalError)
      telemetry.error(
        new Error(`Unable to upload or process mission package`, {
          cause: criticalError,
        })
      )
  }, [criticalError])

  return (
    <>
      <DialogContentContainer>
        <DialogContent>
          <FormContainer>
            {!criticalError ? (
              <>
                {workflowStage === 0 && (
                  <UploaderMissionPackageFileSelector
                    initializeUploader={initializeUavUploader}
                    setCriticalError={setCriticalError}
                  />
                )}
                {workflowStage === 1 && (
                  <UploaderMissionPackageValidator
                    uploader={uploader}
                    setCriticalError={setCriticalError}
                  />
                )}
                {workflowStage === 2 && <UploaderMissionPackageUploadingPane uploader={uploader} />}
              </>
            ) : (
              <CardGenericError
                title="Unable to process mission package"
                description="An error prevented the mission package from being uploaded or processed"
                caption="If you require assistance, please contact Location360 Support"
                fullErrorTrace={criticalError}
              />
            )}
          </FormContainer>
        </DialogContent>
      </DialogContentContainer>
      <UploaderDialogFooterMissionPackage />
    </>
  )
}

export default UploaderMissionPackage
