import React, {useContext} from 'react'
import {imagineServiceContext} from '../context/imagineServiceContext'
import LoadingSpinner from './LoadingSpinner'
import CardGenericError from './CardGenericError'
import DialogContentContainer from './styled/DialogContentContainer'
import {FormikHelpers} from 'formik'
import {CreateProjectFields, StyledDialog} from './SelfService'
import {ProjectFormFormik} from './ProjectFormFormik'

export function ProjectFormDialog({
  open,
  onClose,
  onSubmit,
  initialValues,
}: {
  open: boolean
  onClose: () => void
  onSubmit: (
    values: CreateProjectFields,
    formikHelpers: FormikHelpers<CreateProjectFields>
  ) => void | Promise<any>
  initialValues?: CreateProjectFields
}) {
  const {loading, error} = useContext(imagineServiceContext)

  if (!open) {
    return <></>
  }

  if (loading) {
    return <LoadingSpinner message="Please wait. Connecting to Imagine service" />
  }

  if (error) {
    return (
      <CardGenericError
        title="Unable to upload data"
        description="The Imagine UI is unable to upload data without a connection to the Imagine service"
      />
    )
  }

  return (
    <DialogContentContainer>
      <StyledDialog
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        className="upload-dialog"
        maxWidth="md"
      >
        <ProjectFormFormik onClose={onClose} initialValues={initialValues} onSubmit={onSubmit} />
      </StyledDialog>
    </DialogContentContainer>
  )
}
