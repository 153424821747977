import React, {useState} from 'react'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

import DialogCreateNewAlbum from './DialogCreateNewAlbum'

/** Floating button on the homepage that triggers the creation of new Albums */
function ButtonCreateNewAlbum() {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        sx={{marginRight: '15px'}}
        onClick={() => setDialogOpen(true)}
      >
        <AddIcon />
      </Fab>
      {dialogOpen && <DialogCreateNewAlbum handleClose={() => setDialogOpen(false)} />}
    </>
  )
}

export default ButtonCreateNewAlbum
