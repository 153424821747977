import {applyMiddleware, combineReducers, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducers from './appReducers'

const middlewares = [thunkMiddleware]

if (process.env.NODE_ENV === `development`) {
  const {logger} = require(`redux-logger`)
  middlewares.push(logger)
}

const store = createStore(combineReducers(reducers), applyMiddleware(...middlewares))

export type StoreState = ReturnType<typeof store.getState>

export default store
