import React from 'react'
import styled from 'styled-components'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import {Typography, Paper, FormGroup, FormControlLabel, Switch} from '@mui/material'

const SearchTextContainer = styled(Paper)`
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .icon {
    display: flex;

    color: var(--mdc-theme-primary);
    margin-right: 5px;
    justify-self: center;
    align-self: center;
  }

  .formHistogramToggle {
    padding-right: 12px;
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
  }

  .MuiSwitch-root {
    margin: 0 8px;
  }

  div:last-child {
    font-style: italic;
    color: var(--mdc-theme-primary);
  }
`

function GallerySummarySearchResult({searchText, showHistogram, onHistogramToggle}) {
  const isAdvancedSearch = /\/advanced-search/.test(location.pathname)

  return (
    <SearchTextContainer elevation={0}>
      <div className="icon">
        <CollectionsBookmarkIcon color="primary" />
      </div>
      {!isAdvancedSearch && (
        <FormGroup className="formHistogramToggle">
          <FormControlLabel
            control={<Switch checked={showHistogram} size="small" onChange={onHistogramToggle} />}
            label={<Typography variant="body2">Enable Search Results Summary</Typography>}
            labelPlacement="start"
            disableTypography
          />
        </FormGroup>
      )}
      <div>
        <Typography id="search-text" variant="body2">
          {searchText}
        </Typography>
      </div>
    </SearchTextContainer>
  )
}

export default GallerySummarySearchResult
