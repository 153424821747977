import React, {useContext} from 'react'
import {
  Select,
  InputLabel,
  MenuItem,
  Button,
  DialogTitle,
  IconButton,
  Switch,
  FormControlLabel,
} from '@mui/material'
import {DeleteForever as DeleteForeverIcon} from '@mui/icons-material/'
import {imagineServiceContext} from '../context/imagineServiceContext'
import {FieldArray, useFormikContext} from 'formik'
import {
  CreateExtensionFields,
  TitleSection,
  Container,
  StyledTextField,
  CollectionAttributesTable,
  CollectionAttributesItem,
  StyledFormControl,
  StyledTableTextField,
  DialogFooter,
} from './SelfService'
import {BorderedSection} from './BorderedSection'
import {FormikErrorMessage} from './FormikErrorMessage'
import {initialCollectionAttribute} from './ExtensionFormDialog'

export function ExtensionForm({onClose}: {onClose: () => void}) {
  const {error, loading} = useContext(imagineServiceContext)
  const {
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    touched,
    handleSubmit,
    initialValues,
  } = useFormikContext<CreateExtensionFields>()

  return (
    <form role="form" onSubmit={handleSubmit}>
      <TitleSection>
        <DialogTitle>Create Extension</DialogTitle>
      </TitleSection>
      <Container>
        <Container>
          <StyledTextField
            label="Title"
            value={values.title}
            required
            name="title"
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
          <FormikErrorMessage name="title" />
          <StyledTextField
            label="Description"
            value={values.description}
            required
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
          <FormikErrorMessage name="description" />
          <div style={{display: 'flex', justifyContent: 'stretch'}}>
            <div>
              <StyledTextField
                style={{width: '75px', marginRight: '5px'}}
                label="Major"
                value={values.major}
                required
                name="major"
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
              />
            </div>
            <div>
              <StyledTextField
                style={{width: '75px', marginRight: '5px'}}
                label="Minor"
                value={values.minor}
                required
                name="minor"
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
              />
            </div>
            <div>
              <StyledTextField
                style={{width: '75px'}}
                label="Patch"
                value={values.patch}
                required
                name="patch"
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
              />
            </div>
          </div>
          <FormikErrorMessage name="major" />
          <FormikErrorMessage name="minor" />
          <FormikErrorMessage name="patch" />
          {initialValues.title &&
            initialValues.major === values.major &&
            initialValues.minor === values.minor &&
            initialValues.patch === values.patch && (
              <p style={{color: 'rgb(211, 47, 47)'}}>Must increment extension version</p>
            )}
          <StyledTextField
            label="Extension Prefix"
            helperText='Asset properties are prefixed by this value. For example, the public projection extension has prefix "proj" and one property name is "proj:epsg".'
            value={values.prefix}
            required
            name="prefix"
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            disabled={!!initialValues?.prefix}
          />
          <FormikErrorMessage name="prefix" />
          <BorderedSection title="Collection Properties" style={{marginTop: '20px'}}>
            <div
              style={{
                padding: '5px',
                margin: 0,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <FieldArray
                name="collectionProperties"
                render={({push, remove}) => (
                  <>
                    <CollectionAttributesTable>
                      {values.collectionProperties.map(
                        ({fieldName, fieldType, fieldRequired}, i) => (
                          <div key={i}>
                            <CollectionAttributesItem>
                              <StyledFormControl style={{width: '250px'}}>
                                <StyledTableTextField
                                  label="Field Name"
                                  variant="outlined"
                                  name={`collectionProperties.${i}.fieldName`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={fieldName}
                                  required
                                />
                                <FormikErrorMessage name={`collectionProperties.${i}.fieldName`} />
                              </StyledFormControl>
                              <StyledFormControl style={{width: '150px', marginRight: '8px'}}>
                                <InputLabel>Type *</InputLabel>
                                <Select
                                  value={fieldType}
                                  label="Type"
                                  name={`collectionProperties.${i}.fieldType`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  sx={{fontSize: '14px'}}
                                >
                                  <MenuItem value="string">String</MenuItem>
                                  <MenuItem value="integer">Integer</MenuItem>
                                  <MenuItem value="number">Number</MenuItem>
                                  <MenuItem value="boolean">Boolean</MenuItem>
                                </Select>
                                <FormikErrorMessage name={`collectionProperties.${i}.fieldType`} />
                              </StyledFormControl>
                              <StyledFormControl style={{width: '150px'}}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      sx={{fontSize: '14px'}}
                                      checked={fieldRequired}
                                      value={fieldRequired}
                                      name={`collectionProperties.${i}.fieldRequired`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  }
                                  label="Required"
                                />
                                <FormikErrorMessage
                                  name={`collectionProperties.${i}.fieldRequired`}
                                />
                              </StyledFormControl>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  alignItems: 'center',
                                  height: '53.13px',
                                  flexGrow: 1,
                                }}
                              >
                                <IconButton
                                  style={{height: '34px'}}
                                  onClick={() => remove(i)}
                                  size="small"
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </div>
                            </CollectionAttributesItem>
                          </div>
                        )
                      )}
                    </CollectionAttributesTable>
                    <Button
                      style={{marginTop: values.collectionProperties?.length ? '8px' : '-12px'}}
                      onClick={() => push(initialCollectionAttribute)}
                    >
                      Add Property
                    </Button>
                  </>
                )}
              />
            </div>
          </BorderedSection>
        </Container>

        <DialogFooter>
          <Button size="large" onClick={onClose}>
            Close
          </Button>
          <Button
            type="submit"
            disabled={
              isSubmitting || loading || !!error || !isValid || !Object.keys(touched).length
            }
            sx={{marginLeft: '10px'}}
            variant="contained"
            size="large"
          >
            Submit
          </Button>
        </DialogFooter>
      </Container>
    </form>
  )
}
