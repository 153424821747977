import React from 'react'
import {Typography} from '@mui/material'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import styled from 'styled-components'

const DropArea = styled('section')`
  align-items: center;
  background-color: #fff;
  border: dashed;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  font-size: 20px;
  justify-content: center;
  position: relative;
  user-select: none;
  width: 100%;

  transition: border 0.35s ease-in-out;
  &:hover {
    border-color: #888;
  }

  input {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
`

const DropInputLabel = styled('label')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`

function DirectorySelector({
  onChange,
  fileRef,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement>
  fileRef: any
}) {
  return (
    <DropArea>
      <input
        type="file"
        name="file"
        webkitdirectory="true"
        multiple
        onChange={onChange}
        ref={fileRef}
      />
      <DropInputLabel htmlFor="file">
        <CreateNewFolderIcon />
        <Typography style={{cursor: 'pointer'}}>
          Click to select mission package directory
        </Typography>
      </DropInputLabel>
    </DropArea>
  )
}

export default DirectorySelector
