import React, {createContext, useContext, useState} from 'react'

export const initialToastState = {open: false, severity: 'info', timeout: null, message: ''}

const initialStateContext: ToastStateContext = {
  toastState: initialToastState,
}

const initialContext: ToastContext = {
  setToastState: () => console.warn('Toast Notification not initialized'),
}

const toastNotificationStateContext = createContext<ToastStateContext>(initialStateContext)

const toastNotificationContext = createContext<ToastContext>(initialContext)

export type ToastState = {
  open: boolean
  severity: string
  timeout: number | null
  message: string
}

export type ToastStateContext = {
  toastState: ToastState
}

export type ToastContext = {
  setToastState: React.Dispatch<React.SetStateAction<ToastState>>
}

function useToastNotificationStateContext() {
  const context = useContext(toastNotificationStateContext)

  if (context === undefined) {
    throw new Error('Toast notification state context used outside provider.')
  }

  return context
}

export {toastNotificationContext, toastNotificationStateContext}
