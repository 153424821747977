import React from 'react'
import {Formik, FormikHelpers} from 'formik'
import type {CreateStacExtensionOptions} from '@bayer-int/imagine-sdk-browser'
import {CreateCollectionFields} from './SelfService'
import {CollectionForm} from './CollectionForm'

export function CollectionFormFormik({
  onClose,
  recentExtensions,
  initialValues,
  onSubmit,
}: {
  onClose: () => void
  recentExtensions: CreateStacExtensionOptions[]
  initialValues?: CreateCollectionFields
  onSubmit: (
    values: CreateCollectionFields,
    formikHelpers: FormikHelpers<CreateCollectionFields>
  ) => void | Promise<any>
}) {
  return (
    <Formik
      initialValues={
        initialValues ?? {
          name: '',
          description: '',
          appEntitlements: {
            admin: [],
            delete: [],
            publish: [],
            download: [],
          },
          admin: [],
          delete: [],
          publish: [],
          download: [],
          extensions: [],
          keywords: [],
          license: '',
          providers: [],
        }
      }
      validate={(values: CreateCollectionFields) => {
        const errors = {}

        if (!values.name) {
          errors['name'] = 'Name is required'
        }
        if (!values.description) {
          errors['description'] = 'Description is required'
        }
        if (!values.admin?.length) {
          errors['admin'] = 'Admin is required.'
        }
        if (values.providers) {
          values.providers.forEach((attr, i) => {
            const attributeErrors = {}

            if (!attr.name) {
              attributeErrors['name'] = 'Name is required'
            }

            if (Object.keys(attributeErrors).length) {
              if (!errors['providers']) {
                errors['providers'] = {}
              }
              errors['providers'][i] = attributeErrors
            }
          })
        }

        return errors
      }}
      onSubmit={onSubmit}
      component={() => <CollectionForm onClose={onClose} recentExtensions={recentExtensions} />}
    />
  )
}
