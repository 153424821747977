import {telemetry} from './telemetry'

export function setupCache() {
  // readIndexedDbRow('orthomosaic-uploader', '1725473264415/c41078a1.tif/15777806/dprgdy9jxdzz4z0d')
  // clearIndexedDbRow('orthomosaic-uploader', '1725473264415/c41078a1.tif/15777806/dprgdy9jxdzz4z0d')
  // clearIndexedDbTable('experimental-image-uploader')
  // clearIndexedDbTable('imagine-sdk')
  // clearIndexedDbTable('orthomosaic-uploader')
  // clearIndexedDbTable('s3-multipart-uploader', 'uploadParts')
  // clearIndexedDbTable('s3-multipart-uploader', 'multipartUploads')
  // clearIndexedDbTable('uav-mission-uploader')
}

function readIndexedDbRow(database: string, key: string, table?: string, version?: number) {
  const _table = table ?? 'history'
  const conn = window.indexedDB.open(database, version)
  conn.onsuccess = () => {
    const db = conn.result

    const transaction = db.transaction(_table, 'readwrite')
    transaction.onerror = () => {
      telemetry.error(transaction.error)
    }

    const store = transaction.objectStore(_table)
    const readRequest = store.get(key)
    readRequest.onsuccess = () => {
      console.log(
        `cache read for table ${database} key ${key}: ${JSON.stringify(readRequest.result)}`
      )
    }
  }

  conn.onerror = () => {
    telemetry.error(conn.error)
  }
}

export function clearIndexedDbTable(database: string, table?: string, version?: number) {
  const _table = table ?? 'history'
  const conn = window.indexedDB.open(database, version)
  conn.onsuccess = () => {
    const db = conn.result

    const transaction = db.transaction(_table, 'readwrite')
    transaction.onerror = () => {
      console.error(transaction.error)
    }

    const store = transaction.objectStore(_table)

    const clearRequest = store.clear()
    clearRequest.onsuccess = () => {
      console.log(`cleared table ${_table} in database ${database}`)
    }

    clearRequest.onerror = () => {
      telemetry.error(clearRequest.error)
    }
  }

  conn.onerror = () => {
    telemetry.error(conn.error)
  }
}

function clearIndexedDbRow(database: string, key: string, table?: string, version?: number) {
  const _table = table ?? 'history'
  const conn = window.indexedDB.open(database, version)
  conn.onsuccess = () => {
    const db = conn.result

    const transaction = db.transaction(_table, 'readwrite')
    transaction.onerror = () => {
      telemetry.error(transaction.error)
    }

    const store = transaction.objectStore(_table)

    const deleteRequest = store.delete(key)
    deleteRequest.onsuccess = () => {
      console.log(`deleted record ${key} in table ${_table} in database ${database}`)
    }

    deleteRequest.onerror = () => {
      console.error(deleteRequest.error)
    }
  }

  conn.onerror = () => {
    telemetry.error(conn.error)
  }
}
