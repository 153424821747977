import * as Sentry from '@sentry/react'
import * as SentryBrowser from '@sentry/browser'
import {ExtraErrorData} from '@sentry/integrations'
import {BrowserTracing} from '@sentry/tracing'
import {getEnvironment, isLocal} from './serviceBindings'

export function setupTelemetry() {
  SentryBrowser.init({
    dsn: 'https://5cada471664441a78c3a1ef406471dcb@o1128229.ingest.sentry.io/4503976835612672',
    integrations: [new BrowserTracing(), new ExtraErrorData()],
    environment: getEnvironment(),
    tracesSampleRate: 1.0,
  })
}

export type TelemetryOptions = {
  telemetry?: ITelemetry
  level?: 'error' | 'warning' | 'info' | 'debug'
}

class SentryTelemetry {
  debug(msg: string) {
    Sentry.captureMessage(msg, {
      level: 'debug',
    })
  }

  info(msg: string) {
    Sentry.captureMessage(msg, {level: 'info'})
  }

  warn(msg: string) {
    Sentry.captureMessage(msg, {level: 'warning'})
  }

  error(err: Error) {
    Sentry.captureException(err)
  }
}

type ITelemetry = {
  debug(msg: string): void
  info(msg: string): void
  warn(msg: string): void
  error(err: Error): void
}

class Telemetry {
  telemetry: ITelemetry
  options: TelemetryOptions

  constructor(options?: TelemetryOptions) {
    this.options = {level: 'info', ...(options ?? {})}
  }

  configure(options: Partial<TelemetryOptions>) {
    this.options = {...this.options, ...options}
  }

  debug(msg: any) {
    console.debug(msg)
    if (isLocal) {
      return
    }
    try {
      const text = typeof msg === 'string' ? msg : JSON.stringify(msg, null, 2)
      this.telemetry.debug(text)
    } catch (err) {
      console.error(err)
    }
  }

  info(msg: string) {
    console.info(msg)
    if (isLocal) {
      return
    }
    try {
      this.telemetry.info(msg)
    } catch (err) {
      console.error(err)
    }
  }

  warn(msg: string) {
    console.warn(msg)
    if (isLocal) {
      return
    }
    try {
      this.telemetry.warn(msg)
    } catch (err) {
      console.error(err)
    }
  }

  error(err: Error, details?: string) {
    const combinedError = details ? new Error(details, {cause: err}) : err
    console.error(combinedError)
    if (isLocal) {
      return
    }
    try {
      this.telemetry.error(combinedError)
    } catch (err) {
      console.error(err)
    }
  }
}

export const telemetry = new Telemetry({
  telemetry: new SentryTelemetry(),
})
