const {fetch: originalFetch} = window

class FetchInterceptor {
  async fetch(input: RequestInfo | URL, init?: RequestInit) {
    if (!init) {
      init = {}
    }

    const collectionPathRe = /.+\/collection/
    const projectPathRe = /.+\/project/
    const collectionsPathRe = /.+\/collections/
    const projectsPathRe = /.+\/projects/
    if (typeof input === 'string') {
      const noCachePath =
        projectsPathRe.test(input) ||
        collectionsPathRe.test(input) ||
        projectPathRe.test(input) ||
        collectionPathRe.test(input)

      if (noCachePath) {
        if (!init.headers) {
          init.headers = {}
        }
        init.headers['Cache-Control'] = 'no-cache'
      }
    }

    return originalFetch(input, init)
  }
}

export const interceptor = new FetchInterceptor()

export function setupInterceptor() {
  window.fetch = interceptor.fetch
}
