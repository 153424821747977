import React, {CSSProperties} from 'react'
import {Autocomplete, Chip} from '@mui/material'
import {useFormikContext} from 'formik'
import {CreateProjectFields, StyledTextField} from './SelfService'
import {FormikErrorMessage} from './FormikErrorMessage'

export function RegionSelect({style}: {style?: CSSProperties}) {
  const {values, handleBlur, setFieldValue} = useFormikContext<CreateProjectFields>()
  const regions = ['default', 'ap-south-1', 'eu-central-1', 'sa-east-1', 'us-east-1']

  return (
    <div style={style}>
      <Autocomplete
        disableClearable
        options={regions}
        value={values.region}
        renderInput={params => (
          <StyledTextField
            {...params}
            name="region"
            label="Default Region *"
            style={{marginTop: 0}}
          />
        )}
        onBlur={handleBlur}
        onChange={(_, value) => setFieldValue('region', value)}
        getOptionDisabled={option => values.region === option}
      />
      <FormikErrorMessage name="region" />
    </div>
  )
}
