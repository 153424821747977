import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import GallerySummaryAdvancedSearch from '../components/GallerySummaryAdvancedSearch'
import GalleryViewGrid from '../components/GalleryViewGrid'
import GalleryViewErrorCard from '../components/GalleryViewErrorCard'
import LoadingSpinner from '../components/LoadingSpinner'

import useGalleryPageState from '../hooks/useGalleryPageState'

import CommonLayout from '../layouts/CommonLayout'
import GalleryPageLayout from '../layouts/GalleryPageLayout'
import {GalleryPageProvider} from '../context/galleryPageContext'
import {imagineServiceContext} from '../context/imagineServiceContext'

import {decodeAdvancedSearchQuery} from '../utilities/advancedSearchQueryString'
import mapSearchParametersToFetchItemsOptions from '../utilities/advancedSearchParameters'
import {AlbumListProvider} from '../context/albumListContext'
import {telemetry} from '../utilities/telemetry'
import useToastNotification from '../hooks/useToastNotification'

const GalleryPageAdvancedSearch = () => {
  const {
    loading: globalLoading,
    error: globalLoadingError,
    imagineSdk,
  } = useContext(imagineServiceContext)
  const {errorNotification} = useToastNotification()
  const [redirectHome, setRedirectHome] = useState(false)
  const [itemListing, setItemListing] = useState(null)
  const navigate = useNavigate()
  const {
    error,
    items,
    initialLoading,
    loading,
    numberResultingItems,
    resetSearch,
    removeItem,
    setServerError,
    setNumberItemsPossible,
    next,
    hasMore,
  } = useGalleryPageState(itemListing)

  const searchParams = new URLSearchParams(useLocation().search)
  searchParams.delete('page')
  const search = searchParams.toString()

  async function getItemListingAndCount() {
    const searchTerms = decodeAdvancedSearchQuery(search)
    if (Object.keys(searchTerms).length === 0) {
      setRedirectHome(true)
      return
    }

    const sdkCompatibleSearchTerms = mapSearchParametersToFetchItemsOptions(searchTerms)

    try {
      const itemListing = imagineSdk.fetchItems({
        ...sdkCompatibleSearchTerms,
        limit: Infinity,
      })
      setItemListing(itemListing)
      const itemCount = await itemListing.count()
      setNumberItemsPossible(itemCount)
    } catch (e) {
      const additionalErrorDetails = `Error in attempting to retrieve search results with the following searchTerms: ${searchTerms}`
      telemetry.error(e, additionalErrorDetails)
      setServerError()
    }
  }

  useEffect(() => {
    if (!loading) resetSearch()
    if (imagineSdk)
      getItemListingAndCount().catch(err => {
        const details = 'Error loading advanced search items'
        telemetry.error(err, details)
        errorNotification(details)
      })
  }, [search, imagineSdk])

  useEffect(() => {
    if (itemListing && next) next('down')
  }, [itemListing])

  useEffect(() => {
    if (globalLoadingError) {
      telemetry.error(globalLoadingError)
      setServerError()
    }
  }, [globalLoadingError])

  if (redirectHome) {
    navigate('/?error-notification=no-valid-search-terms', {replace: true})
  }

  if (error) {
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <GalleryViewErrorCard {...error} />
        </GalleryPageLayout>
      </CommonLayout>
    )
  }

  if (globalLoading || initialLoading) {
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <LoadingSpinner message="Retrieving results..." />
        </GalleryPageLayout>
      </CommonLayout>
    )
  }

  const summary = <GallerySummaryAdvancedSearch numberItems={numberResultingItems} />

  return (
    <CommonLayout>
      <AlbumListProvider>
        <GalleryPageProvider
          type="search"
          loading={loading}
          items={items}
          removeItem={removeItem}
          next={next}
          hasMore={hasMore}
        >
          <GalleryPageLayout summaryComponent={summary}>
            <GalleryViewGrid />
          </GalleryPageLayout>
        </GalleryPageProvider>
      </AlbumListProvider>
    </CommonLayout>
  )
}

export default GalleryPageAdvancedSearch
