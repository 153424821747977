import React, {createContext, useEffect, useState} from 'react'
import formatBytes from 'pretty-bytes'
import {useLocation} from 'react-router-dom'
import {telemetry} from '../utilities/telemetry'
import getItemThumbnailUrl from '../utilities/getItemThumbnailUrl'

/**
 * The following is the list of expected parameters of this state
 *
 * These values are set only once after data loading in the ItemPage page.
 */
const initialState = {
  item: null,
  assets: null,
  title: '',
  subtitle: '',
  projectName: '',
  entitlements: null,
  thumbnail: null,
  collectionTitle: '',
}

const galleryItemContext = createContext(initialState)
const {Provider} = galleryItemContext

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})

function assembleSubtitle(dataAsset, itemDate) {
  const subtitle = itemDate ? [dateFormatter.format(new Date(itemDate))] : []
  if (itemDate && dataAsset?.size) {
    subtitle.push(' | ')
    subtitle.push(formatBytes(dataAsset.size))
  } else if (dataAsset?.size) {
    subtitle.push(formatBytes(dataAsset.size))
  }

  return subtitle
}

/**
 * Breaks up all content in a way that eliminates the need for async in the child components
 * */
function GalleryItemProvider({item, children}) {
  const [itemMetadata, setItemMetadata] = useState(initialState)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const hrefURL = new URL(window.location.href)
  const hrefParams = new URLSearchParams(hrefURL)
  hrefParams.set('id', item.id)
  hrefParams.set('parent', location.pathname)

  /**
   * Loads data for the following components:
   *  * GalleryViewGridItem
   *  * CardGalleryViewItemMultipleSelect
   *  * CardGalleryViewItem
   *  * DialogDeleteItemGalleryPage
   *  * DialogAssetDownload
   */
  useEffect(() => {
    let componentIsMounted = true

    async function getAssets(item) {
      try {
        return await item.fetchAssets()
      } catch (e) {
        const errMsg = `Error fetching assets for item "${item.id}"`
        telemetry.error(e, errMsg)
      }
      return null
    }

    async function getCollection(item) {
      try {
        return await item.collection()
      } catch (e) {
        const errMsg = `Error getting collection for item "${item.id}"`
        telemetry.error(e, errMsg)
      }
      return null
    }

    async function getEntitlements(item) {
      try {
        return await item.entitlements()
      } catch (e) {
        const errMsg = `Error getting entitlements for item "${item.id}"`
        telemetry.error(e, errMsg)
      }
      return null
    }

    if (item) {
      Promise.all([
        getAssets(item),
        getEntitlements(item),
        getCollection(item),
        getItemThumbnailUrl(item),
      ])
        .then(response => {
          const [assetsRes, entitlements, collection, thumbnail] = response
          const assets = assetsRes ?? []

          let dataAsset
          for (const asset of assets) {
            if (asset.roles.includes('data')) {
              dataAsset = asset
              break
            }
          }
          const title = dataAsset?.title ?? item.id
          const subtitle = assembleSubtitle(dataAsset, item.properties.datetime)
          const collectionName = collection?.id ?? 'N/A'

          const userEntitlements = []
          if (entitlements?.currentUserHasEntitlement('ADMIN')) userEntitlements.push('ADMIN')
          if (entitlements?.currentUserHasEntitlement('DELETE')) userEntitlements.push('DELETE')
          if (entitlements?.currentUserHasEntitlement('PUBLISH')) userEntitlements.push('PUBLISH')
          if (entitlements?.currentUserHasEntitlement('DOWNLOAD')) userEntitlements.push('DOWNLOAD')

          if (componentIsMounted) {
            setItemMetadata({
              item,
              href: `/item?${hrefParams}`,
              assets,
              title,
              properties: item.properties,
              subtitle,
              projectName: item.properties['project:name'],
              entitlements: userEntitlements,
              canDelete: userEntitlements?.indexOf('DELETE') !== -1,
              canDownload: userEntitlements?.indexOf('DOWNLOAD') !== -1,
              thumbnail,
              collectionName,
            })
            setLoading(false)
          }
        })
        .catch(e => {
          const additionalErrorDetails = 'There was an error retrieving data for this item.'
          telemetry.error(e, additionalErrorDetails)
        })
    }

    return () => {
      componentIsMounted = false
    }
  }, [item])

  return <Provider value={{loading, ...itemMetadata}}>{children}</Provider>
}

export {galleryItemContext, GalleryItemProvider}
