import React, {CSSProperties} from 'react'
import {ErrorMessage} from 'formik'

export function ErrorMessageInternal({
  errorMessage,
  style,
}: {
  errorMessage: string
  style: CSSProperties
}) {
  return <div style={{color: '#d32f2f', ...style}}>{errorMessage}</div>
}

export function FormikErrorMessage({
  name,
  style,
}: {
  name: string
  style?: CSSProperties | undefined
}) {
  return (
    <ErrorMessage
      render={(errorMessage: string) => (
        <ErrorMessageInternal errorMessage={errorMessage} style={style ?? {}} />
      )}
      name={name}
    />
  )
}
