import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {Card, Typography} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'

import {
  setAvailableWorkflowStages,
  setMissionPackageDiscoveryMode,
  setUploaderWorkflowStage,
} from '../actions/imageUploaderAction'

import LoadingSpinner from './LoadingSpinner'
import UploaderMissionPackageMetadataForm from './UploaderMissionPackageMetadataForm'
import UploaderMissionPackageDialogConfirmDiscovery from './UploaderMissionPackageDialogConfirmDiscovery'
import {telemetry} from '../utilities/telemetry'

const ValidatorSection = styled.section`
  max-height: 530px;
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  overflow-x: hidden;
`

const MessageCardContent = styled('div')`
  padding: 10px;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 15px;
  cursor: default;

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .material-icons {
    font-size: 22px;
  }
  .mdc-typography--body2 {
    line-height: 22px;
  }
`

const CardContainer = styled('div')`
  width: 850px;
  margin: 10px auto 20px auto;
  padding-bottom: 8px;
`

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

/*
 *
 * Validates mission package in reducer on load. Determines one of three scenarios:
 *   1) Incurable defects (Discovery Mode)
 *   2) Curable defects
 *   3) A-OK
 *
 *   Returns loader, error screen, and message describing next step as well as a child metadata form component
 *
 */
function UploaderMissionPackageValidator(props) {
  const {setCriticalError, uploader} = props

  const [validating, setValidating] = useState(true)
  const dispatch = useDispatch()

  const {missionPackageDiscoveryConfirmDialog} = useSelector(state => state.imageUploader)

  // Trigger initial validation of uploader
  useEffect(() => {
    async function retrieveUploader() {
      await uploader.analyze().catch(e => {
        const additionalErrorDetails =
          "An unknown error occured in UploaderMissionPackageValidator's retrieveUploader function."
        telemetry.error(e, additionalErrorDetails)
        setCriticalError(e)
      })

      // Jump straight to uploading for suspended sets.
      const uniqueFormValues = new Set(uploader.metadata.form.map(i => i.visible))
      if (uniqueFormValues.size === 1 && uniqueFormValues.has(false)) {
        dispatch(setAvailableWorkflowStages([2]))
        dispatch(setUploaderWorkflowStage(2))
      }

      setValidating(false)
    }

    if (uploader) {
      retrieveUploader().catch(err => telemetry.error(err))
    }
  }, [uploader])

  // Allows movement to the next stage if there are no defects
  useEffect(() => {
    if (validating || !uploader?.defects) return

    if (uploader.defects.curable.length === 0 && uploader.defects.incurable.length === 0) {
      dispatch(setAvailableWorkflowStages([1, 2]))
    }
  }, [uploader, validating])

  // Make clear to other components that we are in discovery mode
  useEffect(() => {
    if (!validating && uploader?.defects?.incurable?.length) {
      dispatch(setMissionPackageDiscoveryMode())
    }
  }, [uploader, validating])

  if (validating)
    return (
      <LoadingContainer>
        <LoadingSpinner message="Validating Mission Package" dialogMode />
      </LoadingContainer>
    )

  const validate = () => {
    uploader.analyze().catch(e => {
      const additionalErrorDetails =
        "An unknown error occured in UploaderMissionPackageValidator's validate function."
      telemetry.error(e, additionalErrorDetails)
      setCriticalError(e)
    })

    if (
      (uploader.defects.curable.length === 0 && uploader.defects.incurable.length === 0) ||
      (uploader.defects.incurable.length > 0 &&
        uploader.metadata.form
          .filter(i => i.visible)
          .map(i => i.error)
          .filter(i => i).length === 0)
    ) {
      dispatch(setAvailableWorkflowStages([1, 2]))
    }
  }

  const DirectionsCard = () => {
    if (uploader?.defects?.incurable?.length) {
      return (
        <CardContainer>
          <Card raised>
            <MessageCardContent>
              <div className="icon-container">
                <ErrorIcon color="error" />
              </div>
              <div>
                <Typography use="body1">
                  <b>
                    There are critical errors within this mission package that will not allow it to
                    be processed.&nbsp;
                  </b>
                </Typography>
                <Typography use="body2">
                  You may continue this upload, but no mosaics or analytical products will be
                  created.
                  {uploader.defects.incurable.length > 0 && (
                    <ul style={{marginTop: '10px', marginBottom: '5px'}}>
                      {uploader.defects.incurable.map(text => (
                        <li key={`incurable-defect-${text}`}>{text}</li>
                      ))}
                    </ul>
                  )}
                </Typography>
              </div>
            </MessageCardContent>
          </Card>
        </CardContainer>
      )
    }

    if (uploader?.defects?.severe?.length > 0) {
      return (
        <CardContainer>
          <Card raised>
            <MessageCardContent>
              <div className="icon-container">
                <WarningIcon />
              </div>
              <div>
                <Typography use="body1">
                  <b>
                    There are serious errors within this mission package that give it a high
                    probability of failure in processing.&nbsp;
                  </b>
                </Typography>
                <Typography use="body2">
                  You may continue this upload, but it is likely that any attempt to create mosaics
                  or analytical products from this mission will fail.
                  <ul style={{marginTop: '10px', marginBottom: '5px'}}>
                    {uploader.defects.severe.map(text => (
                      <li key={`incurable-defect-${text}`}>{text}</li>
                    ))}
                  </ul>
                </Typography>
              </div>
            </MessageCardContent>
          </Card>
        </CardContainer>
      )
    }
    return (
      <CardContainer>
        <Card>
          <MessageCardContent>
            <div className="icon-container">
              <InfoIcon />
            </div>
            <Typography use="body2">
              Please review and complete the metadata for this mission.
            </Typography>
          </MessageCardContent>
        </Card>
      </CardContainer>
    )
  }

  return (
    <>
      {/* Lives here, which is the last component a user sees at the center of their screen before triggering the dialog */}
      {missionPackageDiscoveryConfirmDialog && <UploaderMissionPackageDialogConfirmDiscovery />}
      <ValidatorSection>
        <DirectionsCard />

        <UploaderMissionPackageMetadataForm
          uploader={uploader}
          metadata={uploader?.metadata}
          defects={
            uploader?.defects?.curable?.length > 0 || uploader?.defects?.incurable?.length > 0
          }
          validate={validate}
        />
      </ValidatorSection>
    </>
  )
}

export default UploaderMissionPackageValidator
