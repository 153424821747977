import React, {useContext, useState} from 'react'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import {
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Tooltip,
} from '@mui/material'
import styled from 'styled-components'

import downloadImagineAsset from '../utilities/downloadImagineAsset'
import {galleryItemContext} from '../context/galleryItemContext'
import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'

const StyledIconButton = styled(IconButton)`
  margin: 2px !important;
  padding: 15px !important;
  width: 1em;
  height: 1em;
  &.Mui-disabled .MuiSvgIcon-colorPrimary {
    color: var(--mdc-theme-text-disabled-on-dark) !important;
  }
`

/**
 * Seeks assets of a given item, provides a dialogue for users to select which asset to download
 *
 */
function DialogAssetDownload() {
  const [open, setOpen] = useState(false)
  const {assets, canDownload, item} = useContext(galleryItemContext)
  const {errorNotification} = useToastNotification()

  async function downloadAsset(asset) {
    try {
      await downloadImagineAsset(asset, item.id)
    } catch (e) {
      telemetry.error(e, 'Error downloading asset')
      errorNotification('Error downloading asset')
    }
    setOpen(false)
  }

  if (!canDownload)
    return (
      <Tooltip title="Insufficient entitlement">
        <span>
          <StyledIconButton aria-label="Download" size="small" edge="start" disabled>
            <CloudDownloadIcon />
          </StyledIconButton>
        </span>
      </Tooltip>
    )

  if (!assets?.map?.size > 0)
    return (
      <Tooltip title="No data asset is present">
        <span>
          <StyledIconButton aria-label="Download" size="small" edge="start" disabled>
            <CloudDownloadIcon />
          </StyledIconButton>
        </span>
      </Tooltip>
    )

  return (
    <>
      <Tooltip title="Download Item Asset">
        <span>
          <StyledIconButton
            aria-label="Download"
            size="small"
            edge="start"
            onClick={() => setOpen(true)}
          >
            <CloudDownloadIcon color="primary" />
          </StyledIconButton>
        </span>
      </Tooltip>
      {open && (
        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogTitle>Select asset</DialogTitle>
          <List>
            {[...assets.map].map(([name, asset], index) => {
              let avatar = name[0].toUpperCase()
              if (/thumb/.test(name)) avatar = 'T'

              return (
                <ListItem onClick={() => downloadAsset(asset)} key={`list-item-${name}`}>
                  <ListItemAvatar>
                    <Avatar
                      index={index}
                      style={{backgroundColor: `hsl(${(180 + 65 * index) % 360},80%, 50%)`}}
                    >
                      {avatar}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={name} />
                </ListItem>
              )
            })}
          </List>
        </Dialog>
      )}
    </>
  )
}

export default DialogAssetDownload
