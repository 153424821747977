import React from 'react'
import {Formik, FormikHelpers} from 'formik'
import {CreateProjectFields} from './SelfService'
import {ProjectForm} from './ProjectForm'

export function ProjectFormFormik({
  onClose,
  initialValues,
  onSubmit,
}: {
  onClose: () => void
  initialValues?: CreateProjectFields
  onSubmit: (
    values: CreateProjectFields,
    formikHelpers: FormikHelpers<CreateProjectFields>
  ) => void | Promise<any>
}) {
  let _initialValues = initialValues ?? {
    title: '',
    appEntitlements: {
      admin: [],
      delete: [],
      publish: [],
      download: [],
    },
    admin: [],
    delete: [],
    publish: [],
    download: [],
    region: 'us-east-1',
  }

  if (_initialValues.region === undefined) {
    _initialValues.region = 'default'
  }

  return (
    <Formik
      initialValues={_initialValues}
      validate={(values: CreateProjectFields) => {
        const errors = {}

        if (!values.title) {
          errors['title'] = 'Title is required'
        }
        if (!values.admin?.length) {
          errors['admin'] = 'Admin is required'
        }
        if (!values.region?.length) {
          errors['region'] = 'Default region is required'
        }

        return errors
      }}
      onSubmit={onSubmit}
      component={() => <ProjectForm onClose={onClose} />}
    />
  )
}
