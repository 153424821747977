import React from 'react'
import {Formik, FormikHelpers} from 'formik'
import {ExtensionFormFormikValidate} from './ExtensionFormFormikValidate'
import {CreateExtensionFields} from './SelfService'
import {ExtensionForm} from './ExtensionForm'

export function ExtensionFormFormik({
  onClose,
  onSubmit,
  initialValues,
  validate,
}: {
  onClose: () => void
  onSubmit: (
    values: CreateExtensionFields,
    formikHelpers: FormikHelpers<CreateExtensionFields>
  ) => void | Promise<any>
  initialValues?: CreateExtensionFields
  validate: ExtensionFormFormikValidate
}) {
  return (
    <Formik
      initialValues={initialValues ?? {}}
      validate={validate}
      onSubmit={onSubmit}
      component={() => <ExtensionForm onClose={onClose} />}
    />
  )
}
