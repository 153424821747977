import React from 'react'
import {Button, Dialog, DialogActions} from '@mui/material'
import styled from 'styled-components'

const Title = styled('h3')`
  padding: 0.3em 1em;
  font-weight: 200;
  font-size: 1.7em;
`

function DialogConfirm({title, onConfirm, onClose}) {
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Title>{title}</Title>
      <DialogActions>
        <Button style={{fontSize: '0.8em'}} onClick={onClose}>
          Cancel
        </Button>
        <Button
          style={{fontSize: '0.8em'}}
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirm
