import uploadCardCrop from '../assets/card-upload-selection-crop.jpg'
import uploadCardExperimental from '../assets/card-upload-selection-experiment.jpg'
import uploadCardUav from '../assets/card-upload-selection-mission-package.jpg'
import uploadCardMosaic from '../assets/terrace-farm.jpeg'

export const data360Location360DevTeam = 'DATA-360-LOCATION-360-DEV-TEAM'
const loc360groups = [data360Location360DevTeam, 'IMAGINEDEVELOPMENTTEAM', 'LOCATION360']
const experimentalGroups = [...loc360groups, 'BREEDINGDISCOVERYIMAGERYUPLOADING']

/**
 * Source of truth for the workflow of uploading assets or
 * mission packages. Delinitates the stages, and provides
 * the description of said stages for both users of the UI
 * as well as developers.
 */

const uploadWorkflows = [
  {
    title: 'Select an upload workflow',
    description: 'Select an upload workflow:',
    stages: [
      {
        title: 'The only portion',
        description: 'This element is a placeholder and is not used',
      },
    ],
  },

  {
    title: 'Imagine Service',
    description: 'For uploading individual items to the Imagine service',
    disabledDescription: '',
    image: uploadCardCrop,
    disabledImage: uploadCardCrop,
    stages: [
      {
        key: 'SET_IMAGE',
        title: 'Select File',
        description: 'Select the image or video you wish to be uploaded',
      },
      {
        key: 'SELECT_PROJECT_COLLECTION',
        title: 'Set Project and Collection',
        description: 'Select project and collection for the image',
      },
      {
        key: 'ENTER_METADATA',
        title: 'Edit and Validate Metadata',
        description: 'Set and verify metadata',
      },
      {title: 'Upload', key: 'UPLOAD'},
      {
        title: 'Upload Result',
        key: 'RESULT',
      },
    ],
  },
  {
    title: 'UAV Mission Package',
    enabled: (isProd, userGroups) =>
      isProd || loc360groups.some(group => userGroups.includes(group)),
    description: 'For uploading UAV mission packages to the Location360 UAV Image Pipeline',
    disabledDescription: 'UAV missions may only be uploaded in Production',
    image: uploadCardUav,
    stages: [
      {
        key: 'SET_DIRECTORY',
        title: 'Select Mission Directory',
        description: 'Set the mission package in directory format',
      },

      {key: 'EDIT_METADATA', description: '', title: 'Edit and Validate Metadata'},
      {key: 'UPLOAD', description: '', title: 'Upload UAV Mission'},
      {
        key: 'ERROR',
        description: 'Displayed when a critical error occurs',
        title: 'Critical Error',
        hideBreadcrumb: true,
      },
    ],
  },
  {
    title: 'Orthomosaic',
    description:
      'For uploading 3rd party vendor-processed orthomosaics for analysis in the Location360 UAV Pipeline',
    disabledDescription: 'Orthomosaics may only be uploaded in Production',
    image: uploadCardMosaic,
    enabled: (isProd, userGroups) =>
      isProd || loc360groups.some(group => userGroups.includes(group)),
    stages: [
      {
        key: 'SET_DIRECTORY',
        title: 'Select File',
        description: 'Set the mosaic you wish to upload',
      },

      {key: 'EDIT_METADATA', description: '', title: 'Edit and Validate Metadata'},
      {key: 'UPLOAD', description: '', title: 'Upload Orthomosaic'},
      {
        key: 'ERROR',
        description: 'Displayed when a critical error occurs',
        title: 'Critical Error',
        hideBreadcrumb: true,
      },
    ],
  },
  {
    title: 'Experimental',
    description: 'For transferring experimental images or other files to development teams',
    disabledDescription: 'Experimental files may only be uploaded in Production',
    image: uploadCardExperimental,
    disabledImage: uploadCardExperimental,
    visible: (_, userGroups) => experimentalGroups.some(group => userGroups.includes(group)),
    enabled: (isProd, userGroups) => {
      if (isProd) {
        return experimentalGroups.some(group => userGroups.includes(group))
      }
      return loc360groups.some(group => userGroups.includes(group))
    },
    stages: [
      {
        key: 'SET_DIRECTORY',
        title: 'Select Directory',
        description: 'Set the files you wish to upload',
      },

      {key: 'EDIT_METADATA', description: '', title: 'Edit and Validate Metadata'},
      {key: 'UPLOAD', description: '', title: 'Upload Experimental Files'},
      {
        key: 'ERROR',
        description: 'Displayed when a critical error occurs',
        title: 'Critical Error',
        hideBreadcrumb: true,
      },
    ],
  },
]

export default uploadWorkflows
