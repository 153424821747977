import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {telemetry} from '../utilities/telemetry'

import GalleryViewErrorCard from '../components/GalleryViewErrorCard'
import LoadingSpinner from '../components/LoadingSpinner'

import {AlbumListProvider} from '../context/albumListContext'
import {GalleryPageProvider} from '../context/galleryPageContext'
import {imagineServiceContext} from '../context/imagineServiceContext'

import useGalleryPageState from '../hooks/useGalleryPageState'
import useUpdatePageVisitCount from '../hooks/useUpdatePageVisitCount'

import CommonLayout from '../layouts/CommonLayout'
import GalleryPageLayout from '../layouts/GalleryPageLayout'
import GalleryViewGrid from '../components/GalleryViewGrid'
import GallerySummaryCollection from '../components/GallerySummaryCollection'
import GalleryVisibleSchemaDialog from '../components/GalleryVisibleSchemaDialog'

import noItemsAvailableCopy from '../helpers/noItemsAvailableCopy'

const GalleryPageCollection = () => {
  const {collection: collectionName} = useParams()

  const [collection, setCollection] = useState(null)
  const [schemaDialog, setSchemaDialog] = useState(false)
  const [itemListing, setItemListing] = useState(null)

  const {
    loading: globalLoading,
    error: globalLoadingError,
    imagineSdk,
  } = useContext(imagineServiceContext)

  const {
    error,
    items,
    initialLoading,
    loading,
    numberResultingItems,
    removeItem,
    setNotFoundError,
    setServerError,
    hasMore,
    next,
    setNumberItemsPossible,
  } = useGalleryPageState(itemListing)

  useUpdatePageVisitCount({
    topic: 'collectionVisitCount',
    pageIdentifier: collectionName,
    valid: collection !== null,
  })

  useEffect(() => {
    async function retrieveCollection() {
      try {
        const retrievedCollection = await imagineSdk.fetchCollection({id: collectionName})

        if (retrievedCollection) {
          setCollection(retrievedCollection)
        } else {
          setNotFoundError(collectionName)
        }
      } catch (e) {
        const additionalErrorDetails = `Error in retrieving collection ${collectionName}.`
        telemetry.error(e, additionalErrorDetails)
        setServerError()
      }
    }

    if (!globalLoading && !globalLoadingError) retrieveCollection()
    if (globalLoadingError) setServerError()
  }, [globalLoading, globalLoadingError])

  useEffect(() => {
    async function getItemListing() {
      try {
        const itemListing = collection.fetchItems({limit: Infinity})
        setItemListing(itemListing)
        const count = await itemListing.count()
        setNumberItemsPossible(count)
      } catch (e) {
        const additionalErrorDetails = `Error in retrieving the itemListing or count for collection ${collection?.id}`
        telemetry.error(e, additionalErrorDetails)
      }
    }
    if (collection) getItemListing()
  }, [collection])

  useEffect(() => {
    if (itemListing && next) next('down')
  }, [itemListing])

  if (error && error.title !== noItemsAvailableCopy) {
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <GalleryViewErrorCard {...error} />
        </GalleryPageLayout>
      </CommonLayout>
    )
  }

  if (globalLoading || initialLoading) {
    return (
      <CommonLayout>
        <GalleryPageLayout>
          <LoadingSpinner message="Loading collection..." />
        </GalleryPageLayout>
      </CommonLayout>
    )
  }

  const summary = (
    <>
      <GallerySummaryCollection
        numberItemsInCollection={numberResultingItems}
        loading={loading}
        name={collectionName}
        collection={collection}
        openSchemaDialog={() => setSchemaDialog(true)}
        description={collection?.description}
      />
      <GalleryVisibleSchemaDialog onClose={() => setSchemaDialog(false)} open={schemaDialog} />
    </>
  )

  return (
    <CommonLayout>
      <AlbumListProvider>
        <GalleryPageProvider
          type="collection"
          collection={collection}
          loading={loading}
          items={items}
          removeItem={removeItem}
          hasMore={hasMore}
          next={next}
        >
          <GalleryPageLayout summaryComponent={summary}>
            <GalleryViewGrid />
          </GalleryPageLayout>
        </GalleryPageProvider>
      </AlbumListProvider>
    </CommonLayout>
  )
}

export default GalleryPageCollection
