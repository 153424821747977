import {getBearerToken} from '../utilities/initializeProfile'

const LOCAL_SDK = false
const LOCAL_SDK_PORT = 9022

export async function fetchOrthoSdk(): Promise<OrthoSDKModule> {
  let res: Response
  if (LOCAL_SDK) {
    res = await fetch(`http://127.0.0.1:${LOCAL_SDK_PORT}/index.js`, {
      cache: 'no-cache',
    })
  } else {
    res = await fetch('https://unpkg.loc360.monsanto.net/@bayer-int/orthomosaic-uploader@3', {
      headers: {
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    })
  }

  const source = await res.blob()

  return import(/* webpackIgnore: true */ URL.createObjectURL(source))
}

type OrthoSDKModule = typeof import('@bayer-int/orthomosaic-uploader')
// type OrthoSDKModule = typeof import('../../../location360-img-orthomosaic-upload-sdk/dist')
