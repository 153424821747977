import React, {memo} from 'react'
import styled from 'styled-components'

const DescriptionContainer = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 0 5px;
  color: var(--mdc-theme-text-disabled-on-background);
`

/**
 * A scrolling element and surrounding container. Used to display the description in the project and album directory cards.
 */
const DirectoryCardScrollingDescription = memo(function (props) {
  const {description} = props

  return (
    <DescriptionContainer className="description-container">{description}</DescriptionContainer>
  )
})

export default DirectoryCardScrollingDescription
