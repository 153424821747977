import React from 'react'
import {Star as StarIcon} from '@mui/icons-material/'

export function AutocompleteOption({
  name,
  description,
  starred,
  ...props
}: {
  name: string
  description: string
  starred?: boolean
} & any) {
  return (
    <option title={description} {...props}>
      {starred && (
        <>
          <StarIcon />
          &emsp;
        </>
      )}
      <span
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {name}&emsp;
      </span>
      <span
        style={{
          color: 'darkgrey',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {description}
      </span>
    </option>
  )
}
