import {useContext} from 'react'
import {initialToastState, toastNotificationContext} from '../context/toastNotificationContext'

const timeout = 10000

function useToastNotification() {
  const {setToastState} = useContext(toastNotificationContext)

  const resetNotification = () => setToastState({...initialToastState, message: '', open: false})

  const errorNotification = (message: string, options?: NotificationOptions) => {
    setToastState({message, timeout, open: true, severity: 'error', ...options})
  }
  const warningNotification = (message: string, options?: NotificationOptions) => {
    setToastState({message, timeout, open: true, severity: 'warning', ...options})
  }

  const infoNotification = (message: string, options?: NotificationOptions) => {
    setToastState({message, timeout, open: true, severity: 'info', ...options})
  }

  const successNotification = (message: string, options?: NotificationOptions) => {
    setToastState({message, timeout, open: true, severity: 'success', ...options})
  }

  return {
    errorNotification,
    warningNotification,
    infoNotification,
    successNotification,
    resetNotification,
  }
}

type NotificationOptions = {timeout?: number}

export default useToastNotification
