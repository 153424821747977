import React from 'react'
import {Autocomplete, Chip} from '@mui/material'
import {telemetry} from '../utilities/telemetry'
import useToastNotification from '../hooks/useToastNotification'
import {getIn, useFormikContext} from 'formik'
import {useGroupSearch} from '../utilities/profile'
import {CreateCollectionFields, StyledTextField, GroupOption} from './SelfService'
import {FormikErrorMessage} from './FormikErrorMessage'
import {AutocompleteOption} from './AutocompleteOption'

export function EntitlementField({name, label, options, loading}) {
  const {values, handleBlur, setFieldValue} = useFormikContext<CreateCollectionFields>()
  const {errorNotification} = useToastNotification()

  const value = getIn(values, name)

  const {options: searchOptions, setText: setSearchText} = useGroupSearch({
    onError: (err: Error) => {
      const details = `Failed to search for group "${value}"`
      telemetry.error(err, details)
      errorNotification(details)
    },
  })

  const allOptions = options.concat(searchOptions) ?? []

  return (
    <div style={{margin: '8px'}}>
      <Autocomplete
        multiple
        freeSolo
        openOnFocus
        disableClearable
        options={allOptions}
        loading={loading}
        value={value}
        renderInput={params => (
          <StyledTextField {...params} name={name} label={label} style={{marginTop: 0}} />
        )}
        onBlur={handleBlur}
        onInputChange={(_, value) => setSearchText(value)}
        onChange={(_, value) => setFieldValue(name, value)}
        renderTags={(tagValue, getTagProps) => (
          <>
            {tagValue.map((option, index) => {
              return (
                <Chip
                  data-testid={`chip:${name}:${option.id}`}
                  label={option.name}
                  {...getTagProps({index})}
                  key={option.id}
                />
              )
            })}
          </>
        )}
        renderOption={(props, option: GroupOption) => (
          <AutocompleteOption
            starred={option.hasGroup}
            name={option.name}
            description={option.description}
            {...props}
            key={option.hasGroup ? `hasGroup:${option.id}` : option.id}
          />
        )}
        getOptionLabel={option => option.name ?? option.id}
        getOptionDisabled={option =>
          value.findIndex((opt: GroupOption) => opt.id === option.id) !== -1
        }
      />
      <FormikErrorMessage name={name} />
    </div>
  )
}
