import {getBearerToken} from '../utilities/initializeProfile'

const LOCAL_SDK = false
const LOCAL_SDK_PORT = 8080

export async function fetchImagineSdk(): Promise<ImagineSDKModule> {
  let res: Response
  if (LOCAL_SDK) {
    res = await fetch(`http://127.0.0.1:${LOCAL_SDK_PORT}/index-browser.js`, {
      cache: 'no-cache',
    })
  } else {
    res = await fetch('https://unpkg.loc360.monsanto.net/@bayer-int/imagine-sdk-browser', {
      headers: {
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    })
  }

  const source = await res.blob()

  return await import(/* webpackIgnore: true */ URL.createObjectURL(source))
}

type ImagineSDKModule = typeof import('@bayer-int/imagine-sdk-browser')
