import React from 'react'
import PropTypes from 'prop-types'
import {Typography} from '@mui/material'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'

import {addAvailableWorkflowStages, setUploaderWorkflowStage} from '../actions/imageUploaderAction'
import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'

const DropArea = styled('section')`
  align-items: center;
  background-color: #fff;
  border: dashed;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  font-size: 20px;
  justify-content: center;
  position: relative;
  user-select: none;
  width: 100%;

  transition: border 0.35s ease-in-out;
  &:hover {
    border-color: #888;
  }

  input {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }
`

const DropInputLabel = styled('label')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`

/**
 * Mission Package directory selection for Imagine Uploader
 */
function UploaderMissionPackageFileSelector(props) {
  const {initializeUploader, setCriticalError} = props

  const dispatch = useDispatch()
  const setUploaderStage = stage => dispatch(setUploaderWorkflowStage(stage))
  const addWorkflowStages = stages => dispatch(addAvailableWorkflowStages(stages))

  const setFiles = event => {
    event.persist()

    initializeUploader(event)
      .then(() => {
        addWorkflowStages([1])
        setUploaderStage(1)
      })
      .catch(e => {
        const details = 'Failed to initialize uploader'
        telemetry.error(e, details)
        setCriticalError(e)
      })
  }

  return (
    <DropArea>
      <input
        type="file"
        name="file"
        id="file"
        webkitdirectory="true"
        multiple
        onChange={setFiles}
      />
      <DropInputLabel htmlFor="file">
        <CreateNewFolderIcon size="large" />
        <Typography use="subtitle1" style={{cursor: 'pointer'}}>
          Click to select mission package directory
        </Typography>
      </DropInputLabel>
    </DropArea>
  )
}

UploaderMissionPackageFileSelector.propTypes = {
  initializeUploader: PropTypes.func.isRequired,
  setCriticalError: PropTypes.func.isRequired,
}

export default UploaderMissionPackageFileSelector
