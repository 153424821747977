import React, {useContext, useState} from 'react'
import styled from 'styled-components'
import {
  FormGroup,
  FormControlLabel,
  IconButton,
  ImageList,
  Link as MuiLink,
  Paper,
  Switch,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material'
import {
  AddToPhotos,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBoxOutlined,
  RemoveCircleOutline,
  ExpandLessRounded,
} from '@mui/icons-material'
import {telemetry} from '../utilities/telemetry'

import {galleryPageContext} from '../context/galleryPageContext'
import {GalleryItemProvider} from '../context/galleryItemContext'

import useMultipleSelect from '../hooks/useMultipleSelect'
import useToastNotification from '../hooks/useToastNotification'

import CardGenericError from './CardGenericError'
import DialogAddToAlbum from './DialogAddToAlbum'
import GalleryNavigationButtons from './GalleryNavigationButtons'
import GalleryViewGridItem from './GalleryViewGridItem'
import DialogMultiTagActions from './DialogMultiTagActions'
import useGalleryCardWidth from '../hooks/useGalleryCardWidth'
import GalleryViewErrorCard from './GalleryViewErrorCard'
import useInfiniteScroll from 'react-easy-infinite-scroll-hook'

const StyledImageList = styled(ImageList)`
  text-align: left;
  margin: 0 auto !important;
  display: grid;
  grid-auto-rows: ${props => props.$cardWidth}px;
  grid-template-columns: repeat(${props => `${props.cols}, ${props.$cardWidth}px`});
  padding: 10px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  width: calc(100vw - 40px);
`

const Container = styled('div')`
  align-self: stretch;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SelectedInfoWrapper = styled('div')`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 10px;

  .copy {
    display: flex;
    align-items: center;
    user-select: none;
  }

  .multiple-select-elements {
    display: flex;
    align-items: center;
  }

  .multiple-select-elements {
    display: flex;
    align-items: center;
  }
`

const PaddedPaper = styled(Paper)`
  padding: 10px 10px 40px 10px;
  // width: 100%;
  // flex: 1;
`
const Spacer = styled.div`
  width: 1px;
  border-right: 1px solid var(--mdc-theme-primary);
  margin-right: 8px;
  margin-left: 8px;
  height: 21px;
`

/**
 * A grid of items in a collection or search
 */
function GalleryViewGrid(props) {
  const {canRemoveAlbumItem, removeItemsFromState, fullSearchTerms} = props
  const {
    album,
    collection,
    project,
    items,
    itemsPinned,
    toggleItemsPinned,
    next,
    hasMore,
    loading,
  } = useContext(galleryPageContext)

  const [selectMode, setSelectMode] = useState(false)
  const [addToAlbumContent, setAddToAlbumContent] = useState([])

  const {errorNotification, successNotification} = useToastNotification()

  const {selectedItems, clearItems, toggleSelectAll} = useMultipleSelect()

  const {cardWidth, numberColumns} = useGalleryCardWidth()

  const deselectImages = () => {
    clearItems()
    setSelectMode(false)
  }

  function removeItemsFromAlbum(itemsToRemove) {
    if (!album) throw new Error('This method should not execute without an album object present')

    album
      .removeItems(itemsToRemove)
      .then(() => {
        successNotification(`The selected items were removed`)
        removeItemsFromState(itemsToRemove)
        clearItems()
      })
      .catch(e => {
        const additionalErrorDetails = 'Unable to remove selected items from this album.'
        telemetry.error(e, additionalErrorDetails)
        errorNotification(e.message)
      })
  }

  if (!items?.length && album)
    return (
      <CardGenericError
        height="185px"
        level="info"
        title="No items in this album"
        description={
          canRemoveAlbumItem
            ? 'You can add items to this album through the gallery and item views'
            : 'The owner of the album can add items, or remove the album'
        }
      />
    )

  if (!items?.length) {
    let caption = 'No items were found in this search. Try using different search terms.'

    if (project) caption = 'No items are in this project'
    if (collection) caption = 'No items are in this collection'

    return (
      <div
        style={{
          height: '60vh',
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column',
        }}
      >
        <GalleryViewErrorCard title="No items available" description={caption} level="info" />
        <div style={{flexDirection: 'row'}}>
          <GalleryNavigationButtons disabled loading={false} buttonLabel="View Previous" />
          <GalleryNavigationButtons disabled loading={false} buttonLabel="View Next" />
        </div>
      </div>
    )
  }

  return (
    <Container>
      <PaddedPaper elevation={0}>
        <SelectedInfoWrapper>
          <div className="pin-item-display">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch checked={itemsPinned} onChange={toggleItemsPinned} color="primary" />
                }
                label={
                  <div className="copy">
                    <Typography color="primary" variant="body2">
                      View Metadata
                    </Typography>
                  </div>
                }
              />
            </FormGroup>
          </div>
          <div className="multiple-select-elements">
            {selectMode ? (
              <>
                <span style={{marginRight: '8px'}}>
                  {selectedItems.length} item{selectedItems.length !== 1 && 's'} Selected
                </span>
                <Spacer />
                <IconButton
                  onClick={() => {
                    toggleSelectAll(items)
                  }}
                  size="large"
                >
                  <>
                    {selectedItems.length === 0 && <CheckBoxOutlineBlank color="primary" />}
                    {selectedItems.length > 0 && selectedItems.length !== items.length && (
                      <IndeterminateCheckBoxOutlined color="primary" />
                    )}
                    {selectedItems.length > 0 && selectedItems.length === items.length && (
                      <CheckBox color="primary" />
                    )}
                  </>
                </IconButton>
                <Spacer />
                <Tooltip title="Add selected items to album">
                  <span>
                    <IconButton
                      color="primary"
                      onClick={() => setAddToAlbumContent(selectedItems)}
                      disabled={selectedItems?.length < 1}
                      size="large"
                    >
                      <AddToPhotos />
                    </IconButton>
                  </span>
                </Tooltip>
                {album && canRemoveAlbumItem && (
                  <Tooltip title="Remove selected items from album">
                    <span>
                      <IconButton
                        color="primary"
                        onClick={() => removeItemsFromAlbum(selectedItems)}
                        disabled={selectedItems?.length < 1}
                        size="large"
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                <Spacer />

                <DialogMultiTagActions selectedItems={selectedItems} />

                <Spacer />
                <MuiLink
                  component="button"
                  variant="body1"
                  onClick={() => deselectImages()}
                  style={{marginLeft: 8}}
                >
                  Cancel selection
                </MuiLink>
              </>
            ) : (
              <MuiLink component="button" variant="body1" onClick={() => setSelectMode(true)}>
                Select items
              </MuiLink>
            )}
          </div>
        </SelectedInfoWrapper>
        <WindowList
          cardWidth={cardWidth}
          numberColumns={numberColumns}
          selectMode={selectMode}
          canRemoveAlbumItem={canRemoveAlbumItem}
          setAddToAlbumContent={setAddToAlbumContent}
          fullSearchTerms={fullSearchTerms}
          next={next}
          data={items}
          hasMore={hasMore}
          loading={loading}
        />
      </PaddedPaper>
      {addToAlbumContent.length > 0 && (
        <DialogAddToAlbum items={addToAlbumContent} handleClose={() => setAddToAlbumContent([])} />
      )}
    </Container>
  )
}

const WindowList = ({
  cardWidth,
  numberColumns,
  selectMode,
  canRemoveAlbumItem,
  setAddToAlbumContent,
  fullSearchTerms,
  data,
  hasMore,
  loading,
  next,
}) => {
  useInfiniteScroll({
    next,
    rowCount: data?.length ?? 0,
    hasMore: {down: hasMore ?? true},
    windowScroll: true,
  })

  return (
    <>
      <StyledImageList cols={numberColumns} $cardWidth={cardWidth} rowHeight={232}>
        {data?.map(item => (
          <GalleryItemProvider key={`gallery-viewing-tile-${item?.id}`} item={item}>
            <GalleryViewGridItem
              item={item}
              selectMode={selectMode}
              cardWidth={cardWidth}
              canRemoveAlbumItem={canRemoveAlbumItem}
              addItemToAlbum={() => setAddToAlbumContent([item])}
              fullSearchTerms={fullSearchTerms}
            />
          </GalleryItemProvider>
        ))}
        {loading && (
          <CircularProgress
            size={cardWidth / 2}
            style={{marginLeft: cardWidth / 4, marginTop: cardWidth / 4}}
          />
        )}
      </StyledImageList>
      <IconButton
        onClick={() => window.scroll(0, 0)}
        sx={{
          position: 'fixed',
          right: '24px',
          bottom: '24px',
          borderRadius: '50%',
          backgroundColor: 'lightgrey',
          border: '1px solid rgba(0, 0, 0, .4)',
          boxShadow: '3px 3px 8px rgba(6, 15, 20, 0.65098)',
          opacity: '60%',
          '&:hover': {
            backgroundColor: 'lightgrey',
            opacity: '90%',
          },
          margin: 0,
        }}
      >
        <ExpandLessRounded fontSize="large" />
      </IconButton>
    </>
  )
}

GalleryViewGrid.defaultProps = {
  canRemoveAlbumItem: false,
  removeItemsFromState: () => console.log('removeItemsFromState not implemented'),
  fullSearchTerms: '',
}

export default GalleryViewGrid
