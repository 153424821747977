import React, {useEffect, useState} from 'react'
import {DeleteForever as DeleteForeverIcon} from '@mui/icons-material/'
import {
  Button,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  SwitchProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import {FormikErrorMessage} from './FormikErrorMessage'
import {FieldArray, Formik, FormikHelpers, getIn, useFormikContext} from 'formik'
import {BorderedSection} from './BorderedSection'
import {instanceOf} from 'prop-types'
import {CSSProperties} from 'styled-components'
import {TitleSection} from './SelfService'
import {telemetry} from '../utilities/telemetry'

export const annotations = [
  '$id',
  '$schema',
  'title',
  'description',
  'default',
  'examples',
  'readOnly',
  'writeOnly',
  '$comment',
]

export const simpleTypes = ['string', 'number', 'integer', 'boolean', 'object', 'array', 'null']

export const typeKeywords = {
  any: [
    'additionalProperties',
    'allOf',
    'anyOf',
    'contains',
    'const',
    'dependencies',
    'else',
    'enum',
    'exclusiveMaximum',
    'exclusiveMinimum',
    'format',
    'if',
    'items',
    'maxItems',
    'maxLength',
    'maxProperties',
    'maximum',
    'minItems',
    'minLength',
    'minProperties',
    'minimum',
    'multipleOf',
    'not',
    'oneOf',
    'pattern',
    'properties',
    'propertyNames',
    'required',
    'then',
    'type',
    'uniqueItems',
  ],
  null: ['allOf', 'anyOf', 'const', 'enum', 'format', 'not', 'oneOf'],
  number: [
    'allOf',
    'anyOf',
    'const',
    'else',
    'enum',
    'exclusiveMaximum',
    'exclusiveMinimum',
    'format',
    'if',
    'maximum',
    'minimum',
    'multipleOf',
    'not',
    'oneOf',
    'then',
    'type',
  ],
  integer: [
    'allOf',
    'anyOf',
    'const',
    'else',
    'enum',
    'exclusiveMaximum',
    'exclusiveMinimum',
    'format',
    'if',
    'maximum',
    'minimum',
    'multipleOf',
    'not',
    'oneOf',
    'then',
    'type',
  ],
  string: [
    'allOf',
    'anyOf',
    'const',
    'else',
    'enum',
    'format',
    'if',
    'maxLength',
    'minLength',
    'not',
    'oneOf',
    'pattern',
    'then',
    'type',
  ],
  object: [
    'additionalProperties',
    'allOf',
    'anyOf',
    'const',
    'dependencies',
    'else',
    'enum',
    'format',
    'if',
    'maxProperties',
    'minProperties',
    'not',
    'oneOf',
    'patternProperties',
    'properties',
    'propertyNames',
    'required',
    'then',
    'type',
  ],
  boolean: ['allOf', 'anyOf', 'const', 'enum', 'not', 'oneOf', 'type'],
  array: [
    'allOf',
    'anyOf',
    'const',
    'contains',
    'else',
    'enum',
    'if',
    'items',
    'maxItems',
    'minItems',
    'not',
    'oneOf',
    'then',
    'type',
    'uniqueItems',
  ],
}

const keywordInitialValues = {
  type: '',
  allOf: [],
  anyOf: [],
  contains: {},
  enum: [],
  if: {},
  then: {},
  else: {},
  items: {},
  additionalItems: {},
  maxItems: 0,
  minItems: 0,
  not: {},
  oneOf: [],
  uniqueItems: false,
  additionalProperties: {},
  dependencies: {},
  format: '',
  maxProperties: 0,
  minProperties: 0,
  patternProperties: {},
  properties: {},
  propertyNames: {},
  maxLength: 0,
  minLength: 0,
  pattern: '',
  exclusiveMaximum: 0,
  exclusiveMinimum: 0,
  maximum: 0,
  minimum: 0,
  const: {
    string: '',
    number: 0,
    integer: 0,
    boolean: false,
    object: '{}',
    array: '[]',
  },
  multipleOf: 0,
  required: [],
  contentMediaType: '',
  contentEncoding: '',
  definitions: {},
}

class ErrorBoundary extends React.Component<any, {error: string | null}> {
  constructor(props: any) {
    super(props)
    this.state = {error: null}
  }

  componentDidCatch(err: Error) {
    telemetry.error(err)
    if (instanceOf(Error)) {
      this.setState({error: `${err.name}: ${err.message}`})
    } else {
      this.setState({error: 'Unexpected application error occurred.'})
    }
  }

  render() {
    const {error} = this.state
    if (error) {
      return <div>{error}</div>
    }
    return <>{this.props.children}</>
  }
}

function processArray(arr: any[]): any[] {
  return arr.map(x => {
    if (x.constructor === Object) {
      return processObject(x)
    }
    if (Array.isArray(x)) {
      return processArray(x)
    }
    return x
  })
}

function processKey(obj: any, key: string): any {
  const value = obj[key]
  switch (key) {
    case 'const':
    case 'default':
    case 'examples':
      if (['object', 'array'].includes(value.type)) {
        return JSON.parse(value.value)
      }
      return value.value
    case 'enum':
      return value.map((v: {type: string; value: string}) => {
        if (['object', 'array'].includes(v.type)) {
          return JSON.parse(v.value)
        }
        return v.value
      })
    default:
      if (obj[key].constructor === Object || Array.isArray(value)) {
        return processObject(value)
      }
      return value
  }
}

function processObject(obj: any): any {
  if (Array.isArray(obj)) {
    return processArray(obj)
  }
  if (obj?.constructor !== Object) {
    throw new Error('Failed to process object')
  }
  return Object.keys(obj).reduce((acc, k) => {
    acc[k] = processKey(obj, k)
    return acc
  }, {})
}

export function JSONSchemaFormikForm({
  onSubmit,
  initialValues,
  validate,
}: {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>
  initialValues?: any
  validate: any
}) {
  const _initialValues = initialValues ?? {
    type: 'object',
  }
  return (
    <Formik
      initialValues={{
        root: _initialValues,
      }}
      component={({handleSubmit}) => {
        return (
          <ErrorBoundary>
            <form onSubmit={handleSubmit} style={{padding: '10px'}} role="form">
              <TitleSection>
                <DialogTitle>Extension Form</DialogTitle>
              </TitleSection>
              <JSONSchemaField name="root" />
              <Button style={{marginTop: '20px'}} variant="contained" type="submit">
                Submit
              </Button>
            </form>
          </ErrorBoundary>
        )
      }}
      validate={validate}
      onSubmit={onSubmit}
    />
  )
}

export function AddTypeKeyword({name}: {name: string}) {
  const {values, setFieldValue} = useFormikContext()
  const value = getIn(values, name)
  const currentType = value.type === '' || Array.isArray(value.type) ? 'any' : value.type ?? 'any'
  const currentKeywords = Object.keys(value)
  const [keywordType, setKeywordType] = useState<string>('')
  const keywordBaseOptions = typeKeywords[currentType]
  if (keywordBaseOptions === undefined) {
    throw new Error(`Unsupported keyword of type "${currentType}"`)
  }
  const keywordOptions = [''].concat(
    keywordBaseOptions.filter((keyword: string) => !currentKeywords.includes(keyword))
  )

  useEffect(() => {
    const option = keywordOptions?.[0]
    if (option) {
      setKeywordType(option)
    }
  }, [keywordOptions.length])

  return (
    <>
      {keywordOptions?.length && (
        <div style={{display: 'flex', marginTop: '10px'}}>
          <FormControl>
            <InputLabel>Add Validator</InputLabel>
            <Select
              role="select"
              native
              data-testid={`${name}:add-validator`}
              sx={{width: '175px'}}
              value={keywordType}
              onChange={e => {
                const keywordType = e.target.value
                if (keywordType === '') {
                  return
                }
                if (keywordType === 'const' && currentType === 'any') {
                  setFieldValue(`${name}.type`, 'string')
                }
                const initialValues = keywordInitialValues[keywordType]
                const v =
                  keywordType === 'const'
                    ? initialValues[currentType === 'any' ? 'string' : currentType] ?? '' // const default value not getting found by stringfield for some reason
                    : initialValues
                setFieldValue(`${name}.${keywordType}`, v)
              }}
            >
              {keywordOptions.map((a: string) => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  )
}

function TypeKeyword({name, kind, onDelete}: {name: string; kind: string; onDelete: () => void}) {
  switch (kind) {
    case 'type':
      return <TypeTypeKeyword name={name} onDelete={onDelete} />
    case 'allOf':
      return <AllOfKeyword name={name} onDelete={onDelete} />
    case 'anyOf':
      return <AnyOfKeyword name={name} onDelete={onDelete} />
    case 'contains':
      return <ContainsKeyword name={name} onDelete={onDelete} />
    case 'enum':
      return <EnumKeyword name={name} onDelete={onDelete} />
    case 'if':
      return <IfKeyword name={name} onDelete={onDelete} />
    case 'then':
      return <ThenKeyword name={name} onDelete={onDelete} />
    case 'else':
      return <ElseKeyword name={name} onDelete={onDelete} />
    case 'items':
      return <ItemsKeyword name={name} onDelete={onDelete} />
    case 'additionalItems':
      return <AdditionalItemsKeyword name={name} onDelete={onDelete} />
    case 'maxItems':
      return <MaxItemsKeyword name={name} onDelete={onDelete} />
    case 'minItems':
      return <MinItemsKeyword name={name} onDelete={onDelete} />
    case 'not':
      return <NotKeyword name={name} onDelete={onDelete} />
    case 'oneOf':
      return <OneOfKeyword name={name} onDelete={onDelete} />
    case 'uniqueItems':
      return <UniqueItemsKeyword name={name} onDelete={onDelete} />
    case 'additionalProperties':
      return <AdditionalPropertiesKeyword name={name} onDelete={onDelete} />
    case 'dependencies':
      return <DependenciesKeyword name={name} onDelete={onDelete} />
    case 'format':
      return <FormatKeyword name={name} onDelete={onDelete} />
    case 'maxProperties':
      return <MaxPropertiesKeyword name={name} onDelete={onDelete} />
    case 'minProperties':
      return <MinPropertiesKeyword name={name} onDelete={onDelete} />
    case 'patternProperties':
      return <PatternPropertiesKeyword name={name} onDelete={onDelete} />
    case 'properties':
      return <PropertiesKeyword name={name} onDelete={onDelete} />
    case 'propertyNames':
      return <PropertyNamesKeyword name={name} onDelete={onDelete} />
    case 'maxLength':
      return <MaxLengthKeyword name={name} onDelete={onDelete} />
    case 'minLength':
      return <MinLengthKeyword name={name} onDelete={onDelete} />
    case 'pattern':
      return <PatternKeyword name={name} onDelete={onDelete} />
    case 'exclusiveMaximum':
      return <ExclusiveMaximumKeyword name={name} onDelete={onDelete} />
    case 'exclusiveMinimum':
      return <ExclusiveMinimumKeyword name={name} onDelete={onDelete} />
    case 'maximum':
      return <MaximumKeyword name={name} onDelete={onDelete} />
    case 'minimum':
      return <MinimumKeyword name={name} onDelete={onDelete} />
    case 'const':
      return <ConstKeyword name={name} onDelete={onDelete} />
    case 'multipleOf':
      return <MultipleOfKeyword name={name} onDelete={onDelete} />
    case 'required':
      return <RequiredKeyword name={name} onDelete={onDelete} />
    case 'contentMediaType':
      return <ContentMediaTypeKeyword name={name} onDelete={onDelete} />
    case 'contentEncoding':
      return <ContentEncodingKeyword name={name} onDelete={onDelete} />
    case 'definitions':
      return <DefinitionsKeyword name={name} onDelete={onDelete} />
    default:
      throw new Error(`Unsupported keyword of type "${kind}"`)
  }
}

export function AddAnnotation({name}: {name: string}) {
  const {values, setFieldValue} = useFormikContext()
  const value = getIn(values, name)
  const [annotationType, setAnnotationType] = useState<string>('')
  const currentAnnotations = Object.keys(value)
  const annotationOptions = [''].concat(annotations.filter(a => !currentAnnotations.includes(a)))

  useEffect(() => {
    const option = annotationOptions?.[0]
    if (option) {
      setAnnotationType(option)
    }
  }, [annotationOptions.length])

  return (
    <>
      {annotationOptions?.length && (
        <div style={{display: 'flex', marginTop: '10px'}}>
          <FormControl>
            <InputLabel>Add Annotation</InputLabel>
            <Select
              data-testid={`${name}:add-annotation`}
              native
              sx={{width: '175px'}}
              value={annotationType}
              onChange={e => {
                const annotationType = e.target.value
                if (annotationType === '') {
                  return
                }
                setFieldValue(`${name}.${annotationType}`, '')
                setAnnotationType('')
              }}
            >
              {annotationOptions.map(a => (
                <option key={a} value={a}>
                  {a}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  )
}

function Annotation({name, kind, onDelete}: {name: string; kind: string; onDelete: () => void}) {
  switch (kind) {
    case '$id':
      return <IdAnnotation name={name} onDelete={onDelete} />
    case '$schema':
      return <SchemaAnnotation name={name} onDelete={onDelete} />
    case 'title':
      return <TitleAnnotation name={name} onDelete={onDelete} />
    case 'description':
      return <DescriptionAnnotation name={name} onDelete={onDelete} />
    case 'default':
      return <DefaultAnnotation name={name} onDelete={onDelete} />
    case 'examples':
      return <ExamplesAnnotation name={name} onDelete={onDelete} />
    case 'readOnly':
      return <ReadOnlyAnnotation name={name} onDelete={onDelete} />
    case 'writeOnly':
      return <WriteOnlyAnnotation name={name} onDelete={onDelete} />
    case '$comment':
      return <CommentAnnotation name={name} onDelete={onDelete} />
    default:
      throw new Error(`Unsupported annotation of type "${kind}"`)
  }
}

export function TypeTypeKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Type" onDelete={props.onDelete}>
      <JSONSchemaTypeNameField {...props} />
    </KeywordWrapper>
  )
}

export function AllOfKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <BorderedKeywordWrapper label="All Of" onDelete={props.onDelete} name={props.name}>
      <JSONSchemaDefinitionArrayField {...props} />
    </BorderedKeywordWrapper>
  )
}

function ContainsKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Contains" onDelete={props.onDelete}>
      <JSONSchemaDefinitionField {...props} />
    </KeywordWrapper>
  )
}

function EnumKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <BorderedKeywordWrapper name={props.name} label="Enum" onDelete={props.onDelete}>
      <JSONSchemaTypeArrayField name={props.name} />
    </BorderedKeywordWrapper>
  )
}

function IfKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="If" onDelete={props.onDelete}>
      <JSONSchemaDefinitionField name={props.name} />
    </KeywordWrapper>
  )
}

function ThenKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Then" onDelete={props.onDelete}>
      <JSONSchemaDefinitionField name={props.name} />
    </KeywordWrapper>
  )
}

function ElseKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Else" onDelete={props.onDelete}>
      <JSONSchemaDefinitionField name={props.name} />
    </KeywordWrapper>
  )
}

function ItemsKeyword(props: TextFieldProps & {onDelete: () => void}) {
  const {values, setFieldValue} = useFormikContext()
  const value = getIn(values, props.name)
  const [isTuple, setIsTuple] = useState<boolean>(false)

  useEffect(() => {
    setIsTuple(Array.isArray(value))
  }, [value])

  return (
    <KeywordWrapper label="Items" onDelete={props.onDelete}>
      <FormControl style={{marginBottom: '10px'}}>
        <FormControlLabel
          required
          control={
            <Switch
              checked={isTuple}
              value={isTuple}
              onChange={e => {
                const checked = e.target.checked
                if (checked) {
                  setFieldValue(props.name, [])
                } else {
                  setFieldValue(props.name, {})
                }
                setIsTuple(checked)
              }}
            />
          }
          label="Is Tuple"
        />
      </FormControl>
      {isTuple ? (
        <JSONSchemaDefinitionArrayField name={props.name} />
      ) : (
        <JSONSchemaDefinitionField name={props.name} />
      )}
    </KeywordWrapper>
  )
}

function AdditionalItemsKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Additional" onDelete={props.onDelete}>
      <JSONSchemaDefinitionField name={props.name} />
    </KeywordWrapper>
  )
}

function MaxItemsKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Max Items" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function MinItemsKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Min Items" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function NotKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Not" onDelete={props.onDelete}>
      <JSONSchemaDefinitionField name={props.name} />
    </KeywordWrapper>
  )
}

function OneOfKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <BorderedKeywordWrapper name={props.name} label="One Of" onDelete={props.onDelete}>
      <JSONSchemaDefinitionArrayField {...props} />
    </BorderedKeywordWrapper>
  )
}

function UniqueItemsKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Unique Items" onDelete={props.onDelete}>
      <BooleanField name={props.name} label="Unique Items" />
    </KeywordWrapper>
  )
}

function AdditionalPropertiesKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Additional Properties" onDelete={props.onDelete}>
      <JSONSchemaDefinitionField name={props.name} />
    </KeywordWrapper>
  )
}

function AnyOfKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <BorderedKeywordWrapper name={props.name} label="Any Of" onDelete={props.onDelete}>
      <JSONSchemaDefinitionArrayField {...props} />
    </BorderedKeywordWrapper>
  )
}

function DependenciesKeyword(props: {name: string; onDelete: () => void}) {
  const {name, onDelete} = props
  const {values, setFieldValue} = useFormikContext()
  const value = getIn(values, name)
  const [dependency, setDependency] = useState<string>('')
  const dependencies = Object.keys(value)

  return (
    <KeywordWrapper label="Dependencies" onDelete={onDelete}>
      <FieldArray
        name={name}
        render={({remove}) => {
          return (
            <div>
              {dependencies.map((d, i) => (
                <DependencyKeyword
                  key={d}
                  name={`${name}.${d}`}
                  onDelete={() => {
                    if (Array.isArray(value[d])) {
                      remove(i)
                    } else {
                      setFieldValue(`${name}.${d}`, undefined)
                    }
                  }}
                />
              ))}
              <div style={{display: 'flex'}}>
                <FormControl>
                  <InputLabel>Add Dependency</InputLabel>
                  <Select
                    style={{width: '250px'}}
                    onChange={e => setDependency(e.target.value)}
                    value={dependency}
                  >
                    <MenuItem value="schema">schema</MenuItem>
                    <MenuItem value="string">string</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  onClick={() =>
                    setFieldValue(`${name}.${dependency}`, dependency === 'schema' ? {} : [''])
                  }
                >
                  Add
                </Button>
              </div>
            </div>
          )
        }}
      />
    </KeywordWrapper>
  )
}

function DependencyKeyword(props: {name: string; onDelete: () => void}) {
  const {name, onDelete} = props
  const {values} = useFormikContext()
  const value = getIn(values, name)

  const field = Array.isArray(value) ? (
    <div>
      <StringArrayField name={name} addButtonLabel="Add" />
    </div>
  ) : (
    <JSONSchemaDefinitionField name={name} />
  )

  return (
    <KeywordWrapper label="Dependency" onDelete={onDelete}>
      {field}
    </KeywordWrapper>
  )
}

function FormatKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Format" onDelete={props.onDelete}>
      <StringField name={props.name} label="Format" />
    </KeywordWrapper>
  )
}

function MaxPropertiesKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Max Properties" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function MinPropertiesKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Min Properties" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function PatternPropertiesKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Pattern Properties" onDelete={props.onDelete}>
      <StringField name={props.name} />
    </KeywordWrapper>
  )
}

function PropertiesKeyword(props: {name: string; onDelete: () => void}) {
  const {name, onDelete} = props

  return (
    <BorderedKeywordWrapper label="Properties" onDelete={onDelete} name={name}>
      <div style={{padding: '10px'}}>
        <JSONSchemaDefinitionObjectField name={name} />
      </div>
    </BorderedKeywordWrapper>
  )
}

function PropertyNamesKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Property Names" onDelete={props.onDelete}>
      <JSONSchemaDefinitionField name={props.name} />
    </KeywordWrapper>
  )
}

function MaxLengthKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Max Length" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function MinLengthKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Min Length" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function PatternKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Pattern" onDelete={props.onDelete}>
      <StringField name={props.name} />
    </KeywordWrapper>
  )
}

function ExclusiveMaximumKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Exclusive Maximum" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function ExclusiveMinimumKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Exclusive Minimum" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function MaximumKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Maximum" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function MinimumKeyword(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <KeywordWrapper label="Minimum" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

export function SimpleTypeField(props: {name: string; label: string}) {
  const {values, setFieldValue} = useFormikContext()
  const value = getIn(values, props.name)
  const parentName = props.name.split('.').slice(0, -1).join('.')
  const parentValue = getIn(values, parentName)
  const type = parentValue?.type

  switch (type) {
    case 'string':
      return <StringField name={props.name} />
    case 'number':
      return <NumberField name={props.name} />
    case 'integer':
      return <NumberField name={props.name} />
    case 'boolean':
      return <BooleanField name={props.name} label={props.label} />
    case 'object':
      return <StringField name={props.name} />
    case 'array':
      return <StringField name={props.name} />
    case 'null':
      if (type === 'null' && value !== null) {
        setFieldValue(props.name, null)
      }
      return (
        <FormControl>
          <TextField
            inputProps={{'data-testid': props.name}}
            name={props.name}
            variant="outlined"
            style={{width: '250px'}}
            value="null"
          />
        </FormControl>
      )

    default:
      return <StringField name={props.name} />
  }
}

function SimpleTypeKeyword(props: {name: string; onDelete: () => void; label: string}) {
  return (
    <KeywordWrapper label={props.label} onDelete={props.onDelete}>
      <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
        <SimpleTypeField name={`${props.name}.value`} label={props.label} />
        <JSONSchemaTypeNameField name={`${props.name}.type`} style={{height: '53.13px'}} />
      </div>
    </KeywordWrapper>
  )
}

function ConstKeyword(props: {name: string; onDelete: () => void}) {
  return <SimpleTypeKeyword label="Const" {...props} />
}

function MultipleOfKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Multiple Of" onDelete={props.onDelete}>
      <NumberField name={props.name} />
    </KeywordWrapper>
  )
}

function RequiredKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Required" onDelete={props.onDelete}>
      <StringArrayField name={props.name} addButtonLabel="Add Required" />
    </KeywordWrapper>
  )
}

function ContentMediaTypeKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Content Media Type" onDelete={props.onDelete}>
      <StringField name={props.name} />
    </KeywordWrapper>
  )
}

function ContentEncodingKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Content Encoding" onDelete={props.onDelete}>
      <StringField name={props.name} />
    </KeywordWrapper>
  )
}

function DefinitionsKeyword(props: {name: string; onDelete: () => void}) {
  return (
    <KeywordWrapper label="Definitions" onDelete={props.onDelete}>
      <JSONSchemaDefinitionObjectField name={props.name} />
    </KeywordWrapper>
  )
}

function BorderedKeywordWrapper(props: {
  label: string
  name: string
  onDelete: () => void
  children: React.ReactNode
}) {
  const {label, name, onDelete, children} = props
  return (
    <div data-testid={`${name}:container`} style={{marginBottom: '10px'}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">{label}</Typography>
        <IconButton onClick={onDelete}>
          <DeleteForeverIcon />
        </IconButton>
      </div>
      <BorderedSection title={label}>
        <div style={{padding: '10px'}}>{children}</div>
      </BorderedSection>
    </div>
  )
}

export function KeywordWrapper(props: {
  label: string
  onDelete: () => void
  children: React.ReactNode
  style?: CSSProperties
  actionButton?: React.ReactNode
}) {
  const {label, onDelete, children, actionButton} = props
  return (
    <div style={{marginBottom: '10px', ...props.style}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Typography variant="h6">{label}</Typography>
        <IconButton onClick={onDelete}>
          <DeleteForeverIcon />
        </IconButton>
        {actionButton}
      </div>
      {children}
    </div>
  )
}

export function AnnotationWrapper(props: {
  label: string
  onDelete: () => void
  children: React.ReactNode
  style?: CSSProperties
  actionButton?: React.ReactNode
}) {
  const {label, onDelete, children, actionButton} = props
  return (
    <KeywordWrapper
      label={label}
      onDelete={onDelete}
      style={props.style}
      actionButton={actionButton}
    >
      {children}
    </KeywordWrapper>
  )
}

function IdAnnotation(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <AnnotationWrapper label="Id" onDelete={props.onDelete}>
      <StringField name={props.name} label="Id" />
    </AnnotationWrapper>
  )
}

function SchemaAnnotation(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <AnnotationWrapper label="Schema" onDelete={props.onDelete}>
      <StringField name={props.name} label="Schema" />
    </AnnotationWrapper>
  )
}

function TitleAnnotation(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <AnnotationWrapper label="Title" onDelete={props.onDelete}>
      <StringField name={props.name} label="Title" />
    </AnnotationWrapper>
  )
}

function DescriptionAnnotation(props: TextFieldProps & {onDelete: () => void}) {
  const {onDelete, ...rest} = props
  return (
    <AnnotationWrapper label="Description" onDelete={onDelete}>
      <StringField
        name={props.name}
        label="Description"
        multiline
        style={{width: '500px'}}
        {...rest}
      />
    </AnnotationWrapper>
  )
}

function DefaultAnnotation(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <AnnotationWrapper label="Default" onDelete={props.onDelete}>
      <SimpleTypeKeyword name={props.name} onDelete={props.onDelete} label="Default" />
    </AnnotationWrapper>
  )
}

function ExamplesAnnotation(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <AnnotationWrapper label="Examples" onDelete={props.onDelete}>
      <SimpleTypeKeyword name={props.name} onDelete={props.onDelete} label="Examples" />
    </AnnotationWrapper>
  )
}

function ReadOnlyAnnotation(props: SwitchProps & {onDelete: () => void}) {
  return (
    <AnnotationWrapper label="ReadOnly" onDelete={props.onDelete}>
      <BooleanField name={props.name} label="Read Only" {...props} />
    </AnnotationWrapper>
  )
}

function WriteOnlyAnnotation(props: SwitchProps & {onDelete: () => void}) {
  return (
    <AnnotationWrapper label="WriteOnly" onDelete={props.onDelete}>
      <BooleanField name={props.name} label="Write Only" {...props} />
    </AnnotationWrapper>
  )
}

function CommentAnnotation(props: TextFieldProps & {onDelete: () => void}) {
  return (
    <AnnotationWrapper label="Comment" onDelete={props.onDelete}>
      <StringField name={props.name} label="Comments" fullWidth multiline {...props} />
    </AnnotationWrapper>
  )
}

export function BooleanField(props: {name: string; label: string}) {
  const {values, setFieldValue, handleBlur} = useFormikContext()
  const value = getIn(values, props.name)

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Switch
            data-testid={props.name}
            checked={value}
            value={value}
            name={props.name}
            onChange={(_, v) => setFieldValue(props.name, v)}
            onBlur={handleBlur}
          />
        }
        label={props.label}
      />
      <FormikErrorMessage name={props.name} />
    </FormControl>
  )
}

export function StringArrayField(props: {name: string; addButtonLabel: string}) {
  const {name, addButtonLabel} = props
  const {values} = useFormikContext()
  const value = getIn(values, name)
  const [addText, setAddText] = useState<string>('')

  return (
    <FieldArray
      name={name}
      render={({push, remove}) => {
        return (
          <div style={{width: '250px'}}>
            {value.map((_, i: number) => (
              <div key={i} style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                <StringField name={`${name}.${i}`} />
                <IconButton onClick={() => remove(i)}>
                  <DeleteForeverIcon />
                </IconButton>
              </div>
            ))}
            <Button
              variant="contained"
              onClick={() => {
                push(addText)
                setAddText('')
              }}
            >
              {addButtonLabel}
            </Button>
          </div>
        )
      }}
    />
  )
}

export function StringField(props: TextFieldProps) {
  const {values, handleChange, handleBlur} = useFormikContext()

  return (
    <FormControl>
      <TextField
        inputProps={{'data-testid': props.name}}
        label={props.label}
        name={props.name}
        value={getIn(values, props.name)}
        onChange={e => handleChange(e)}
        onBlur={handleBlur}
        variant="outlined"
        {...props}
        style={{width: '250px', ...props.style}}
      />
      <FormikErrorMessage name={props.name} />
    </FormControl>
  )
}

export function NumberField(props: TextFieldProps) {
  const {values, handleChange, handleBlur} = useFormikContext()

  return (
    <FormControl>
      <TextField
        style={{width: '250px'}}
        type="number"
        label={props.label}
        name={props.name}
        value={getIn(values, props.name)}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        {...props}
      />
      <FormikErrorMessage name={props.name} />
    </FormControl>
  )
}

export function JSONSchemaTypeArrayField(props: {name: string}) {
  const {values} = useFormikContext()
  const {name} = props
  const value = getIn(values, name)
  const [typeOption, setTypeOption] = useState<string>('string')

  return (
    <FieldArray
      name={name}
      render={({push, remove}) => {
        return (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {value.map((_, i: number) => (
              <SimpleTypeKeyword
                key={i}
                name={`${name}.${i}`}
                onDelete={() => remove(i)}
                label="Value"
              />
            ))}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <FormControl>
                <InputLabel>Add Type</InputLabel>
                <Select
                  data-testid={`${name}:add-type`}
                  native
                  style={{width: '250px'}}
                  onChange={e => setTypeOption(e.target.value)}
                  value={typeOption}
                >
                  {simpleTypes.map(a => (
                    <option key={a} value={a}>
                      {a}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Button
                style={{marginLeft: '5px'}}
                variant="contained"
                onClick={() => push({value: '', type: typeOption})}
              >
                Add
              </Button>
            </div>
          </div>
        )
      }}
    />
  )
}

export function JSONSchemaTypeNameField(props: {name: string; style?: CSSProperties}) {
  const {values, handleBlur, handleChange} = useFormikContext()
  const {name, style} = props
  const value = getIn(values, name)

  return (
    <FormControl>
      <Select
        native
        role="select"
        data-testid={name}
        style={{width: '250px', ...style}}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        value={value ?? ''}
      >
        {simpleTypes.map(t => (
          <option key={t} value={t}>
            {t}
          </option>
        ))}
      </Select>
      <FormikErrorMessage name={props.name} />
    </FormControl>
  )
}

export function JSONSchemaDefinitionArrayField(props: {name: string}) {
  const {name} = props
  const {values} = useFormikContext()

  const value = getIn(values, name)

  return (
    <FieldArray
      name={name}
      render={({push, remove}) => {
        return (
          <div>
            {value.map((_, i) => (
              <BorderedKeywordWrapper
                name={`${name}.${i}`}
                key={`${name}.${i}`}
                label={`${name}.${i}`}
                onDelete={() => remove(i)}
              >
                <JSONSchemaDefinitionField name={`${name}.${i}`} />
              </BorderedKeywordWrapper>
            ))}
            <Button
              style={{marginTop: '10px'}}
              variant="contained"
              onClick={() => push({type: 'string'})}
            >
              Add Schema
            </Button>
          </div>
        )
      }}
    />
  )
}

export function JSONSchemaDefinitionObjectField(props: {name: string}) {
  const {name} = props
  const {values, setFieldValue} = useFormikContext()
  const value = getIn(values, name)
  const properties = Object.keys(value)
  const [property, setProperty] = useState('')

  return (
    <div>
      {properties.map(p => (
        <BorderedKeywordWrapper
          name={`${name}.${p}`}
          key={p}
          label={p}
          onDelete={() => setFieldValue(`${name}.${p}`, undefined)}
        >
          <JSONSchemaDefinitionField name={`${name}.${p}`} />
        </BorderedKeywordWrapper>
      ))}
      <div style={{display: 'flex', marginTop: '10px'}}>
        <TextField
          label="Add Property"
          value={property}
          onChange={e => setProperty(e.target.value)}
        />
        <Button
          style={{marginLeft: '5px'}}
          variant="contained"
          onClick={() => {
            setFieldValue(`${name}.${property}`, {type: 'string'})
            setProperty('')
          }}
        >
          Add
        </Button>
      </div>
    </div>
  )
}

export function JSONSchemaDefinitionField({name}: {name: string}) {
  const {values} = useFormikContext()
  const value = getIn(values, name)
  const isBoolean = typeof value === 'boolean'

  const field = isBoolean ? (
    <BooleanField name={name} label="Boolean" />
  ) : (
    <JSONSchemaField name={name} />
  )

  return (
    <div>
      <div style={{display: 'flex', gap: '20px', marginBottom: '20px'}}>
        <FormControl style={{marginTop: '10px'}}>
          <InputLabel>Definition Type</InputLabel>
          <Select style={{width: '175px'}} value={isBoolean ? 'boolean' : 'schema'}>
            <MenuItem value="boolean">Boolean</MenuItem>
            <MenuItem value="schema">Schema</MenuItem>
          </Select>
        </FormControl>
        {!isBoolean && (
          <>
            <AddAnnotation name={name} />
            <AddTypeKeyword name={name} />
          </>
        )}
      </div>
      {field}
    </div>
  )
}

export function JSONSchemaField(props: {name: string}) {
  const {name} = props
  const {values, setFieldValue} = useFormikContext()
  const value = getIn(values, name)
  const keys = Object.keys(value)

  const fields = keys.map(k => {
    const isAnnotation = annotations.includes(k)
    const keyName = `${name}.${k}`
    const Component = isAnnotation ? Annotation : TypeKeyword
    return (
      <Component
        key={keyName}
        name={keyName}
        kind={k}
        onDelete={() => setFieldValue(keyName, undefined)}
      />
    )
  })

  const schema = fields?.length ? (
    <BorderedSection title="Schema">
      <div style={{padding: '10px', paddingTop: 0}}>{fields}</div>
    </BorderedSection>
  ) : (
    <></>
  )

  return (
    <div>
      {name === 'root' && (
        <div style={{display: 'flex', gap: '20px', marginBottom: '20px'}}>
          <AddAnnotation name={name} />
          <AddTypeKeyword name={name} />
        </div>
      )}
      {schema}
    </div>
  )
}
