import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {useNavigate} from 'react-router-dom'

import {AppBar, Button, Dialog, Toolbar, Typography, Fab} from '@mui/material'
import {
  Fullscreen as FullscreenIcon,
  Navigation as NavigationIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
  Notes as NotesIcon,
  LocalOffer as LocalOfferIcon,
  ContentCopy as ContentCopyIcon,
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material/'
import PropTypes from 'prop-types'
import validBoundingBox from '../helpers/validBoundingBox'

import viewerService from '../utilities/imageViewerService'
import {getBearerToken} from '../utilities/initializeProfile'
import LoadingSpinner from './LoadingSpinner'
import ItemMetaDataAddTagDialog from './ItemMetadataAddTagDialog'
import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'

const StyledAppBar = styled(AppBar)`
  background-color: white;
  color: black;
  flex: 0 0 64px;
  position: static !important;
  z-index: 1300 !important;
`

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`

const LowerElement = styled('div')`
  display: flex;
  flex: 1 1 auto;
  height: 0;
`

const OverviewMap = styled('div')`
  border: 1px solid #bbb;
  bottom: 1rem;
  height: 200px;
  position: absolute;
  right: 1rem;
  width: 200px;
`

const ViewerAction = styled(Fab)``

const ViewerActions = styled('div')`
  display: flex;
  flex-direction: column;
  left: 0.75rem;
  padding: 0;
  position: absolute;
  top: 1rem;
  row-gap: 0.5rem;
`

const ViewerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  opacity: ${props => props.opacity};
`

const ViewerElement = styled('div')`
  height: 100%;
  position: relative;
  width: 100%;
`

const SlippyMap = styled('div')`
  height: 100%;
  width: 100%;
`

const NavigationContainer = styled('div')`
  display: flex;
  padding: 0;
  position: absolute;
  top: 1rem;
  width: 100vw;
  justify-content: center;
  align-items: center;
`

const ControlsContainer = styled('div')`
  background-color: #034f84;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin-top: 0.5rem;
  align-items: center;
  border-radius: 1.2rem;
`

const NavigationText = styled('div')`
  color: white;
`

const ItemImageViewer = ({
  imageAsset,
  itemAttributes,
  viewableAssets,
  triggerError,
  closeDrawer,
  permissions,
}) => {
  const [loading, setLoading] = useState(true)
  const [createDialog, setCreateDialog] = useState(false)
  const overviewContainer = useRef()
  const viewerContainer = useRef()
  const viewer = useRef(null)
  const navigate = useNavigate()
  const {errorNotification} = useToastNotification()

  const currentURL = new URL(window.location.href)
  const params = new URLSearchParams(currentURL.search)
  const currentAssetId = params.get('preview')

  useEffect(() => {
    const currentURL = new URL(window.location.href)
    const params = new URLSearchParams(currentURL.search)
    if (params.get('preview') !== imageAsset.id) {
      params.set('preview', imageAsset.id)
      navigate(`?${params}`)
    }
    updateViewer(imageAsset, itemAttributes).catch(e => telemetry.error(e))
  }, [imageAsset.id, itemAttributes, viewableAssets])

  useEffect(
    () => () => {
      if (viewer.current) {
        viewer.current.destroy()
        viewer.current = null
      }
    },
    []
  )

  async function updateViewer(imageAsset, itemAttributes) {
    try {
      const validImageByHref = viewerService.ImageViewer.isSupported(imageAsset.href)

      const validImageByAssetType =
        imageAsset.type && viewerService.ImageViewer.isSupported(imageAsset.mediaType)

      const supportedFileType = validImageByHref || validImageByAssetType

      if (supportedFileType === 'TMS') {
        const {bbox} = itemAttributes
        if (!validBoundingBox(bbox)) {
          telemetry.error(new Error('The item must have a geometry to preview TMS assets.'))
          triggerError('The item must have a geometry to preview TMS assets.')
          return
        }

        let url = imageAsset.href

        const colormap = itemAttributes?.metadata['gipc:colormap']
        if (colormap) url += `&colormap=${colormap}`

        return await loadTms(bbox, url)
      }

      const url = await imageAsset.fetchDownloadUrl()

      if (supportedFileType || viewerService.ImageViewer.isSupported(url)) {
        await loadImage(url, imageAsset)
      } else {
        if (supportedFileType) {
          telemetry.error(new Error('Refutation triggered in "ImageViewer.isSupported" method'))
        } else {
          telemetry.warn('File type not supported. Check ItemImageViewer')
        }
        telemetry.error(new Error('This image cannot be previewed.'))
        triggerError('This image cannot be previewed.')
      }
    } catch (error) {
      telemetry.error(error)
      if (error?.message.includes('TIFF')) {
        triggerError(error.message)
      } else {
        triggerError('Failed to load image.')
      }
    }
  }

  async function loadImage(url, imageAsset) {
    const {pathname} = new URL(url)

    try {
      const head = await fetch(url, {
        headers: {Range: 'bytes=30000001-30000001'},
      })

      if (head.status !== 416) {
        const details = 'This image is too large to preview.'
        telemetry.error(new Error(details))
        triggerError(details)
        errorNotification(details)
        close()

        return
      }
    } catch (err) {
      telemetry.error(err)
    }

    let image = null
    try {
      image = await fetch(url)
      image = await image.blob()
      if (imageAsset.mediaType && image.type.endsWith('octet-stream')) {
        image = new Blob([image], {type: imageAsset.mediaType})
      }
    } catch (error) {
      telemetry.error(error)
      if (error?.message.includes('TIFF')) {
        triggerError(error.message)
      } else {
        triggerError('Failed to load image.')
      }
      return
    }

    try {
      const imageConfig = {
        image,
        path: pathname,
        type: 'Image',
      }
      if (!viewer.current) {
        viewer.current = new viewerService.ImageViewer({
          container: viewerContainer.current,
          data: imageConfig,
          overviewContainer: overviewContainer.current,
        })
        await viewer.current.render()
      } else {
        setLoading(true)
        await viewer.current.updateData(imageConfig)
      }
    } catch (error) {
      telemetry.error(error)
      close()
      if (error?.message.includes('TIFF')) {
        triggerError(error.message)
      } else {
        triggerError('Failed to load image.')
      }
      return
    }

    setLoading(false)
  }

  async function loadTms(extent, url) {
    const headers = {
      Authorization: `Bearer ${await getBearerToken()}`,
    }
    const data = {
      extent,
      headers,
      url,
      type: 'TMS',
    }

    if (!viewer.current) {
      viewer.current = new viewerService.ImageViewer({
        container: viewerContainer.current,
        data,
        overviewContainer: overviewContainer.current,
      })
      await viewer.current.render()
    } else {
      setLoading(true)
      await viewer.current.updateData(data)
    }

    setLoading(false)
  }

  function indexOfCurrentAsset(viewableAssets = []) {
    const currentURL = new URL(window.location.href)
    const params = new URLSearchParams(currentURL.search)
    const currentAssetId = params.get('preview')
    for (let i = 0; i < viewableAssets.length; i++) {
      if (viewableAssets[i].id === currentAssetId) {
        return i
      }
    }
  }

  const resetExtent = () => {
    viewer.current.resetExtent()
  }

  const resetRotation = () => {
    viewer.current.resetRotation()
  }

  const toggleFullscreen = () => {
    viewer.current.toggleFullscreen()
  }

  const zoomIn = () => {
    viewer.current.zoomIn()
  }

  const zoomOut = () => {
    viewer.current.zoomOut()
  }

  const close = () => {
    closeDrawer()
    const currentURL = new URL(window.location.href)
    const params = new URLSearchParams(currentURL.search)
    params.delete('preview')
    navigate(`?${params}`)
  }

  const openCreateTagDialog = () => {
    setCreateDialog(true)
  }

  const closeCreateTagDialog = () => {
    setCreateDialog(false)
  }

  const navigatePreviousAsset = () => {
    const hrefURL = new URL(window.location.href)
    const hrefParams = new URLSearchParams(hrefURL.search)
    const previousAsset = viewableAssets[indexOfCurrentAsset(viewableAssets) - 1]
    hrefParams.set('preview', previousAsset.id)
    navigate(`/item?${hrefParams}`, {replace: true})
    updateViewer(previousAsset, itemAttributes)
  }

  const navigateNextAsset = () => {
    const hrefURL = new URL(window.location.href)
    const hrefParams = new URLSearchParams(hrefURL.search)
    const nextAsset = viewableAssets[indexOfCurrentAsset(viewableAssets) + 1]
    hrefParams.set('preview', nextAsset.id)
    navigate(`/item?${hrefParams}`, {replace: true})
    updateViewer(nextAsset, itemAttributes)
  }

  return (
    <div className="panel-content-wrapper">
      <Dialog open aria-labelledby="form-dialog-title" fullScreen>
        {loading && <LoadingSpinner message="Loading asset..." fullHeight />}
        <ViewerContainer opacity={loading ? 0 : 100}>
          <StyledAppBar>
            <StyledToolbar>
              <Button color="inherit" aria-label="Open metadata panel" onClick={close}>
                <NotesIcon />
                <Typography variant="button" color="inherit">
                  close
                </Typography>
              </Button>
            </StyledToolbar>
          </StyledAppBar>
          <LowerElement>
            <ViewerElement>
              <SlippyMap ref={viewerContainer} />
              <OverviewMap ref={overviewContainer} />
              {!loading && (
                <ViewerActions>
                  <ViewerAction color="primary" onClick={zoomIn} size="small" title="Zoom in">
                    <ZoomInIcon />
                  </ViewerAction>
                  <ViewerAction color="primary" onClick={zoomOut} size="small" title="Zoom out">
                    <ZoomOutIcon />
                  </ViewerAction>
                  <ViewerAction
                    color="primary"
                    onClick={resetExtent}
                    size="small"
                    title="Zoom to fit entire image"
                  >
                    <ZoomOutMapIcon />
                  </ViewerAction>
                  <ViewerAction
                    color="primary"
                    onClick={toggleFullscreen}
                    size="small"
                    title="Use full screen"
                  >
                    <FullscreenIcon />
                  </ViewerAction>
                  <ViewerAction
                    color="primary"
                    onClick={resetRotation}
                    size="small"
                    title="Reset rotation. Hold Alt + Shift and drag to rotate."
                  >
                    <NavigationIcon />
                  </ViewerAction>
                  {permissions.publish && (
                    <ViewerAction
                      color="primary"
                      onClick={openCreateTagDialog}
                      size="small"
                      title="Add a tag."
                    >
                      <LocalOfferIcon />
                    </ViewerAction>
                  )}
                  <ViewerAction color="primary" size="small" title="Copy URL to clipboard.">
                    <CopyToClipboard text={window.location.href}>
                      <ContentCopyIcon />
                    </CopyToClipboard>
                  </ViewerAction>
                </ViewerActions>
              )}
              <NavigationContainer>
                <ControlsContainer>
                  <ViewerAction
                    color="primary"
                    onClick={navigatePreviousAsset}
                    size="small"
                    title="Previous viewable asset."
                    disabled={indexOfCurrentAsset(viewableAssets) - 1 < 0}
                  >
                    <ArrowBackIosNewIcon />
                  </ViewerAction>
                  <NavigationText>{currentAssetId}</NavigationText>
                  <ViewerAction
                    color="primary"
                    onClick={navigateNextAsset}
                    size="small"
                    title="Next viewable asset."
                    disabled={!(indexOfCurrentAsset(viewableAssets) + 1 < viewableAssets?.length)}
                  >
                    <ArrowForwardIosIcon />
                  </ViewerAction>
                </ControlsContainer>
              </NavigationContainer>
              <ItemMetaDataAddTagDialog
                createDialog={createDialog}
                setCreateDialog={closeCreateTagDialog}
              />
            </ViewerElement>
          </LowerElement>
        </ViewerContainer>
      </Dialog>
    </div>
  )
}

ItemImageViewer.propTypes = {
  imageAsset: PropTypes.any.isRequired,
  itemAttributes: PropTypes.any.isRequired,
  triggerError: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
}

export default ItemImageViewer
