import React, {useContext, useEffect, useState} from 'react'
import styled from 'styled-components'
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete'
import {TextField} from '@mui/material'

import {imagineServiceContext} from '../context/imagineServiceContext'
import useAdvancedSearch from '../hooks/useAdvancedSearch'
import SearchSelectedAttribute from './SearchSelectedAttribute'
import {telemetry} from '../utilities/telemetry'
import useToastNotification from '../hooks/useToastNotification'

const ProjectsSearchElement = styled.div`
  .MuiAutocomplete-root {
    margin-bottom: 5px;
  }

  .MuiChip-root {
    margin: 2px;
  }

  .selected-projects-list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  label {
    padding-left: 5px;
  }

  .MuiAutocomplete-endAdornment {
    margin-right: 10px;
  }
`
const ADVANCED_SEARCH_SELECTOR_PROJECT_IDS = 'ADVANCED_SEARCH_SELECTOR_PROJECT_IDS'

/**
 * updates and displays selected projects from AdvancedSearch Context through the useAdvancedSearch hook
 *
 */
function AdvancedSearchSelectorProjects() {
  const [projectList, setProjectList] = useState([''])
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const {
    searchAttributes: {projects: selectedProjects},
    setProjects,
  } = useAdvancedSearch()
  const [toggleAutocompleteRender, setToggleAutocompleteRender] = useState(false)
  const {imagineSdk} = useContext(imagineServiceContext)
  const [loading, setLoading] = useState(false)
  const {errorNotification} = useToastNotification()

  // Loads the potential set of projects
  useEffect(() => {
    async function loadProjects() {
      try {
        const cachedData = window.localStorage.getItem(ADVANCED_SEARCH_SELECTOR_PROJECT_IDS)
        if (cachedData) {
          setProjectList(JSON.parse(cachedData))
        }
      } catch (err) {
        telemetry.error(err, 'Error loading advanced selector project IDs from local storage')
      }

      const projectListing = imagineSdk.fetchProjects()
      const projects = []
      for await (const project of projectListing) {
        projects.push(project.id)
      }
      projects.sort()
      window.localStorage.setItem(ADVANCED_SEARCH_SELECTOR_PROJECT_IDS, JSON.stringify(projects))
      setProjectList(projects)
    }

    setLoading(true)
    if (imagineSdk)
      loadProjects()
        .catch(e => {
          telemetry.error(e, 'Project autocomplete failed to load.')
          errorNotification('Project autocomplete failed to load.')
        })
        .finally(() => setLoading(false))
  }, [imagineSdk])

  function onChange(
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ): void {
    if (reason === 'selectOption') {
      setProjects([...selectedProjects, value])
      setAutocompleteValue('')
      setToggleAutocompleteRender(!toggleAutocompleteRender)
    } else {
      setAutocompleteValue(value)
    }
  }

  function removeProject(project) {
    const newArray = [...selectedProjects]
    newArray.splice(newArray.indexOf(project), 1)
    setProjects([...newArray])
  }

  return (
    <ProjectsSearchElement>
      <Autocomplete
        key={toggleAutocompleteRender.toString()}
        loading={loading}
        options={projectList}
        getOptionDisabled={option => selectedProjects.indexOf(option) !== -1}
        value={autocompleteValue}
        renderInput={params => (
          <TextField {...params} name="projects" label="Projects" variant="standard" />
        )}
        defaultValue=""
        disableClearable
        onChange={onChange}
        autoHighlight
      />

      <div className="selected-projects-list">
        {selectedProjects.map(project => (
          <SearchSelectedAttribute
            key={`search-project-chip-${project}`}
            property={project}
            onDelete={() => removeProject(project)}
          />
        ))}
      </div>
    </ProjectsSearchElement>
  )
}

export default AdvancedSearchSelectorProjects
