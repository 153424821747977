import React from 'react'
import {BorderedSection} from './BorderedSection'
import {AppEntitlementField} from './AppEntitlementField'

export function AppEntitlementFields() {
  const appOptions = []
  const loading = false

  return (
    <>
      <BorderedSection title="Application Entitlements" style={{marginTop: '20px'}}>
        <AppEntitlementField
          name="appEntitlements.admin"
          label="Admin"
          options={appOptions}
          loading={loading}
        />
        <AppEntitlementField
          name="appEntitlements.delete"
          label="Delete"
          options={appOptions}
          loading={loading}
        />
        <AppEntitlementField
          name="appEntitlements.publish"
          label="Publish"
          options={appOptions}
          loading={loading}
        />
        <AppEntitlementField
          name="appEntitlements.download"
          label="Download"
          options={appOptions}
          loading={loading}
        />
      </BorderedSection>
    </>
  )
}
