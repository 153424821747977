import React, {useContext} from 'react'

import {IconButton, Tooltip} from '@mui/material'
import {RemoveCircle as RemoveCircleIcon} from '@mui/icons-material'
import styled from 'styled-components'

import {galleryItemContext} from '../context/galleryItemContext'
import {galleryAlbumContext} from '../context/galleryAlbumContext'
import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'

const StyledIconButton = styled(IconButton)`
  margin: 2px !important;
  padding: 15px !important;
  width: 1em;
  height: 1em;
  &.Mui-disabled .MuiSvgIcon-colorPrimary {
    color: var(--mdc-theme-text-disabled-on-dark) !important;
  }
`

/** Selectivley-visible dialog which allows users to remove items from albums in an album gallery view */
const DialogRemoveItemFromAlbumGallery = function () {
  const {item} = useContext(galleryItemContext)
  const {album, removeItemsFromList} = useContext(galleryAlbumContext)
  const {errorNotification, successNotification} = useToastNotification()

  return (
    <Tooltip title="Remove item from album">
      <span>
        <StyledIconButton
          color="primary"
          onClick={() => {
            album
              .removeItems([item])
              .then(() => removeItemsFromList([item]))
              .then(() => {
                successNotification('Item removed from album')
              })
              .catch(e => {
                telemetry.error(e)
                errorNotification('Unable to remove item from album')
              })
          }}
        >
          <RemoveCircleIcon />
        </StyledIconButton>
      </span>
    </Tooltip>
  )
}

export default DialogRemoveItemFromAlbumGallery
