import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {IconButton, TextField, Typography} from '@mui/material'
import {Clear as CancelIcon, Check as SubmitIcon, Create as EditIcon} from '@mui/icons-material'

import {galleryAlbumContext} from '../context/galleryAlbumContext'
import groupingItemCountCopy from '../helpers/groupingItemCountCopy'
import useToastNotification from '../hooks/useToastNotification'
import {telemetry} from '../utilities/telemetry'

/**
 * Displays and provides editing for gallery summary's description field
 *
 */
const GallerySummaryTitleField = function (props) {
  const {editable, title, count} = props
  const [editing, setEditing] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [editedTitle, setEditedTitle] = useState(title)
  const {album} = useContext(galleryAlbumContext)
  const {errorNotification, successNotification} = useToastNotification()

  // Updates the value of the description on the SDK on completion of editing
  useEffect(() => {
    function updateDescription() {
      album
        .updateMetadata({title: editedTitle})
        .then(() => {
          successNotification('Description updated')
        })
        .catch(e => {
          const additionalErrorDetails = 'There was an error in updating the album description.'
          telemetry.error(e, additionalErrorDetails)
          errorNotification(e.message)
          setEditedTitle(title)
        })
    }

    if (!(editing || initialLoad) && editedTitle !== title) updateDescription()
    if (initialLoad) setInitialLoad(false)
  }, [editing])

  const StaticMode = () => (
    <>
      <>
        <Typography variant="body1">
          {groupingItemCountCopy({grouping: 'album', name: editedTitle, count})}
        </Typography>
      </>

      {editable && (
        <IconButton onClick={() => setEditing(true)} size="large">
          <EditIcon fontSize="small" />
        </IconButton>
      )}
    </>
  )

  const EditingMode = () => (
    <>
      <TextField
        defaultValue={editedTitle}
        onBlur={e => setEditedTitle(e.target.value)}
        onKeyUp={event => {
          if (event.key === 'Escape') setEditing(false)
          if (event.key === 'Enter') {
            setEditedTitle(event.target.value)
            setEditing(false)
          }
        }}
        style={{minWidth: 220, paddingBottom: 3}}
        variant="standard"
      />
      <IconButton onClick={() => setEditing(false)} size="large">
        <SubmitIcon fontSize="small" color="primary" />
      </IconButton>
      <IconButton
        onClick={() => {
          setEditedTitle(title)
          setEditing(false)
        }}
        size="large"
      >
        <CancelIcon fontSize="small" />
      </IconButton>
    </>
  )

  return <>{editing ? <EditingMode /> : <StaticMode />}</>
}

GallerySummaryTitleField.propTypes = {
  /** Number of items in the Album */
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Text Description fed from the SDK */
  title: PropTypes.string,
  /** Check on whether user can edit */
  editable: PropTypes.bool,
}

GallerySummaryTitleField.defaultProps = {
  title: '',
  editable: false,
}

export default GallerySummaryTitleField
