import React, {useEffect, useState} from 'react'
import {useLocation, useParams, useNavigate} from 'react-router-dom'

import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import styled from 'styled-components'
import {isLocal} from '../utilities/serviceBindings'

const StyledIconButton = styled(IconButton)`
  margin-top: 2px !important;
  color: var(--mdc-theme-primary) !important;
`

const StyledInputLabel = styled(InputLabel)`
  padding: 2px;
`

const StyledSearchControl = styled(FormControl)`
  width: 100%;
`

const SearchContainer = styled.div`
  display: flex;
  width: 100%;
`

/*
  Standalone search bar with drawer-toggling button.

  1. Redirects to /search/keywords="*" when search bar triggered
  2. Toggles the advanced search drawer when advanced button triggered "todo"
*/
function BasicSearchBar() {
  const [searchText, setSearchText] = useState('')
  const location = useLocation()
  const params = useParams()

  // Sets the text search bar to the search param if the user manually enters the page.
  useEffect(() => {
    const {search} = location

    const keywordsRe = /search=(.*)&*.*$/

    const reResults = search.match(keywordsRe)

    if (Array.isArray(reResults) && reResults[1]) {
      setSearchText(reResults[1])
    } else if (params.searchTerm) {
      // Used in the dedicated "basic search" page
      setSearchText(decodeURIComponent(params.searchTerm))
    } else {
      setSearchText('')
    }

    return () => {
      setSearchText('')
    }
  }, [location.search])

  const updateSearchTerm = e => {
    setSearchText(e.target.value)
  }

  const detectSubmit = ({key}) => {
    if (searchText === '' || !searchText) return

    if (key === 'Enter' && searchText) {
      const base = isLocal ? '/imagine' : ''
      window.location.href = `${base}/search/${encodeURIComponent(searchText)}`
    }
  }

  return (
    <>
      <SearchContainer id="search-images">
        <StyledSearchControl variant="outlined">
          <StyledInputLabel htmlFor="outlined-adornment">Search Images</StyledInputLabel>
          <OutlinedInput
            id="outlined-adornment"
            onChange={updateSearchTerm}
            onKeyUp={detectSubmit}
            endAdornment={
              <InputAdornment position="end">
                <StyledIconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    detectSubmit({key: 'Enter'})
                  }}
                >
                  <SearchIcon />
                </StyledIconButton>
              </InputAdornment>
            }
            value={searchText}
          />
        </StyledSearchControl>
      </SearchContainer>
    </>
  )
}

export default BasicSearchBar
